import { useContext } from 'react';
import { Ecodrive } from '../context/store';
import { USER_ENUM } from '../context/store/actions/user.actions';
import { useNavigate } from 'react-router-dom';
import { ecodriveApi } from '../libs/axios';
import { BUSINESS_ENUM } from '../context/store/actions/business.actions';

export const useLogin = ({ email, password }) => {
	const [, dispatch] = useContext(Ecodrive);

	return async function () {
		const { data } = await ecodriveApi.post('/login', { email, password });
		localStorage.setItem('authorization', data.token);
		dispatch(USER_ENUM.EMIT_TOKEN_EVENT, { token: data.token });
	};
};

export const useLogout = () => {
	const [, dispatch] = useContext(Ecodrive);
	const navigate = useNavigate();

	return function () {
		dispatch(USER_ENUM.CLEAR);
		dispatch(BUSINESS_ENUM.CLEAR);
		localStorage.clear();
		navigate('/login');
	};
};
