import React, { useState, useEffect, useContext } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

import './NPSFeedback.scss';
import { useBetween } from 'use-between';
import BubbleSelection from './BubbleSelection';
import useClient from '../../../pages/client/useClient';
import useAuth from '../../../pages/auth/useAuth';
import { Ecodrive } from '../../../context/store';

const NPSFeedback = () => {
	const [score, setScore] = useState(null);
	const [show, setShow] = useState(false);
	const [feedback, setFeedback] = useState('');
	const { postNPSFeedback, updateLastPromptDate, shouldPromptNPS } =
		useBetween(useClient);

	const [{ business }] = useContext(Ecodrive);

	const isSubscriptionActive = business.subscription?.active;

	const handleAskLater = async () => {
		setShow(false);
		await updateLastPromptDate(business.id);
	};

	const handleSubmit = async () => {
		const id = uuidv4();
		const completed = true;
		const last_prompt_date = new Date().toISOString();

		const data = {
			id,
			business_id: business.id,
			completed,
			score,
			feedback,
			last_prompt_date,
		};

		await postNPSFeedback(data)
			.then((data) => {
				setShow(false);
				toast.success('NPS feedback submitted successfully!');
			})
			.catch((error) => {
				console.error(error);
				toast.error('Failed to submit NPS feedback.');
			});
	};

	const handleFeedbackChange = (e) => {
		const value = e.target.value;
		if (value.length <= 500) {
			setFeedback(value);
		}
	};

	const feedbackRemaining = 500 - feedback.length;
	const showWarning = feedbackRemaining <= 50;

	useEffect(() => {
		const checkShouldPromptNPS = async () => {
			try {
				const response = await shouldPromptNPS(business.id);
				setShow(response.shouldPrompt && isSubscriptionActive);
			} catch (error) {
				console.error(error);
			}
		};

		checkShouldPromptNPS();
	}, []);
	return (
		<>
			<Modal
				show={show}
				backdrop='static'
				keyboard={false}
				dialogClassName='nps__custom-modal'
			>
				<Modal.Header className='nps__modal-header'>
					<Modal.Title>How likely are you to recommend our services?</Modal.Title>
				</Modal.Header>
				<Modal.Body className='nps__modal-body'>
					<Form.Group className='mt-2 mb-5 nps__form-group'>
						<Form.Label className='nps__score-label'>
							Score: {score !== null ? score : 'Not selected'}
						</Form.Label>
						<BubbleSelection value={score} setValue={setScore} />
					</Form.Group>

					<Form.Group className='mt-3 nps__form-group'>
						<Form.Label>Additional Feedback:</Form.Label>
						<div className='nps__fixed-size-textarea'>
							<Form.Control
								as='textarea'
								rows={3}
								value={feedback}
								onChange={handleFeedbackChange}
								placeholder='Please provide additional feedback here.'
								maxLength={500}
								className='nps__no-resize-textarea'
							/>
						</div>
						{showWarning && (
							<small className='nps__warning-text'>
								{feedbackRemaining} characters remaining
							</small>
						)}
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className='nps__modal-footer'>
					<Button
						variant='secondary'
						onClick={handleAskLater}
						style={{
							backgroundColor: '#abb5c4',
							borderColor: '#abb5c4',
						}}
						className='nps__ask-later-btn'
					>
						Ask me later
					</Button>
					<Button
						variant='primary'
						onClick={() => {
							handleSubmit();
						}}
						disabled={score === null}
						style={{
							backgroundColor: '#77cfc6',
							borderColor: '#abb5c4',
						}}
						className='nps__submit-btn'
					>
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default NPSFeedback;
