import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ecodriveApi } from '../../libs/axios';
import allowOnlyNumbers from '../../utils/allowOnlyNumbers';

const validateProjectSchema = Yup.object().shape({
	ProjectID: Yup.string().required(),
	inventory: Yup.number().required(),
	price: Yup.number().required(),
});

const AddNewProject = ({ onSubmit }) => {
	const [addingProject, setAddingProject] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleProjectSubmit = (v, { resetForm }) => {
		setLoading(true);
		ecodriveApi
			.post('/planting-projects', {
				projectKey: v.ProjectID,
				inventory: v.inventory || 0,
				price: v.price || 0,
			})
			.then(resetForm)
			.then(onSubmit)
			.finally(() => {
				setAddingProject(false);
				setLoading(false);
			});
	};

	const {
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		handleReset,
		handleSubmit,
		isValid,
	} = useFormik({
		initialValues: {
			ProjectID: '',
			inventory: '',
			price: '',
		},
		validationSchema: validateProjectSchema,
		isInitialValid: false,
		onSubmit: handleProjectSubmit,
	});

	return (
		<>
			{addingProject && (
				<div className='client-row'>
					<Row className='fs-6'>
						<Col xs={4}>
							<Form.Control
								value={values.ProjectID}
								onChange={handleChange}
								onBlur={handleBlur}
								name='ProjectID'
								className='w-100 border-0 rounded px-2'
								type='text'
								placeholder='Enter a Project Key'
							/>
						</Col>
						<Col xs={4}>
							<Form.Control
								value={values.inventory}
								onChange={allowOnlyNumbers((v) => setFieldValue('inventory', v))}
								onBlur={handleBlur}
								className='w-100 border-0 rounded px-2'
								type='text'
								name='inventory'
								placeholder='Enter the inventory'
							/>
						</Col>
						<Col xs={4}>
							<Form.Control
								value={values.price}
								onChange={allowOnlyNumbers((v) => setFieldValue('price', v))}
								onBlur={handleBlur}
								className='w-100 border-0 rounded px-2'
								type='text'
								name='price'
								placeholder='Enter a price, e.g. 0.45'
							/>
						</Col>
					</Row>
				</div>
			)}
			<div className='client-row'>
				<Button
					className='me-2'
					size='sm'
					disabled={addingProject && (loading || !isValid)}
					variant={addingProject ? 'success' : 'secondary'}
					onClick={() => {
						if (addingProject) {
							handleSubmit();
						} else {
							setAddingProject(true);
						}
					}}
				>
					{addingProject ? 'Save' : 'Add Project'}
				</Button>
				{addingProject && (
					<Button
						disabled={loading}
						size='sm'
						variant={'danger'}
						onClick={() => {
							setAddingProject(false);
							handleReset();
						}}
					>
						Cancel
					</Button>
				)}
			</div>
		</>
	);
};

export default AddNewProject;
