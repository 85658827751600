import React, { useState, useEffect } from 'react';
import { useBetween } from 'use-between';
import useClient from '../../useClient';
import SettingsLayout from '../layout';
import { Table, Button, Row, Col, Modal } from 'react-bootstrap';
import './index.scss';

const ManageApiKeys = () => {
  const { getApiKey , deleteApiKey } = useBetween(useClient);
  const [apiKey, setApiKey] = useState(null);
  const [copied, setCopied] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  const generateApiKey = async () => {
    await getApiKey()
      .then((data) => {
        setApiKey(data);
        setCopied(false);
      })
  };

  const copyApiKey = () => {
    navigator.clipboard
      .writeText(apiKey.apiKey)
      .then(() => {
        setCopied(true);
      })
  };

  const handleButtonClick = () => {
    setShowDeleteModal(true);
  };

  const deleteConfirmed = async () => {
    await deleteApiKey()
      .then((data) => {
        setApiKey(null);
        setShowDeleteModal(false);
      })
  };


  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const maskedApiKey = (apiKey) => {
    if (apiKey && apiKey.length >= 5) {
      const lastFiveDigits = apiKey.slice(-5);
      const maskedCharacters = apiKey.slice(0, -5).replace(/./g, '*');
      return maskedCharacters + lastFiveDigits;
    } else {
      return apiKey;
    }
  };

  const formattedDate = (date) => {
    const dateObject = new Date(date);
    const timezoneOffset = dateObject.getTimezoneOffset();
    dateObject.setMinutes(dateObject.getMinutes() - timezoneOffset);
    return dateObject.toISOString().slice(0, 10);
  };

  return (
    <SettingsLayout title="ApiKeys">
      <div className="manage-keys">
        <Row>
          <Col>
            <h1>Manage Your Key</h1>
            <h6>Generate and manage your key.</h6>
            <Button className='button-submit my-3' onClick={generateApiKey}>Get API key</Button>
            {apiKey && <Table striped bordered>
              <thead>
                <tr>
                  <th>apiKey</th>
                  <th>Creation Date</th>
                  <th>Copy</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr key={apiKey.apiKey}>
                <td className="text-center"><input
                  type="text"
                  className="masked-api-key my-3"
                  value={maskedApiKey(apiKey.apiKey)}
                  readOnly
                />
                </td>
                <td className="text-center" ><p  className="my-3">{formattedDate(apiKey.created_at)}</p></td>
                <td className="text-center">
                <Button
                    className="button-submit my-3"
                    onClick={copyApiKey}
                >
                    {copied ? 'Copied!' : 'Copy to Clipboard'}
                </Button>
                </td>
                <td className="text-center">
                    <Button  className="my-3" variant="danger" onClick={() => handleButtonClick()}>
                    Delete
                    </Button>
                </td>
                </tr> 
              </tbody>
            </Table>}
          </Col>
        </Row>
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete the API Key?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={deleteConfirmed}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </SettingsLayout>
  );
};

export default ManageApiKeys;
