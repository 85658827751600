import React, { useEffect } from 'react';
import { ecodriveApi } from '../../libs/axios'; // Import the configured Axios instance
import { toast } from 'react-toastify'; // Assuming you are using react-toastify for displaying toast messages

const withErrorHandling = (WrappedComponent) => {
	const WithErrorHandling = (props) => {
		useEffect(() => {
			const axiosInterceptor = ecodriveApi.interceptors.response.use(
				(response) => response,
				(error) => {
					if (error.isAxiosError) {
						toast.error(error.response.data.message);
						throw new Error(error.response.data.message);
					}
					toast.error(error.message);
					throw error;
				},
			);

			return () => {
				ecodriveApi.interceptors.response.eject(axiosInterceptor);
			};
		}, []);

		return <WrappedComponent {...props} />;
	};

	return WithErrorHandling;
};

export default withErrorHandling;
