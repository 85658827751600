import React from 'react';

const LinkEntity = ({ entityKey, contentState, children }) => {
	const { url } = contentState.getEntity(entityKey).getData();
	return (
		<a href={url} aria-label={url} target='_blank' rel='noopener noreferrer'>
			{children}
		</a>
	);
};

const linkStrategy = (contentBlock, cb, contentState) => {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();
		return (
			entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
		);
	}, cb);
};

export { LinkEntity, linkStrategy };
