import { useState } from 'react';

import { getZapierRedirectUrl } from '../../utils/zapier';

export const useAuthenticateZapier = () => {
	const [isAuthenticating, setIsAuthenticating] = useState(false);

	const handleAuthenticateWithZapier = () =>
		new Promise<void>((resolve, reject) => {
			setIsAuthenticating(true);

			const redirectUrl = getZapierRedirectUrl();
			const opened = window.open(
				redirectUrl,
				'zapieroauth',
				'popup=yes,width=500,height=600',
			);

			if (!opened) {
				reject('There was an error opening the Zapier redirect url');
			} else {
				const closedInterval = setInterval(() => {
					if (opened.closed) {
						setIsAuthenticating(false);
						clearInterval(closedInterval);
						resolve();
					}
				}, 1000);
			}
		});

	return {
		handleAuthenticateWithZapier,
		isAuthenticating,
	};
};
