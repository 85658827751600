import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import MediaUpload from '../../MediaUpload';

const Media = ({ onClick, projectId }) => {
	const [open, toggleOpen] = useState(false);

	const handleClose = (v) => {
		toggleOpen(false);
		onClick(v);
	};

	return (
		<Dropdown show={open} onToggle={() => toggleOpen(!open)}>
			<Dropdown.Toggle variant='light' size='sm'>
				<i className='bi bi-image'></i>
			</Dropdown.Toggle>
			<Dropdown.Menu className='p-0 border-0'>
				{open && (
					<MediaUpload
						uploadUrl={`/planting-projects/${projectId}/upload`}
						width={'20rem'}
						onChange={handleClose}
						reset
					/>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default Media;
