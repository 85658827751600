import {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useContext,
} from 'react';
import { USER_ENUM } from './actions/user.actions';
import { BUSINESS_ENUM } from './actions/business.actions';
import UserReducer from './reducers/user.reducer';
import BusinessReducer from './reducers/business.reducer';

import useFetch from '../../hooks/useFetch';
import { ecodriveApi } from '../../libs/axios';
import useLocalStorage from '../../hooks/useLocalStorage';

export const Ecodrive = createContext([{ user: {}, business: {} }, () => {}]);

export const useEcodrive = () => {
	const state = useContext(Ecodrive);

	return state;
};

const EcodriveProvider = ({ children }) => {
	const token = useLocalStorage('authorization');
	const [user, userDispatch] = useReducer(UserReducer, { token });
	const [business, businessDispatch] = useReducer(BusinessReducer, {});
	const reducer = useCallback((action, payload) => {
		if (action in USER_ENUM) {
			userDispatch({ type: action, ...payload });
		}
		if (action in BUSINESS_ENUM) {
			businessDispatch({ type: action, ...payload });
		}
	}, []);

	const state = useMemo(
		() => [{ user, business }, reducer],
		[user, business, reducer],
	);

	const { data: profile } = useFetch(user.token && '/users/me');

	useEffect(() => {
		if (profile) {
			userDispatch({
				type: USER_ENUM.FILL_PROFILE,
				...profile,
			});

			if (user.profile?.businessId) {
				ecodriveApi
					.get('/business')
					.then(({ data }) => {
						return { businessData: data };
					})
					.then(async ({ businessData }) => {
						const { data } = await ecodriveApi.get('/content/' + businessData.id);

						return { businessData, content: data };
					})
					.then(({ businessData, content }) => {
						let step = null;
						if (!businessData['customerId']) step = 'payment';
						if (!content) step = 'content';

						businessDispatch({
							type: BUSINESS_ENUM.FILL_BUSINESS,
							step,
							...businessData,
						});
					});
			}
		}
	}, [profile, user.profile?.businessId]);

	return <Ecodrive.Provider value={state}>{children}</Ecodrive.Provider>;
};

export default EcodriveProvider;
