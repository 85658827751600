import { useEffect, useState } from 'react';
import { Container, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import Card from 'react-credit-cards-2';
import { useBetween } from 'use-between';
import { toast } from 'react-toastify';
import useClient from '../../../useClient';
import 'react-credit-cards-2/es/styles-compiled.css';

import './index.scss';
import {
	formatCreditCardNumber,
	formatCVC,
	formatExpirationDate,
} from '../../../../../plugins';

import useAuth from '../../../../auth/useAuth';
import MaskedInput from 'react-text-mask';
import { creditCardMask } from '../../../../../utils/numberMasks';
import getStored from '../../../../../utils/getStored';

const Payment = ({ data, setData, prev, next }) => {
	const [billing] = useState(getStored('billingInfo'));

	const [cardNumber, setCardNumber] = useState(
		data.cardNumber ? data.cardNumber : '',
	);
	const [expDate, setExpDate] = useState(data.expDate ? data.expDate : '');
	const [cvv, setCvv] = useState(data.cvv ? data.cvv : '');
	const [loading, setLoading] = useState(false);
	const [focused, setFocused] = useState('');
	const [, setThisUser] = useState({});
	const [, setStripeCustomerId] = useState(
		data.stripeCustomerId ? data.stripeCustomerId : '',
	);
	const { postStripe, getUser, getDiscount } = useBetween(useClient);
	const { setStripeId, userId } = useBetween(useAuth);

	const [discount, setDiscount] = useState('');
	const [discountId, setDiscountId] = useState('');
	const [discountLocked, setDiscountLocked] = useState(false);

	useEffect(() => {
		const asyncFetchDataOnce = async () => {
			let thisBillingInfo = JSON.parse(localStorage.getItem('billingInfo'));
			if (!thisBillingInfo.firstName || !thisBillingInfo.lastName) {
				let userResponse = await getUser(userId);
				setThisUser(userResponse.user);
			}
		};
		asyncFetchDataOnce();
	}, [getUser, userId]);

	const goBack = () => {
		setData({
			cardNumber,
			expDate,
			cvv,
		});
		prev();
	};

	const handleInputChange = (event) => {
		if (event.target.name === 'number') {
			const value = formatCreditCardNumber(event.target.value);
			setCardNumber(value);
		} else if (event.target.name === 'expiry') {
			const value = formatExpirationDate(event.target.value);
			setExpDate(value);
		} else if (event.target.name === 'cvc') {
			const value = formatCVC(event.target.value, cardNumber);
			setCvv(value);
		}
	};

	const finishStep = async () => {
		const formData = new FormData();
		setLoading(true);
		formData.append('phone_number', billing.phone);
		formData.append('country', billing.country);
		formData.append('address1', billing.address1);
		formData.append('address2', billing.address2);
		formData.append('city', billing.city);
		formData.append('state', billing.state);
		formData.append('zip', billing.zip);

		formData.append('cardNumber', cardNumber);
		formData.append('expiryDate', expDate);
		formData.append('cvv', cvv);
		if (discountLocked) {
			formData.append('code', discountId);
		}

		postStripe(formData)
			.then((response) => {
				toast.success(response.message);
				setStripeCustomerId(response.business.customerId);
				setStripeId(response.business.customerId);
				setLoading(false);
				next();
			})
			.catch((error) => {
				setLoading(false);
				toast.error('Occurring error while saving datas.');
				console.error(error);
				setLoading(false);
			});
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		setData({
			cardNumber,
			expDate,
			cvv,
		});

		finishStep();
	};

	return (
		<Container className='setup-info payment-info'>
			<label>Welcome</label>
			<p>Please enter your billing info</p>
			<Card
				number={cardNumber}
				expiry={expDate}
				cvc={cvv}
				name={''}
				focused={focused}
				className='card'
			/>

			<Form onSubmit={(event) => handleSubmit(event)}>
				<Row>
					<Col>
						<Form.Group className='mb-3'>
							<Form.Label>Credit Card Number</Form.Label>
							<MaskedInput
								guide={false}
								mask={creditCardMask}
								type='tel'
								name='number'
								onChange={(event) => {
									handleInputChange(event);
								}}
								onFocus={(event) => {
									setFocused(event.target.name);
								}}
								value={cardNumber}
								placeholder='Your Credit Card Number'
								render={(ref, props) => <Form.Control ref={ref} required {...props} />}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Expire Date</Form.Label>
							<Form.Control
								type='tel'
								name='expiry'
								pattern='\d\d/\d\d'
								onChange={(event) => {
									handleInputChange(event);
								}}
								onFocus={(event) => {
									setFocused(event.target.name);
								}}
								placeholder='MM/YY'
								value={expDate}
								autoComplete='cc-exp'
								maxLength={5}
								required
							/>
						</Form.Group>
					</Col>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>CVV</Form.Label>
							<Form.Control
								type='tel'
								name='cvc'
								pattern='\d{3,4}'
								onChange={(event) => {
									handleInputChange(event);
								}}
								onFocus={(event) => {
									setFocused(event.target.name);
								}}
								placeholder='Your CVV'
								value={cvv}
								required
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={12} xs={12}>
						<InputGroup className='mb-3'>
							<Form.Control
								value={discount}
								onChange={(event) => setDiscount(event.target.value)}
								placeholder='Promo Code'
								disabled={discountLocked}
							/>
							<Button
								className='promo-button'
								variant='outline-secondary'
								onClick={() => {
									if (discountLocked) return;
									getDiscount(discount)
										.then((response) => {
											if (response.id) {
												toast.success('Promo Code Applied');
												setDiscountLocked(true);
												setDiscountId(response.id);
											} else {
												toast.error('Promo Code Invalid');
											}
										})
										.catch((err) => {
											toast.error('Error occured when applying Promo Code');
										});
								}}
								disabled={!discount || discountLocked}
							>
								Apply Promo Code
							</Button>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group className='mt-2 mb-3'>
							<Form.Check
								type='checkbox'
								label='By checking this box I agree to the $25/ monthly dashboard fee and $0.45 / per usage (per tree) charge to be billed monthly.'
								required
							/>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Check type='checkbox'>
								<Form.Check.Input type='checkbox' required />
								<Form.Check.Label>
									By checking this box I agree to the Ecodrive&nbsp;
									<Button
										variant='link'
										href='https://ecodrive.community/policies/terms-of-service'
										target='_blank'
									>
										Terms of Use
									</Button>
									&nbsp;and&nbsp;
									<Button
										variant='link'
										href='https://ecodrive.community/policies/privacy-policy'
										target='_blank'
									>
										Privacy Policy
									</Button>
									.
								</Form.Check.Label>
							</Form.Check>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col className='action-buttons'>
						<Button
							type='button'
							className='button button-primary'
							onClick={() => goBack()}
						>
							Back
						</Button>
						<Button
							type='submit'
							className='button button-secondary'
							disabled={loading}
						>
							{loading ? 'Submitting...' : 'Next'}
						</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
};

export default Payment;
