import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useBetween } from 'use-between';
import useAuth from '../useAuth';
import { Logo } from '../../../components/icons';
import withErrorHandling from '../../../components/withErrorHandling';
import '../index.scss';
import { toast } from 'react-toastify';
import { AuthLayout } from '../../../lauyouts';
const Reset = () => {
	let navigate = useNavigate();
	const location = useLocation();
	const { reset } = useBetween(useAuth);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState('');
	const [token, setToken] = useState('');
	const [userId, setUserId] = useState('');

	const handleSubmit = (event) => {
		setError('');
		event.preventDefault();

		if (password !== confirmPassword) {
			setError('Password does not match!');
		} else if (password.length < 8) {
			setError('Password must be at least 8 characters long');
		} else {
			const formData = new FormData();
			formData.append('password', password);
			formData.append('confirm_password', confirmPassword);

			reset(formData, token)
				.then((response) => {
					if (
						response.msg === 'The password reset link is invalid or has expired.'
					) {
						toast.error(response.message);
						navigate('/forgot-password');
					} else {
						navigate('/login');
					}
				})
				.catch((error) => {
					setError(error);
				});
		}
	};

	useEffect(() => {
		if (location.search) {
			const searchParams = new URLSearchParams(location.search);
			const token = searchParams.get('token');
			const userId = searchParams.get('user_id');
			setToken(token);
			setUserId(userId);
		}
	}, [location]);

	return (
		<AuthLayout>
			<div className='auth-container'>
				<Row>
					<Col md={7} sm={12} className='content px-4 py-5'>
						<div className='content-wrapper'>
							<Logo />
							<h1>Reset Password</h1>
							<Form onSubmit={(event) => handleSubmit(event)}>
								<Row>
									<Col>
										<p className='form-title'>Please type new password</p>
									</Col>
								</Row>
								<Row>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Password</Form.Label>
											<Form.Control
												type='password'
												value={password}
												onChange={(event) => setPassword(event.target.value)}
												placeholder='Password'
												required
											/>
										</Form.Group>
									</Col>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Password Confirm</Form.Label>
											<Form.Control
												type='password'
												value={confirmPassword}
												onChange={(event) => setConfirmPassword(event.target.value)}
												placeholder='Password Confirm'
												required
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									{error && (
										<Col sm={12} className='my-3'>
											<p className='error mb-0'>{error}</p>
										</Col>
									)}
									<Col md={6} sm={12}>
										<Button
											type='submit'
											className='button button-secondary full-width my-3'
										>
											Submit
										</Button>
									</Col>
								</Row>
							</Form>
							<p
								className='link mt-3'
								onClick={() => navigate('/login', { replace: true })}
							>
								Already have an account? Login here
							</p>
						</div>
					</Col>
					<Col md={5} sm={12} className='banner'>
						<div className='logo-container'>{/* <Logo /> */}</div>
					</Col>
				</Row>
			</div>
		</AuthLayout>
	);
};

export default withErrorHandling(Reset);
