import { useQuery } from 'react-query';
import { z } from 'zod';

import { zapierApi } from '../../libs/zapier';
import { getZapierAccessToken } from '../../utils/zapier';

export const zapStepSchema = z.object({
	api: z.string(),
	description: z.string(),
	slug: z.string(),
	title: z.string(),
	url: z.string(),
	uuid: z.string(),
	image: z.string(),
	label: z.string().nullish(),
});

export type ZapStep = z.infer<typeof zapStepSchema>;

export const zapAppSchema = z.object({
	app: zapStepSchema,
});

type ZapApp = z.infer<typeof zapAppSchema>;

const zapSchema = z.object({
	id: z.number(),
	modified_at: z.string(),
	state: z.string(),
	title: z.string(),
	url: z.string(),
	steps: z.array(zapAppSchema),
});

type Zap = z.infer<typeof zapSchema>;

const getZapsSchema = z.object({
	count: z.number(),
	next: z.string().nullish(),
	previous: z.string().nullish(),
	objects: z.array(zapSchema),
});

const getZaps = async (): Promise<
	z.infer<typeof getZapsSchema> | undefined
> => {
	const accessToken = getZapierAccessToken();

	if (accessToken) {
		const { data } = await zapierApi.get<unknown>('/v1/zaps', {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		const parsed = getZapsSchema.parse(data);

		return parsed;
	} else {
		return undefined;
	}
};

export interface GetZap extends Zap {
	trigger?: ZapApp;
	triggerSlug: string;
	actionSlug: string;
}

export const useGetZaps = () => {
	const { data, ...rest } = useQuery<ReadonlyArray<GetZap>>(
		['getZaps'],
		async () => {
			const data = await getZaps();

			if (!data?.objects) return [];

			return data.objects.map<GetZap>((o) => {
				const [trigger, action] = o.steps;

				return {
					...o,
					trigger: trigger,
					triggerSlug: trigger?.app.slug ?? '',
					actionSlug: action?.app.slug ?? '',
				};
			});
		},
	);

	return {
		...rest,
		zaps: data ?? [],
	};
};
