import { useEffect, useState } from 'react';
import './Reactivation.scss';
import { useBetween } from 'use-between';
import useAuth from '../../../auth/useAuth';
import useClient from '../../../client/useClient';
import { toast } from 'react-toastify';
import PaymentMethod from '../../../../components/PaymentMethod';
import Smartlook from 'smartlook-client';

const Reactivation = ({ show, onClose }) => {
	const [paymentBrand, setPaymentBrand] = useState('visa');
	const [paymentLastFour, setPaymentLastFour] = useState('1234');
	const [paymentExpMonth, setPaymentExpMonth] = useState('06');
	const [paymentExpYear, setPaymentExpYear] = useState('2027');
	const [loading, setLoading] = useState(false);

	const { getStripeInfo, reactivateAccount } = useBetween(useClient);
	const { userId } = useBetween(useAuth);

	const handleReactivation = () => {
		setLoading(true);
		return reactivateAccount().then((response) => {
			toast.success('Account reactivated successfully!');
			setLoading(false);
			window.location.reload(false);
		});
	};

	useEffect(() => {
		getStripeInfo(userId).then((data) => {
			setPaymentBrand(data.activeCard.brand);
			setPaymentLastFour(data.activeCard.last4);
			setPaymentExpMonth(data.activeCard.exp_month);
			setPaymentExpYear(data.activeCard.exp_year);

			Smartlook.identify(userId);
		});
	}, []);

	if (!show) return null;

	return (
		<div className='reactivation__overlay position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center'>
			<div className='reactivation bg-light p-4 rounded overflow-auto'>
				<div className='reactivation__content p-4 text-center'>
					<h1>Reactivate Account</h1>
					<h5 className='mt-3'>This account is currently deactivated.</h5>
					<h5 className='mb-5'>Do you wish to reactivate your account?</h5>
					<PaymentMethod
						paymentBrand={paymentBrand}
						paymentLastFour={paymentLastFour}
						paymentExpMonth={paymentExpMonth}
						paymentExpYear={paymentExpYear}
					/>
					<button
						className={[
							'reactivation__content-btn text-light mt-3 w-50 rounded mx-2',
							loading ? 'opacity-25' : '',
						].join(' ')}
						onClick={handleReactivation}
						disabled={loading}
					>
						Confirm
					</button>
					<button
						className='reactivation__content-btn-close text-light mt-3 w-50 rounded'
						onClick={onClose}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default Reactivation;
