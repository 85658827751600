import { Col, Form, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './index.scss';
import { Download } from '../icons';
import { numberWithCommas } from '../../plugins';

const TransactionsFilters = ({
	fminDate,
	fmaxDate,
	tminDate,
	tmaxDate,
	from,
	setFrom,
	to,
	setTo,
	trees,
	orders,
	totalTrees,
	display,
	isAdmin,
	setDisplay,
	headers,
	csvData,
	order,
	setOrder,
}) => {
	const date = new Date();
	const month = date.getMonth() + 1;

	return (
		<Row className='order-filters'>
			<Col lg={2} md={4} xs={12} className='mb-3'>
				<Form.Group>
					<Form.Label>From</Form.Label>
					<DatePicker
						selected={from}
						onChange={(date) => setFrom(date)}
						dateFormat='yyyy-MM-dd'
						minDate={fminDate}
						maxDate={fmaxDate}
					/>
				</Form.Group>
			</Col>
			<Col lg={2} md={4} xs={12} className='mb-3'>
				<Form.Group>
					<Form.Label>To</Form.Label>
					<DatePicker
						selected={to}
						onChange={(date) => setTo(date)}
						dateFormat='yyyy-MM-dd'
						minDate={tminDate}
						maxDate={tmaxDate}
					/>
				</Form.Group>
			</Col>
			{isAdmin && (
				<Col lg={2} md={4} xs={12} className='mb-3'>
					<Form.Group>
						<Form.Label>Display by</Form.Label>
						<Form.Select
							selected={display}
							onChange={(event) => {
								setDisplay(event.target.value);
							}}
						>
							<option value='date'>Date</option>
							<option value='type'>Type</option>
						</Form.Select>
					</Form.Group>
				</Col>
			)}

			{isAdmin && (
				<Col lg={2} md={4} xs={12} className='mb-3'>
					<Form.Group>
						<Form.Label>Order</Form.Label>
						<Form.Select
							value={order}
							onChange={(event) => {
								setOrder(event.target.value);
							}}
						>
							<option value='desc'>Desc</option>
							<option value='asc'>Asc</option>
						</Form.Select>
					</Form.Group>
				</Col>
			)}
			<Col
				lg={{ span: 4, offset: 2 }}
				md={12}
				className='d-flex align-items-end justify-content-end mb-3 flex-wrap'
			>
				{/* <Form.Check type="switch" label="Show CVR" />
        <div className="export-button">
          <span>Export</span>
          <Download />
        </div> */}
			</Col>
		</Row>
	);
};

export default TransactionsFilters;
