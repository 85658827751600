import { useCallback, useState } from 'react';
import { ecodriveApi } from '../../libs/axios';

import { trackNetworkError } from '../../plugins/analytics';
import { env } from '../../utils/config';
import Smartlook from 'smartlook-client';

let setAuthHeader = (objectToCombine = null) => {
	let defaultHeaders = {
		// TODO: uncomment to enable JWT
		authorization: 'Bearer ' + localStorage.getItem('authorization'),
	};
	if (objectToCombine) {
		return { headers: Object.assign(defaultHeaders, objectToCombine) };
	}
	return { headers: defaultHeaders };
};

let setAuthHeaderAndParams = (params, headers) => {
	let objectWithHeadersKey = setAuthHeader(headers);
	let configObj = Object.assign({ params }, objectWithHeadersKey);
	return configObj;
};

export default function useClient() {
	const [totalOrders, setTotalOrders] = useState(0);
	const [business, setBusiness] = useState({});
	const [transaction, setTransaction] = useState({});
	const [stripe, setStripe] = useState({});
	const [firstLoad, setFirstLoad] = useState(false);
	const [trees, setTrees] = useState({});
	const [tpo] = useState(1);
	const [projects, setProjects] = useState([]);
	const [activeProject, setActiveProject] = useState({});
	const [isSubscriptionActive, setSubscriptionActive] = useState();

	return {
		totalOrders,
		business,
		stripe,
		firstLoad,
		trees,
		tpo,
		projects,
		activeProject,
		isSubscriptionActive,
		getApiKey() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(`/api-key`)
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/api-key`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		deleteApiKey(apiKey) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.delete(`/api-key`)
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/api-key`,
							data: {},
							error,
							requestType: 'delete',
						});
						reject(error.response.data);
					});
			});
		},
		businessDeactivate() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.patch(`/business/deactivate`)
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/api-key`,
							data: {},
							error,
							requestType: 'patch',
						});
						reject(error.response.data);
					});
			});
		},
		fetchOrders(shop) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/orders?shop=${shop}`,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						setTotalOrders(response.data.orders.length);
						resolve(response.data);

						Smartlook.track('FetchOrders', { type: 'FetchSuccessful' });
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/orders?shop=${shop}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		fetchTrees(params) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/dashboard-trees/' + params.businessId)
					.then((response) => {
						resolve(response.data);
						if (response.data) setTrees(response.data);

						Smartlook.track('FetchTrees', { type: 'FetchSuccessful' });
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/dashboard-trees`,
							data: params,
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		// TODO: sync on updates to where/when we calculate total trees planted
		fetchTPO(id, cId) {
			// return new Promise((resolve, reject) => {
			//   axios
			//     .get(`${process.env.REACT_APP_API_ENDPOINT}/trees-per-order?user_id=${id}&business_id=${cId}`, {
			//       headers: {
			//         "Content-Type": "multipart/form-data"
			//       }
			//     })
			//     .then((response) => {
			//       resolve(response.data);
			//       if (response.data.length > 0)
			//         setTpo(response.data[0].trees_per_order);
			//     })
			//     .catch((error) => {
			//       reject(error.response.data);
			//     })
			// })
		},
		// TODO: sync on updates to where/when we calculate total trees planted
		updateTPO(data) {
			// return new Promise((resolve, reject) => {
			//   axios
			//     .post(`${process.env.REACT_APP_API_ENDPOINT}/trees-per-order`, data, {
			//       headers: {
			//         "Content-Type": "multipart/form-data"
			//       }
			//     })
			//     .then((response) => {
			//       setTpo(parseInt(response.data.trees_per_order));
			//       resolve(response.data);
			//     })
			//     .catch((error) => {
			//       reject(error)
			//     })
			// })
		},
		async getBusiness(params) {
			try {
				const {
					data: {
						businessLegalName,
						businessName,
						companySite,
						customerId,
						subscriptionId,
						userId,
						...rest
					},
				} = await ecodriveApi.get(
					'/business',
					setAuthHeaderAndParams(params, {
						'Content-Type': 'multipart/form-data',
					}),
				);
				const returnObj = {
					...rest,
					business_legal_name: businessLegalName,
					business_name: businessName,
					company_site: companySite,
					customer_id: customerId,
					subscription_id: subscriptionId,
					admin_user_id: userId,
				};
				setBusiness(returnObj);
				Smartlook.track('GetBusiness', { type: 'FetchSuccessful' });

				return returnObj;
			} catch (error) {
				trackNetworkError({
					url: `${env.api.host}/business`,
					data: params,
					error,
					requestType: 'get',
				});
			}
		},
		getBusinessByStore(store) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/business?shop_name=${store}`,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						if (response.data) {
							setBusiness(response.data);
							if (response.data.length > 0) {
								localStorage.setItem('business_id', response.data[0].business_id);
							}

							Smartlook.track('GetBusinessByStore', {
								type: 'FetchSuccessful',
							});

							resolve(response.data);
						}
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/business?shop_name=${store}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		getBusinessById(businessId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/business/${businessId}`,
						setAuthHeader({
							'Content-Type': 'application/json',
						}),
					)
					.then(({ data }) => {
						setBusiness(data);
						Smartlook.track('GetBusinessById', {
							type: 'FetchSuccessful',
						});

						resolve(data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${env.api.host}/business/client/${businessId}`,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},

		putBusiness(data, businessId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.put(
						'/business/' + (businessId || ''),
						data,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then(({ data: business }) => {
						Smartlook.track('PutBusiness', { type: 'UpdateSuccessful' });

						resolve(business);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/business`,
							data: data,
							error,
							requestType: 'put',
						});
						reject(error);
					});
			});
		},
		reactivateAccount() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.put('/business/reactivate/')
					.then(({ data: business }) => {
						Smartlook.track('reactivateAccount', { type: 'UpdateSuccessful' });
						resolve(business);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/business/reactivate/`,
							error,
							requestType: 'put',
						});
						reject(error);
					});
			});
		},
		postBusiness(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post('/business', data)
					.then(({ data: business }) => {
						localStorage.setItem('business_id', business.id);
						setBusiness(business);

						Smartlook.track('PostBusiness', {
							type: 'CreationSuccessful',
						});

						resolve(business);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/business`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		getDiscount(discount) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/discount?code=' + discount)
					.then((response) => {
						Smartlook.track('GetDiscount', { type: 'FetchSuccessful' });

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/discount`,
							data: { discount },
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		async getTotalTreesPlanted(businessId) {
			try {
				Smartlook.track('GetIntegrationByBusinessId', {
					type: 'FetchSuccessful',
				});
				const { data } = await ecodriveApi.get(
					'/transactions/' + businessId + '/business',
				);
				setProjects(data || []);

				return data;
			} catch (error) {
				trackNetworkError({
					url: `${process.env.REACT_APP_API_ENDPOINT}/transactions/${businessId}/business`,
					data: {},
					error,
					requestType: 'get',
				});
			}
		},
		getActiveProject: useCallback(
			async (id) => {
				let path = '/planting-projects/active';
				if (id) path = `/planting-projects/${id}/active`;
				const { data: project } = await ecodriveApi.get(path);
				if (project) {
					const {
						business: [business],
					} = project;
					if (!business.active || !business.subscriptionId) {
						setSubscriptionActive('false');
					} else {
						setSubscriptionActive('true');
					}
					setActiveProject(project);
					Smartlook.track('GetActiveProject', { type: 'FetchSuccessful' });
				}
			},
			[setActiveProject],
		),
		getIntegrationByBusinessId(businessId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(`/integrations/${businessId}/business`)
					.then((response) => {
						Smartlook.track('GetIntegrationByBusinessId', {
							type: 'FetchSuccessful',
						});

						resolve(response.data.integrations);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/integrations/${businessId}/business`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		postIntegration(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post('/integrations', data)
					.then((response) => {
						Smartlook.track('PostIntegration', {
							type: 'CreationSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/integrations`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		putIntegration(integrationId, data) {
			return ecodriveApi
				.put(
					`/integrations/${integrationId}`,
					data,
					setAuthHeader({
						'Content-Type': 'application/json',
					}),
				)
				.then((response) => {
					Smartlook.track('PutIntegration', {
						type: 'UpdateSuccessful',
					});
					return response.data;
				})
				.catch((error) => {
					trackNetworkError({
						url: `${process.env.REACT_APP_API_ENDPOINT}/integrations/${integrationId}`,
						data: data,
						error,
						requestType: 'put',
					});
					throw error;
				});
		},

		postCustomTrees(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post(`/trees/add`, data)
					.then((response) => {
						Smartlook.track('PostCustomTrees', {
							type: 'CreationSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/trees/add`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},

		// create content entry when a business is created
		postContent(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post(
						'/content',
						data,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('PostContent', { type: 'CreationSuccessful' });

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/content`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		putContent(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.put('/content', data)
					.then((response) => {
						Smartlook.track('PutContent', { type: 'UpdateSuccessful' });

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/content`,
							data: data,
							error,
							requestType: 'put',
						});
						reject(error);
					});
			});
		},
		getContent(businessId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/content/' + businessId)
					.then(
						({
							data: {
								headerText,
								logoImage,
								logoLink,
								navText,
								subText,
								navLink,
								...rest
							},
						}) => {
							Smartlook.track('GetContent', { type: 'FetchSuccessful' });

							resolve({
								...rest,
								headerText: headerText,
								logoImage: logoImage,
								logoLink: logoLink,
								navText: navText,
								subText: subText,
								navLink: navLink,
							});
						},
					)
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/content`,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		// get a user after login... going to need this to set integration_id after schema updates
		getUser(id) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/users/me')
					.then((response) => {
						Smartlook.track('GetUser', {
							type: 'FetchSuccessful',
							userId: id,
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${env.api.host}/users?user_id=${id}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		getUserAdmin(id) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/users/' + id)
					.then((response) => {
						Smartlook.track('GetUser', {
							type: 'FetchSuccessful',
							userId: id,
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${env.api.host}/users?user_id=${id}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		putUser(data) {
			let userId = data.get('userId');
			return new Promise((resolve, reject) => {
				ecodriveApi
					.put(
						'/users/me',
						data,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('PutUser', {
							type: 'UpdateSuccessful',
							userId: userId,
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/users?user_id=${userId}`,
							data: data,
							error,
							requestType: 'put',
						});
						reject(error);
					});
			});
		},
		putAdminUser(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.put('/users', data)
					.then((response) => {
						Smartlook.track('PutUser', {
							type: 'UpdateSuccessful',
							userId: data.userId,
						});
						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/users`,
							data: data,
							error,
							requestType: 'put',
						});
						reject(error);
					});
			});
		},
		updatePasssword(data, userId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.patch(
						`/users/password`,
						data,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('UpdatePassword', {
							type: 'UpdateSuccessful',
							userId: userId,
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/users/password`,
							data: data,
							error,
							requestType: 'put',
						});
						reject(error);
					});
			});
		},

		getBusinessIdFromShopName(shopSlug) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/integrations/${shopSlug}/shop_url`,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('BusinessIDFromShopName', {
							type: 'FetchSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/integrations/${shopSlug}/shop_url`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		getBusinessIdFromBusinessId(businessId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/integrations/${businessId}/business_id`,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('BusinessIDFromBusinessId', {
							type: 'FetchSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/integrations/${businessId}/business_id`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		getIntegrations() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(`/integrations`)
					.then((response) => {
						Smartlook.track('getIntegrations', {
							type: 'FetchSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/integrations`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		getStripeInfo(userId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/stripe?user_id=${userId}`,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('StripeInfo', { type: 'FetchSuccessful' });

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/stripe?user_id=${userId}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		postStripe(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post(
						'/stripe',
						data,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then(({ data }) => {
						Smartlook.track('PostStripe', { type: 'PostSuccessful' });

						resolve(data);
						setStripe(data.business.customerId);
						localStorage.setItem('stripe_customer_id', data.business.customerId);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/stripe`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		addExtraTrees(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post(
						'/add-extratrees',
						data,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('AddExtraTrees', { type: 'PostSuccessful' });

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/add-extratrees`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		changeCard(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post(
						'/change-card',
						data,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('ChangeCard', { type: 'PostSuccessful' });

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/change-card`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		getAdminDashboardOrders(params = {}) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						'/admin/orders-all',
						setAuthHeaderAndParams(params, {
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('AdminDashboardOrders', {
							type: 'FetchSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/admin/orders-all`,
							data: params,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		getAdminDashboardBusinesses(params = {}) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						'/admin/businesses-all',
						setAuthHeaderAndParams(params, {
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('AdminDashboardBusinesses', {
							type: 'FetchSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/admin/businesses-all`,
							data: params,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		getAdminDashboardBusinessTransactions(businessId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/business/${businessId}/transactions?limit=50`,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('AdminDashboardBusinessTransactions', {
							type: 'FetchSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/transactions/${businessId}/business?limit=50`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		getAdminDashboardBusinessIntegrations(businessId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(`/admin/${businessId}/integrations`)
					.then((response) => {
						Smartlook.track('AdminDashboardBusinessIntegrations', {
							type: 'FetchSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/business/${businessId}/integrations`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},

		getProjects(params) {
			const urlParams = new URLSearchParams();
			for (const key in params) {
				urlParams.set(key, params[key]);
			}
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/planting-projects?${urlParams.toString()}`,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('Projects', { type: 'FetchSuccessful' });

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/projects}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		getProject(projectId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						`/planting-projects/${projectId}`,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('Project', { type: 'FetchSuccessful' });

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/project/${projectId}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		putBusinessProject(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.put(`/business/project`, data)
					.then((response) => {
						Smartlook.track('BusinessProject', { type: 'PutSuccessful' });

						setBusiness(response.data);
						resolve(response);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/business/project/${data.projectId}`,
							data: data,
							error,
							requestType: 'put',
						});
						reject(error);
					});
			});
		},
		setFirst(value) {
			return new Promise((res) => {
				setFirstLoad(value);

				Smartlook.track('FirstLoad', { type: 'SetSuccessful' });

				res();
			});
		},
		addStripePayment(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post(
						'/business/payment',
						data,
						setAuthHeader({
							'Content-Type': 'application/json',
						}),
					)
					.then((response) => {
						Smartlook.track('AddStripePayment', {
							type: 'PostSuccessful',
						});

						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/business/payment`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		postNPSFeedback(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post('/nps', data, setAuthHeader())
					.then(({ data: npsFeedback }) => {
						Smartlook.track('PostNPSFeedback', { type: 'FeedbackSubmitted' });
						resolve(npsFeedback);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/nps`,
							data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		getNPSData() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/nps', setAuthHeader())
					.then(({ data: npsData }) => {
						resolve(npsData);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/nps`,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		getNPSDataByBusinessId(businessId) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(`/nps/business/${businessId}`, setAuthHeader())
					.then(({ data: npsData }) => {
						resolve(npsData);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/nps/business/${businessId}`,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		updateLastPromptDate() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.put(`/nps/last-prompt-date`, null, setAuthHeader())
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						if (error.isAxiosError) {
							reject(error.response.data);
						}
						reject(error);
					});
			});
		},
		shouldPromptNPS() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(`/nps/should-prompt`, setAuthHeader())
					.then(({ data }) => {
						resolve(data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/nps/should-prompt`,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		fetchTemplates(templates, apps, limit = 5, offset = 0) {
			return new Promise((resolve, reject) => {
				let url = `/templates?`;

				if (templates) url += `templates=${templates}&`;
				if (apps) url += `apps=${apps}&`;
				if (limit) url += `limit=${limit}&`;
				if (offset || offset === 0) url += `offset=${offset}&`;

				url = url.endsWith('&') ? url.slice(0, -1) : url;

				ecodriveApi
					.get(url, setAuthHeader())
					.then(({ data }) => {
						resolve(data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/templates`,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		fetchPlatformIntegrations() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/platform-integrations', setAuthHeader())
					.then(({ data }) => {
						resolve(data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/platform-integrations`,
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},

		requestNewIntegration(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post('/request-integration', data)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/request-integration`,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
	};
}
