import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const RenderProject = ({
	id,
	projectKey,
	inventory,
	price,
	onEdit,
	onDelete,
}) => {
	const [hovering, setHovering] = useState(false);
	return (
		<div
			key={id}
			className='position-relative'
			onMouseOver={() => {
				setHovering(true);
			}}
			onMouseLeave={() => {
				setHovering(false);
			}}
		>
			<div className='client-row mb-2'>
				<span className='row-name'>{projectKey}</span>
				<span className='row-value'>{inventory}</span>
				<span className='row-value'>{price}</span>
			</div>
			{hovering && (
				<div
					className='position-absolute end-0 top-0 m-0'
					style={{ transform: 'translate(-8px,7px)' }}
				>
					<Button
						variant='secondary rounded-circle me-2'
						size='sm'
						onClick={() => onEdit()}
					>
						<i className='bi bi-pencil'></i>
					</Button>
					<Button variant='danger rounded-circle' size='sm' onClick={onDelete}>
						<i className='bi bi-trash'></i>
					</Button>
				</div>
			)}
		</div>
	);
};

export default RenderProject;
