/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import './virtualTree.scss';

const VirtualTree = ({ project }) => {
	let treeHeader;
	let DescriptionTextComponent = () => null;
	let modelViewerSource;
	let modelViewerClass = 'glb-tree';
	let buttonLink;
	let labelText;
	if (project === 'MAD') {
		treeHeader = 'Virtual Mangrove';
		DescriptionTextComponent = () => (
			<p className='text-center'>
				Explore the power of the trees we plant together!
				<br />
				The mangrove, which is one of the most efficient tree species for carbon
				capture due to its heavy and dense roots.
			</p>
		);
		modelViewerSource =
			'https://ecodrive-dashboard-files.s3.amazonaws.com/tree.glb';
		modelViewerClass = modelViewerClass + ' glb-tree--madagascar';
		buttonLink = `https://www.google.com/maps/place/16%C2%B012'46.3%22S+44%C2%B043'57.7%22E/@-16.212851,44.730496,967m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x4808af58aab1d4c2!8m2!3d-16.212851!4d44.73269`;
		labelText = `Exact coordinates: 16°12'46.3"S 44°43'57.7"E`;
	} else if (project === 'KEN') {
		treeHeader = 'Tree Planting in Kenya';
		DescriptionTextComponent = () => (
			<p className='text-center'>
				Explore the power of the trees we plant together!
				<br />
				The mangrove, which is one of the most efficient tree species for carbon
				capture due to its heavy and dense roots.
			</p>
		);
		modelViewerSource =
			'https://ecodrive-dashboard-files.s3.amazonaws.com/tree.glb';
		modelViewerClass = modelViewerClass + ' glb-tree--kenya';
		buttonLink = `https://goo.gl/maps/aC4nBpLwbLWtY8eT7`;
		labelText = `Exact coordinates: 4°04'37.6"S 39°33'22.6"E`;
	} else if (project === 'HI') {
		treeHeader = 'Tree Planting in Hawaii';
		DescriptionTextComponent = () => (
			<p className='text-center'>
				Explore the power of the trees we plant together!
				<br />
				The koa, which is one of the most efficient tree species for sequestering
				carbon that provides benefits such as erosion prevention, enhanced water
				quality and biodiversity support.
			</p>
		);
	} else if (project === 'THAI') {
		treeHeader = 'Virtual Banyan Tree';
		DescriptionTextComponent = () => (
			<p className='text-center'>
				Explore the power of the trees we plant together!
				{/* <br />
        Thailand lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt aliqua. */}
			</p>
		);
		modelViewerSource = '';
		modelViewerClass = modelViewerClass + ' glb-tree--thailand';
		buttonLink = `https://goo.gl/maps/jP8EJsRNcfBb5R4A9`;
		labelText = `Exact coordinates: 15°11'01.2"N 102°30'27.7"E`;
	} else if (project === 'KELP') {
		treeHeader = 'Virtual Kelp';
		DescriptionTextComponent = () => (
			<p className='text-center'>
				Explore the power of the trees we plant together!
				{/* <br />
        Kelp lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt aliqua. */}
			</p>
		);
		modelViewerSource = '';
		modelViewerClass = modelViewerClass + ' glb-tree--kelp';
		buttonLink = `https://goo.gl/maps/kD9uZPKW1zo25Y6ZA`;
		labelText = `Exact coordinates: 8°28'51.8"N 15°25'59.7"W`;
	} else if (project === 'MICH') {
		treeHeader = 'Virtual Spruce';
		DescriptionTextComponent = () => (
			<p className='text-center'>
				Explore the power of the trees we plant together!
				{/* <br />
        Michigan lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt aliqua. */}
			</p>
		);
		modelViewerSource = '';
		modelViewerClass = modelViewerClass + ' glb-tree--michigan';
		buttonLink = `https://goo.gl/maps/brqD5hkFwfcQzPX87`;
		labelText = `Exact coordinates: 46°14'47.3"N 86°16'17.5"W`;
	} else if (project === 'MONT') {
		treeHeader = 'Virtual Ponderosa Pine';
		DescriptionTextComponent = () => (
			<p className='text-center'>
				Explore the power of the trees we plant together!
				{/* <br />
        The ponderosa pine, which is one of the most efficient tree species for
        carbon capture due to its heavy and dense roots. */}
			</p>
		);
		modelViewerSource = '';
		modelViewerClass = modelViewerClass + ' glb-tree--montana';
		buttonLink = `https://goo.gl/maps/vTCT41wUT3b75tUC9`;
		labelText = `Exact coordinates: 48°00'24.8"N 108°54'28.8"W`;
	} else {
		return null;
	}
	if (project !== 'KEN' && project !== 'HI') return null;
	return (
		<Row className='virtual-forest'>
			<Col className='d-flex flex-column align-items-center'>
				<h2>{treeHeader}</h2>
				<DescriptionTextComponent />
			</Col>
		</Row>
	);
};

export default VirtualTree;
