import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import {
	AmericanExpress,
	EMailIcon,
	EditIcon,
	DownloadIcon,
	AddIcon,
} from '../icons';
import { useNavigate } from 'react-router-dom';
import { useBetween } from 'use-between';
import useClient from '../../pages/client/useClient';
import useAuth from '../../pages/auth/useAuth';
import Smartlook from 'smartlook-client';

import './PaymentMethod.scss';

function PaymentMethod({
	paymentBrand,
	paymentLastFour,
	paymentExpMonth,
	paymentExpYear,
}) {
	const [businessEmail, setBusinessEmail] = useState(
		'placeholderemail@gmail.com',
	);
	const [treesPerTransaction, setTreesPerTransaction] = useState(1);

	const navigate = useNavigate();
	const { getStripeInfo, getIntegrationByBusinessId, getBusiness } =
		useBetween(useClient);
	const { userId, businessId } = useBetween(useAuth);

	useEffect(() => {
		getBusiness({ business_id: businessId })
			.then((data) => {
				setBusinessEmail(data.business_info['email']);
				return getIntegrationByBusinessId(businessId);
			})
			.then((data) => {
				if (!data.length) return;
				if (data.length === 1) {
					setTreesPerTransaction(data[0].trees_per_transaction.toFixed(2));
				} else {
					let shopifyIntegration = data.find((el) => el.integration === 'shopify');
					setTreesPerTransaction(
						shopifyIntegration.trees_per_transaction.toFixed(2),
					);
				}
			})
			.catch((err) => {
				console.error('SOMETHING WENT WRONG', err);
			});
	}, []);

	return (
		<div className='payment-method'>
			<h3>Payment Method</h3>
			<h6>Update the card on file</h6>
			<div className='payment-method__methods'>
				<div className='payment-method__methods-item'>
					<div className='payment-method__methods-info'>
						<AmericanExpress />
						<div>
							<p>
								{paymentBrand.toUpperCase()} ending in {paymentLastFour}
							</p>
							<em>
								EXP: {paymentExpMonth}/{paymentExpYear}
							</em>
							<em>
								<EMailIcon />
								{businessEmail}
							</em>
						</div>
					</div>

					<Button
						onClick={() => navigate('/settings/billing/add', { replace: true })}
						className='payment-method__methods-btn'
					>
						<AddIcon className='payment-method__methods-svg' />
						<span className='payment-method__methods-span'>Add</span>
					</Button>
				</div>
			</div>
		</div>
	);
}

export default PaymentMethod;
