import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const Italic = ({ onClick, isActive }) => (
	<OverlayTrigger overlay={<Tooltip>Ctrl+I</Tooltip>}>
		<Button variant='light' onClick={onClick} active={isActive('ITALIC')}>
			<i className='bi bi-type-italic'></i>
		</Button>
	</OverlayTrigger>
);

export default Italic;
