import React from 'react';
import { Button } from 'react-bootstrap';

const OrderedList = ({ onClick, isActive }) => (
	<Button
		variant='light'
		onClick={onClick}
		active={isActive('ordered-list-item')}
	>
		<i className='bi bi-list-ul'></i>
	</Button>
);

export default OrderedList;
