import React, { useContext, useState } from 'react';
import { useBetween } from 'use-between';
import useClient from '../../useClient';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './DeactivateAccount.scss';
import Reactivation from '../reactivation';
import { Ecodrive } from '../../../../context/store';
import { useLogout } from '../../../../hooks/useAuth';

const DeactivateAccount = () => {
	const { businessDeactivate } = useBetween(useClient);
	const [{ business }] = useContext(Ecodrive);
	const isSubscriptionActive = business?.subscription.active;

	const logout = useLogout();
	const [showDeactivateModal, setShowDeactivateModal] = useState(false);

	const handleButtonClick = () => {
		setShowDeactivateModal(true);
	};

	const handleActivateButtonClick = () => {
		setShowDeactivateModal(true);
	};

	const deactivateConfirmed = async () => {
		const data = await businessDeactivate();
		toast.success(data.message);
		logout();
	};

	const handleCloseDeactivateModal = () => {
		setShowDeactivateModal(false);
	};

	return !isSubscriptionActive ? (
		<div className='manage-account'>
			<Row>
				<Col>
					<h1>Activate Account</h1>
					<h6 className='m-0'>You can reactivate your account here.</h6>
					<Button
						variant='success'
						className='manage-account__button-submit my-3'
						onClick={() => handleActivateButtonClick()}
					>
						Activate Account
					</Button>
				</Col>
			</Row>
			<Reactivation
				show={showDeactivateModal}
				onClose={handleCloseDeactivateModal}
			/>
		</div>
	) : (
		<div className='manage-account'>
			<Row>
				<Col>
					<h1>Deactivate Account</h1>
					<h6 className='m-0'>You can suspend your account here.</h6>
					<Button
						variant='danger'
						className='manage-account__button-submit my-3'
						onClick={() => handleButtonClick()}
					>
						Deactivate Account
					</Button>
				</Col>
			</Row>
			<Modal show={showDeactivateModal} onHide={handleCloseDeactivateModal}>
				<Modal.Header className='manage-account__header' closeButton>
					<Modal.Title className='manage-account__header__title'>
						Confirm Deactivation
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='manage-account__body'>
					<h3>Deactivating Implications:</h3>
					<ul className='manage-account__body__pl-4'>
						<li className='manage-account__body__list-item my-2'>
							Pauses tree-planting efforts
						</li>
						<li className='manage-account__body__list-item my-2'>
							Deactivates badges
						</li>
						<li className='manage-account__body__list-item my-2'>
							Limits public dashboard access
						</li>
					</ul>
					<p className='manage-account__body__paragraph my-2'>
						Please consider the environmental impact of these actions. Your
						understanding is sincerely appreciated.
					</p>
				</Modal.Body>
				<Modal.Footer className='manage-account__footer'>
					<Button
						variant='secondary'
						onClick={handleCloseDeactivateModal}
						className='btn'
					>
						Cancel
					</Button>
					<Button
						variant='danger'
						onClick={deactivateConfirmed}
						className='btn manage-account__footer__deactivate'
					>
						Deactivate
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default DeactivateAccount;
