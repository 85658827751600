import { Button } from 'react-bootstrap';

import './index.scss';

const Trees = ({ count, setCount, plus, minus }) => {

  return (
    <div className="trees-order">
      <label>How many trees would you like to plant?</label>

      <div className="trees-count">
        <Button disabled={count === 1} onClick={() => minus()}>-</Button>
        <span>{count}</span>
        <Button onClick={() => plus()}>+</Button>
      </div>
    </div>
  )
}

export default Trees;
