import { useState } from 'react';
import { ecodriveApi } from '../../libs/axios';

export default function useAdmin() {
	const [businessesInfo, setBusinessesInfo] = useState([]);

	return {
		businessesInfo,
		getBusinesses(sort_type, from, to) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get(
						from
							? `/businesses-info?from_date=${from}&to_date=${to}&sort_type=${sort_type}`
							: `/businesses-info?sort_type=${sort_type}`,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						},
					)
					.then((response) => {
						resolve(response.data);
						setBusinessesInfo(response.data || []);
					})
					.catch((error) => {
						reject(error.response.data);
					});
			});
		},
	};
}
