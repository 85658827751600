import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Smartlook from 'smartlook-client';

import { Login, Register, Verify, Confirm, Forgot, Reset } from './pages/auth';
import { Setup, Setting, Settings } from './pages/client';
import {
	PartnerDashboard,
	ClientDashboard,
	ClientPublicDashboard,
} from './pages/client/dashboard';
import NPSPage from './pages/admin/dashboard/nps/index';
import { Dashboard, Client } from './pages/admin';
import Places from './components/googleapis/places';
import ZapierAuth from './components/ZapierAuth';
import EcodriveProvider from './context/store';
import WithRedirect from './context/redirect/WithRedirect';
import { QueryProvider } from './context/QueryClient';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'draft-js/dist/Draft.css';

const ScrollToTop = (props) => {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return <>{props.children}</>;
};

export default function App() {
	Smartlook.init(process.env.REACT_APP_SMARTLOOK_TOKEN, {
		cookies: false,
		region: 'eu',
	});

	return (
		<QueryProvider>
			<EcodriveProvider>
				<div className='layout'>
					<Places>
						<ScrollToTop>
							<Routes>
								<Route path='/login' element={<Login isPublic />} />
								<Route path='/register' element={<Register />} />
								<Route path='/forgot-password' element={<Forgot />} />
								<Route path='/reset-password' element={<Reset />} />
								<Route path='/verify' element={<Verify />} />
								<Route path='/confirm/:token' element={<Confirm />} />
								<Route
									path='/setup'
									element={
										<WithRedirect access={['setup']}>
											<Setup />
										</WithRedirect>
									}
								/>
								<Route
									path='/setting'
									element={
										<WithRedirect>
											<Setting />
										</WithRedirect>
									}
								/>
								<Route
									path='/settings/*'
									element={
										<WithRedirect>
											<Settings />
										</WithRedirect>
									}
								/>
								<Route
									path='/client-resources'
									element={
										<WithRedirect>
											<ClientDashboard />
										</WithRedirect>
									}
								/>
								<Route
									path='/client-dashboard'
									element={
										<WithRedirect>
											<ClientPublicDashboard />
										</WithRedirect>
									}
								/>
								<Route
									path='/partner-dashboard'
									element={
										<WithRedirect>
											<PartnerDashboard />
										</WithRedirect>
									}
								/>
								<Route
									path='/partner-auth'
									element={
										<WithRedirect>
											<ZapierAuth />
										</WithRedirect>
									}
								/>
								<Route
									path='/public-dashboard/:store'
									element={<ClientPublicDashboard isPublic={true} />}
								/>
								<Route
									path='/nps-dash'
									element={
										<WithRedirect access={['admin']}>
											<NPSPage />
										</WithRedirect>
									}
								/>
								<Route
									path='/dashboard'
									element={
										<WithRedirect access={['admin']}>
											<Dashboard />
										</WithRedirect>
									}
								/>
								<Route
									path='/client/:id'
									element={
										<WithRedirect access={['admin']}>
											<Client />
										</WithRedirect>
									}
								/>
								<Route path='*' element={<Navigate replace to='/login' />} />
							</Routes>
						</ScrollToTop>
						<ToastContainer
							position='top-right'
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							draggable
						/>
					</Places>
				</div>
			</EcodriveProvider>
		</QueryProvider>
	);
}
