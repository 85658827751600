import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useBetween } from 'use-between';
import useClient from '../../pages/client/useClient';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { toast } from 'react-toastify';

const CustomizedButton = styled(Button)({
	backgroundColor: '#77cfc6',
	color: '#fff',
	width: '100%',
	fontWeight: 600,
	margin: '5% 0',
	'&:hover': {
		backgroundColor: '#77cfc6',
	},
});

const CustomizedContainer = styled(Box)({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: '#fff',
	boxShadow: 24,
	padding: '5% 3%',
	borderRadius: '20px',
	width: '50%',
});

const CustomizedForm = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
});

function RequestIntegrationModal({ openModal, handleModal }) {
	const [integrationName, setIntegrationName] = useState('');
	const [integrationText, setIntegrationText] = useState('');
	const [integrationVolume, setIntegrationVolume] = useState('');
	const { requestNewIntegration } = useBetween(useClient);

	const sendIntegrationRequest = () => {
		const body = {
			integrationName: integrationName,
			integrationText: integrationText,
			integrationVolume: integrationVolume,
		};
		requestNewIntegration(body)
			.then(() => {
				toast.success('New Integration Request Succesfully sent!');
				handleModal();
			})
			.catch((error) => {
				toast.error('Error sending request');
			});
	};
	return (
		<Modal open={openModal} onClose={handleModal}>
			<CustomizedContainer>
				<Typography id='modal-modal-title' variant='h6' component='h2'>
					Request new integration
				</Typography>
				<CustomizedForm>
					<TextField
						sx={{ my: 1 }}
						fullWidth
						label='Name of the integration'
						variant='outlined'
						onChange={(event) => setIntegrationName(event.target.value)}
					/>

					<TextField
						sx={{ my: 1 }}
						fullWidth
						label='Why do you need this integration?'
						multiline
						rows={5}
						variant='outlined'
						onChange={(event) => setIntegrationText(event.target.value)}
					/>

					<Select
						labelId='expected-volume'
						displayEmpty
						value={integrationVolume}
						onChange={(event) => setIntegrationVolume(event.target.value)}
					>
						<MenuItem disabled value=''>
							Expected Volume
						</MenuItem>
						<MenuItem value={'1-100'}>1-100</MenuItem>
						<MenuItem value={'101-1000'}>101-1000</MenuItem>
						<MenuItem value={'1001+'}>1001+</MenuItem>
					</Select>
				</CustomizedForm>
				<CustomizedButton onClick={sendIntegrationRequest}>Send</CustomizedButton>
			</CustomizedContainer>
		</Modal>
	);
}

export default RequestIntegrationModal;
