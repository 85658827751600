import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { USER_ENUM } from '../actions/user.actions';

const profileValidator = Yup.object({
	id: Yup.string().required(),
	firstName: Yup.string().required(),
	lastName: Yup.string().required(),
	email: Yup.string().email().required(),
	businessId: Yup.string().nullable(),
	phone: Yup.string(),
	verified: Yup.boolean().required(),
	role: Yup.number().required(),
});

const UserReducer = (state, action) => {
	const { type, ...next } = action;
	try {
		switch (action.type) {
			case USER_ENUM.FILL_PROFILE:
				const profile = profileValidator.validateSync(next, { stripUnknown: true });
				return {
					token: state.token,
					profile: {
						...profile,
						role: ['user', 'admin'][profile.role],
					},
					logged: true,
				};

			case USER_ENUM.EMIT_TOKEN_EVENT:
				return {
					token: next.token,
				};

			case USER_ENUM.CLEAR:
				return {
					token: undefined,
				};
			default:
				throw new Error('Unknown action: ' + action.type);
		}
	} catch (e) {
		toast.error('[User Store] ' + e.message, { toastId: 'user' });
		return state;
	}
};

export default UserReducer;
