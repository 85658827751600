import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useEcodrive } from '../../context/store/Ecodrive';
import { usePostIntegration } from '../../hooks/integrations/usePostIntegration';
import { useGetBusinessIntegrations } from '../../hooks/integrations/useGetBusinessIntegrations';

const ZapierAuth = () => {
	const mutate = usePostIntegration();
	const navigate = useNavigate();
	const location = useLocation();
	const hash = location.hash.substring(1);
	const [ecodrive] = useEcodrive() as unknown as ReadonlyArray<{
		business: { id: string };
	}>;
	const id = ecodrive?.business.id;
	const { businessIntegrations, isLoading, isError } =
		useGetBusinessIntegrations(id);

	const existingIntegration = businessIntegrations.find(
		(integration) =>
			integration.type === 'zap' && integration.integration === 'zapier',
	);

	const params = hash
		.split('&')
		.reduce<{ access_token?: string; expires_in?: string }>((acc, kv) => {
			const [key, value] = kv.split('=');

			if (key === 'access_token') acc.access_token = value;
			if (key === 'expires_in') acc.expires_in = value;

			return acc;
		}, {});

	const { access_token, expires_in } = params;

	useEffect(() => {
		const handleError = () => {
			toast.error(
				'Sorry, there was an error while trying to authenticate your Zapier account',
			);
			navigate('/settings/integrations');

			window.localStorage.removeItem('zapier_access_token');
			window.localStorage.removeItem('zapier_expires_in');
		};

		if (access_token && expires_in) {
			window.localStorage.setItem('zapier_access_token', access_token);
			window.localStorage.setItem('zapier_expires_in', expires_in);

			if (!isLoading && !isError) {
				if (existingIntegration) {
					window.close();
				} else {
					const integrationFormData = {
						businessId: id!,
						type: 'zap',
						integration: 'zapier',
						authToken: access_token,
						project: 'KEN',
					};

					mutate.mutate(integrationFormData, {
						onSuccess: () => {
							window.close();
						},
						onError: (err) => {
							console.error(err);
							handleError();
						},
					});
				}
			} else if (!isLoading && isError) {
				handleError();
			}
		} else {
			handleError();
		}
	}, [id, isLoading, isError, existingIntegration, access_token, expires_in]);

	return (
		<div>
			<h1>Authenticating to Zapier...</h1>
		</div>
	);
};

export default ZapierAuth;
