/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import './projectContent.scss';

import Trees from '../../../../assets/images/dashboard/trees.png';
import KELPBanner from '../../../../assets/images/dashboard/KELPBanner.png';
import MichiganBanner from '../../../../assets/images/dashboard/MichiganBanner.png';

import { PROJECT_KEYS } from '../../../../plugins/projects';

const TO_RENDER = {
	MAD: (
		<div key='MAD'>
			<Row className='tree-content'>
				<Col className='d-flex flex-column align-items-center justify-content-center'>
					<img src={Trees} alt='trees' />
					<Button
						variant='link'
						href='https://drive.google.com/drive/folders/1DTklmi6x-Gauc16oa27UWwMLCi9qQw63'
						target='_blank'
						className='dashboard-button'
					>
						Download Madagascar Tree Content
					</Button>
				</Col>
			</Row>
		</div>
	),
	KEN: (
		<div key='KEN'>
			<Row className='tree-content'>
				<Col className='d-flex flex-column align-items-center justify-content-center'>
					<img src={Trees} alt='trees' />
					<Button
						variant='link'
						href='https://drive.google.com/drive/folders/1GgBYUF0zqD6JafmzshotHe9cv0y4l6Ib'
						target='_blank'
						className='dashboard-button'
					>
						Download Kenya Tree Content
					</Button>
				</Col>
			</Row>
		</div>
	),
	THAI: (
		<div key='THAI'>
			<Row className='tree-content'>
				<Col className='d-flex flex-column align-items-center justify-content-center'>
					<img src={Trees} alt='trees' />
					<Button
						variant='link'
						href='#'
						target='_blank'
						className='dashboard-button'
					>
						Download Thailand Tree Content
					</Button>
				</Col>
			</Row>
		</div>
	),
	KELP: (
		<div key='KELP'>
			<Row className='tree-content'>
				<Col className='d-flex flex-column align-items-center justify-content-center'>
					<img src={KELPBanner} alt='trees' />
					<Button
						variant='link'
						href='https://drive.google.com/drive/folders/1nIxh6pqw4jIVwayx3eExv7tE0shosx8M'
						target='_blank'
						className='dashboard-button'
					>
						Download Kelp Tree Content
					</Button>
				</Col>
			</Row>
		</div>
	),
	MICH: (
		<div key='MICH'>
			<Row className='tree-content'>
				<Col className='d-flex flex-column align-items-center justify-content-center'>
					<img src={MichiganBanner} alt='trees' />
					<Button
						variant='link'
						href='#'
						target='_blank'
						className='dashboard-button'
					>
						Download Michigan Tree Content
					</Button>
				</Col>
			</Row>
		</div>
	),
	MONT: (
		<div key='MONT'>
			<Row className='tree-content'>
				<Col className='d-flex flex-column align-items-center justify-content-center'>
					<img src={Trees} alt='trees' />
					<Button
						variant='link'
						href='#'
						target='_blank'
						className='dashboard-button'
					>
						Download Montana Tree Content
					</Button>
				</Col>
			</Row>
		</div>
	),
};

const ProjectContent = ({ project = {} }) => (
	<Container className='dashboard-content'>
		{TO_RENDER[project.projectKey]}
	</Container>
);

export default ProjectContent;
