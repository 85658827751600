import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Ecodrive } from '../store';
import useLocalStorage from '../../hooks/useLocalStorage';
import * as yup from 'yup';

const billingSchema = yup.object({
	address1: yup.string().required(),
	address2: yup.string(),
	city: yup.string().required(),
	country: yup.string().required(),
	state: yup.string().required(),
	zip: yup.string().required(),
});

const subscriptionSchema = yup.object({
	id: yup.string().nullable(),
	customer: yup.string().required(),
	active: yup.boolean().required(),
});

const WithRedirect = ({ children, access = [] }) => {
	const [{ user, business }] = useContext(Ecodrive);
	const token = useLocalStorage('authorization');

	if (!('profile' in user)) {
		return <></>;
	}

	if (!token) return <Navigate to='/login' />;

	if (!user.profile.verified) {
		return <Navigate to='/verify' />;
	}

	if (!access.includes('admin') && user.profile.role === 'admin') {
		return <Navigate to='/dashboard' />;
	}
	if (access.includes('admin') && user.profile.role !== 'admin') {
		return <Navigate to='/client-resources' />;
	}

	if (
		!access.includes('setup') &&
		!access.includes('admin') &&
		!('id' in business)
	)
		return <></>;

	if (!access.includes('admin') && !access.includes('setup')) {
		try {
			billingSchema.validateSync(business.billing);
			subscriptionSchema.validateSync(business.subscription);
		} catch (error) {
			return <Navigate to='/setup' />;
		}
	}

	return <>{children}</>;
};

export default WithRedirect;
