import { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useBetween } from "use-between";
import Card from "react-credit-cards-2";
import { toast } from "react-toastify";
import withErrorHandling from "../../../../components/withErrorHandling";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  disallowOnlySpecialCharacters,
} from "../../../../plugins";
import useClient from "../../useClient";
import useAuth from "../../../auth/useAuth";
import CountrySelect from "../../../../components/countrySelect";

import "react-credit-cards-2/es/styles-compiled.css";

import SettingsLayout from "../layout";

import "./index.scss";

const BillingEdit = () => {
  const { getBusiness, putBusiness } = useBetween(useClient);
  const { userId, businessId } = useBetween(useAuth);
  const [focused, setFocused] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessLegalName, setBusinessLegalName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("US");
  const [companySite, setCompanySite] = useState("");
  const [active, setActive] = useState(1);

  const [cardNumber, setCardNumber] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvv, setCvv] = useState("");

  const handleInputChange = (event) => {
    if (event.target.name === "number") {
      event.target.value = formatCreditCardNumber(event.target.value);
      setCardNumber(event.target.value);
    } else if (event.target.name === "expiry") {
      event.target.value = formatExpirationDate(event.target.value);
      setExpDate(event.target.value);
    } else if (event.target.name === "cvc") {
      event.target.value = formatCVC(event.target.value, cardNumber);
      setCvv(event.target.value);
    }
  };

  useEffect(
    () => {
      getBusiness({ user_id: userId })
        .then((data) => {
          setBusinessLegalName(data.business_info["business_legal_name"]);
          setBusinessName(data.business_info["business_name"]);
          setAddress1(data.business_info["address1"]);
          setAddress2(data.business_info["address2"]);
          setCity(data.business_info["city"]);
          setState(data.business_info["state"]);
          setZip(data.business_info["zip"]);
          if (data.business_info["country"]) {
            setCountry(data.business_info["country"]);
          } else {
            setCountry("US");
          }
          setCompanySite(data.business_info["company_site"]);
          setActive(data.business_info["active"]);
        })
    },
    [
      // getBusiness, userId
    ]
  );

  const updateContent = async () => {
    const formData = new FormData();

    formData.append("business_id", businessId);
    formData.append("business_legal_name", businessLegalName);
    formData.append("business_name", businessName);
    formData.append("address1", address1);
    formData.append("address2", address2);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zip", zip);
    formData.append("country", country ? country : "US");
    formData.append("company_site", companySite);
    formData.append("active", active);
    formData.append("card_number", cardNumber);
    formData.append("expiry_date", expDate);
    formData.append("cvv", cvv);

    try {
      await putBusiness(formData);
      toast.success(`Billing info Updated!`);
    } catch (error) {
      toast.error(`An error occured when updating your billing info`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContent();
  };

  return (
    <SettingsLayout title="Billing" title2="Edit Card">
      <div className="billing-information">
        {/*
        <h2><ToggleSwitch Name={"monthly_billing"} Status={status} setStatus={setStatus} />Monthly Billing Receipts: {status ? 'ON' : 'OFF'}</h2>
        <h6>You will still get an email receipt if you choose to plant 100 or 1,000 trees in bulk via the dashboard.</h6>
        */}
        <h1>Billing Information</h1>
        <h6>
          Make sure you keep your billing up to date to avoid a pause in
          planting!
        </h6>

        <Form onSubmit={(event) => handleSubmit(event)}>
          <Row>
            <Col sm={12} md={7}>
              <Row>
                <Col md={6} sm={12} className="billing-info-col">
                  <Form.Label>Credit Card Number</Form.Label>
                  <Form.Control
                    type="tel"
                    name="number"
                    pattern="[\d| ]{16,22}"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    onFocus={(event) => {
                      setFocused(event.target.name);
                    }}
                    placeholder="Your Credit Card Number"
                    value={cardNumber}
                    required
                  />
                </Col>
                <Col md={6} sm={12} className="billing-info-col">
                  <Form.Label>CVV</Form.Label>
                  <Form.Control
                    type="tel"
                    name="cvc"
                    pattern="\d{3,4}"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    onFocus={(event) => {
                      setFocused(event.target.name);
                    }}
                    placeholder="Your CVV"
                    value={cvv}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12} className="billing-info-col">
                  <Form.Label>Expire Date</Form.Label>
                  <Form.Control
                    type="tel"
                    name="expiry"
                    pattern="\d\d/\d\d"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    onFocus={(event) => {
                      setFocused(event.target.name);
                    }}
                    placeholder="MM/YY"
                    value={expDate}
                    autoComplete="cc-exp"
                    maxLength={5}
                    required
                  />
                </Col>
                <Col md={6} sm={12} className="billing-info-col">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control
                    value={zip}
                    onChange={(event) => setZip(event.target.value)}
                    placeholder="Your Zipcode"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} className="billing-info-col">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    value={address1}
                    onChange={(event) => setAddress1(event.target.value)}
                    placeholder="Your Address"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} className="billing-info-col">
                  <Form.Control
                    value={address2}
                    onChange={(event) => setAddress2(event.target.value)}
                    placeholder="Apartment, Suite, Unit, Floor etc"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} className="billing-info-col">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    value={city}
                    onChange={(event) =>
                      setCity(
                        disallowOnlySpecialCharacters(event.target.value, city)
                      )
                    }
                    placeholder="City"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12} className="billing-info-col">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    value={state}
                    onChange={(event) =>
                      setState(
                        disallowOnlySpecialCharacters(event.target.value, state)
                      )
                    }
                    placeholder="Your State"
                    required
                  />
                </Col>
                <Col md={6} sm={12} className="billing-info-col">
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <CountrySelect country={country} setCountry={setCountry} />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={5} className="my-5">
              <Card
                number={cardNumber}
                expiry={expDate}
                cvc={cvv}
                name={""}
                focused={focused}
                className="card"
              />
              <div className="action-buttons">
                <Button type="submit" className="button button-secondary">
                  Update Information
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </SettingsLayout>
  );
};

export default withErrorHandling(BillingEdit);
