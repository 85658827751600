import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import Smartlook from 'smartlook-client';

import withErrorHandling from '../../../../components/withErrorHandling';
import SettingsLayout from '../layout';
import {
	BarChart,
	SplineChart,
	SmallBarChart,
} from '../../../../components/icons';
import { numberWithCommas } from '../../../../plugins';
import './index.scss';
import { phoneMask, formatPhoneNumber } from '../../../../utils/numberMasks';
import { useEcodrive } from '../../../../context/store';
import useFetch from '../../../../hooks/useFetch';
import { ecodriveApi } from '../../../../libs/axios';

const updateUserSchema = Yup.object().shape({
	firstName: Yup.string()
		.matches(/^[a-zA-Z\s]*[aeiouAEIOU]+[a-zA-Z\s]*$/, 'Invalid First Name')
		.required('First Name is required'),
	lastName: Yup.string()
		.matches(/^[a-zA-Z\s]*[aeiouAEIOU]+[a-zA-Z\s]*$/, 'Invalid Last Name')
		.required('Last Name is required'),
	phone: Yup.string()
		.matches(
			/^[+\d()\s-]*$/,
			'Phone number can only contain digits, hyphens (-), plus sign (+), and parentheses.',
		)
		.test('phone', 'Invalid Phone', (value) => {
			const cleanedPhone = value.replace(/\s/g, '').replace(/-/g, '');
			if (
				cleanedPhone.length >= 11 &&
				cleanedPhone.length <= 15 &&
				(cleanedPhone.match(/\+/g) || []).length <= 1
			) {
				if (cleanedPhone.includes('(') && cleanedPhone.includes(')')) {
					const openingIndex = cleanedPhone.indexOf('(');
					const closingIndex = cleanedPhone.indexOf(')');
					if (closingIndex - openingIndex >= 1) {
						return true;
					}
				} else if (cleanedPhone.includes('(') || cleanedPhone.includes(')')) {
					return false;
				}

				return true;
			}

			return false;
		})
		.required('Phone is required'),
	email: Yup.string()
		.matches(
			/^[^\s@🙂-🙏]+@[^\s@🙂-\u{1F64F}]+\.[^\s@🙂-\u{1F64F}]+$/u,
			'Invalid Email',
		)
		.required('Email is required'),
});

const Profile = () => {
	const [treesPlanted, setTreesPlanted] = useState(0);
	const [{ user, business }] = useEcodrive();

	const {
		values,
		handleChange,
		handleBlur,
		handleSubmit,
		errors,
		touched,
		isSubmitting,
	} = useFormik({
		initialValues: {
			firstName: user.profile?.firstName,
			lastName: user.profile?.lastName,
			phone: user.profile?.phone,
			email: user.profile?.email,
		},
		validationSchema: updateUserSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				ecodriveApi
					.put('/users/me', values)
					.then(() => {
						toast.success(`Content Updated!`);
					})
					.catch((err) => {
						setSubmitting(false);
						toast.error(`Error ` + err);
					});
			} catch (error) {
				toast.error(error.response.data.message);
			} finally {
				setSubmitting(false);
			}
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		if ('id' in user.profile) {
			Smartlook.identify(user.profile.id);
		}
	}, [user.profile]);

	const { data: transactions } = useFetch(
		business.id && `/transactions/${business.id}/business`,
	);

	useEffect(() => {
		if (transactions) {
			setTreesPlanted(
				transactions.reduce((acc, curr) => acc + parseInt(curr.treesOrdered), 0),
			);
		}
	}, [setTreesPlanted, transactions]);

	/*const handleSubmit = (event) => {
		event.preventDefault();
		updateContent();
	};*/

	return (
		<SettingsLayout title='Profile'>
			<div className='profile-container'>
				<Row>
					<Col md={7} sm={12}>
						<h1>Your Profile</h1>
						<h6>Edit all of your personal information here.</h6>
						<Form onSubmit={handleSubmit}>
							<Row>
								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label' htmlFor='firstName'>
										First Name
									</Form.Label>
									<Form.Control
										type='text'
										name='firstName'
										value={values.firstName}
										onChange={handleChange}
										className='profile-item-input'
										id='firstName'
									/>
									{touched.firstName && errors.firstName && (
										<div className='error-message'>{errors.firstName}</div>
									)}
								</Col>

								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label' htmlFor='lastName'>
										Last Name
									</Form.Label>
									<Form.Control
										type='text'
										name='lastName'
										value={values.lastName}
										onChange={handleChange}
										className='profile-item-input'
										id='lastName'
									/>
									{touched.lastName && errors.lastName && (
										<div className='error-message'>{errors.lastName}</div>
									)}
								</Col>
							</Row>
							<Row>
								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>Email</Form.Label>
									<Form.Control
										type='text'
										name='email'
										disabled={true}
										value={values.email}
										onChange={handleChange}
										className='profile-item-input'
									/>
									{touched.email && errors.email && (
										<div className='error-message'>{errors.email}</div>
									)}
								</Col>
								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label' htmlFor='phone'>
										Phone
									</Form.Label>
									<MaskedInput
										id='phone'
										guide={false}
										mask={phoneMask}
										value={values.phone}
										className='profile-item-input'
										onChange={(event) => {
											const formattedPhone = formatPhoneNumber(event.target.value);
											handleChange({
												target: {
													name: 'phone',
													value: formattedPhone,
												},
											});
										}}
										onBlur={handleBlur}
										placeholder='Your Phone'
										render={(ref, props) => (
											<>
												<Form.Control ref={ref} required {...props} />
												{touched.phone && errors.phone && (
													<div className='error-message'>{errors.phone}</div>
												)}
											</>
										)}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm={12}>
									<Button
										type='submit'
										className='button-submit full-width my-3'
										disabled={isSubmitting}
									>
										Save Changes
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
					<Col md={1}></Col>
					<Col md={4} sm={12}>
						<h1 className='statistic-label text-center mb-3'>Your Tree Statistics</h1>
						<div className='statistic-item'>
							<Row>
								<Col xs={6} sm={6} md={6}>
									<p className='statistic-item-label'>Lifetime Lbs of CO2 Removed</p>
									<p className='statistic-item-value'>
										{numberWithCommas(treesPlanted * 680)}
									</p>
								</Col>
								<Col xs={6} sm={6} md={6} className=''>
									<div className='statistic-item-chart'>
										<BarChart />
									</div>
								</Col>
							</Row>
						</div>
						<div className='statistic-item'>
							<Row>
								<Col xs={6} sm={6} md={6}>
									<p className='statistic-item-label'>Lifetime Trees Planted</p>
									<p className='statistic-item-value'>
										{numberWithCommas(treesPlanted)}
									</p>
								</Col>
								<Col xs={6} sm={6} md={6}>
									<div className='statistic-item-chart'>
										<SplineChart />
									</div>
								</Col>
							</Row>
						</div>
						<div className='statistic-item'>
							<Row>
								<Col xs={3} sm={2} md={2} className='d-flex align-item-center'>
									<div className='statistic-item-chart'>
										<SmallBarChart />
									</div>
								</Col>
								<Col xs={9} sm={10} md={10}>
									<p className='statistic-item-label'>Work Days Created</p>
									<p className='statistic-item-value'>
										{numberWithCommas(treesPlanted / 100)}
									</p>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</div>
		</SettingsLayout>
	);
};

export default withErrorHandling(Profile);
