import { Form } from 'react-bootstrap';
import countryList from "./countrylist";

const CountrySelect = ({ country, setCountry }) => {

    const countryOptions = countryList.map((item, index) => {
        return (
            <option
                value={item.code}
                key={item.code}
            >
                {item.name}
            </option>
        );
    });

    return (
        <Form.Select
            defaultValue={country}
            onChange={(event) => setCountry(event.target.value)}
            required
        >
            {countryOptions}
        </Form.Select>
    )
  }
  
  export default CountrySelect;