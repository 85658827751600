import React, { useEffect, useState } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import { useBetween } from 'use-between';
import useClient from '../../../pages/client/useClient';
import './NPSBreakdown.scss';

const NPSBreakdown = () => {
	const [promoters, setPromoters] = useState(0);
	const [passives, setPassives] = useState(0);
	const [detractors, setDetractors] = useState(0);
	const { getNPSData } = useBetween(useClient);

	useEffect(() => {
		const fetchData = async () => {
			let data = await getNPSData();
			data = data.filter((item) => item.completed);
			const totalCount = data.length;
			let promotersCount = 0;
			let passivesCount = 0;
			let detractorsCount = 0;

			data.forEach((item) => {
				if (item.score >= 9) {
					promotersCount += 1;
				} else if (item.score >= 7) {
					passivesCount += 1;
				} else {
					detractorsCount += 1;
				}
			});

			setPromoters((promotersCount / totalCount) * 100);
			setPassives((passivesCount / totalCount) * 100);
			setDetractors((detractorsCount / totalCount) * 100);
		};

		fetchData();
	}, [getNPSData]);

	return (
		<Card>
			<Card.Header>
				<h2 className='mt-2'>Breakdown</h2>
			</Card.Header>
			<Card.Body>
				<h4 className='nps-breakdown-heading'>Promoters</h4>
				<ProgressBar
					className='nps-progress-bar'
					now={promoters}
					label={`${promoters.toFixed(2)}%`}
				/>
				<h4 className='nps-breakdown-heading'>Passives</h4>
				<ProgressBar
					className='nps-progress-bar'
					now={passives}
					label={`${passives.toFixed(2)}%`}
				/>
				<h4 className='nps-breakdown-heading'>Detractors</h4>
				<ProgressBar
					className='nps-progress-bar'
					now={detractors}
					label={`${detractors.toFixed(2)}%`}
				/>
			</Card.Body>
		</Card>
	);
};

export default NPSBreakdown;
