import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useBetween } from 'use-between';
import './NPSComments.scss';
import useClient from '../../../pages/client/useClient';

const NPSComments = () => {
	const [npsData, setNpsData] = useState([]);
	const [filter, setFilter] = useState({
		all: true,
		promoters: false,
		passives: false,
		detractors: false,
	});
	const { getNPSData } = useBetween(useClient);

	useEffect(() => {
		const fetchData = async () => {
			let data = await getNPSData();
			data = data.filter((item) => item.completed);
			setNpsData(data);
		};
		fetchData();
	}, [getNPSData]);

	const handleFilterClick = (category) => {
		if (category === 'all') {
			setFilter({
				all: true,
				promoters: false,
				passives: false,
				detractors: false,
			});
		} else {
			const updatedFilter = {
				...filter,
				all: false,
				[category]: !filter[category],
			};
			if (
				!updatedFilter.promoters &&
				!updatedFilter.passives &&
				!updatedFilter.detractors
			) {
				updatedFilter.all = true;
			}
			setFilter(updatedFilter);
		}
	};

	const filterData = npsData.filter(
		(comment) =>
			filter.all ||
			(filter.promoters && comment.score >= 9) ||
			(filter.passives && comment.score >= 7 && comment.score <= 8) ||
			(filter.detractors && comment.score < 7),
	);

	return (
		<div>
			<h2 className='mb-3'>Scores</h2>
			<div className='filter-buttons'>
				<button
					className={`btn mx-1 ${
						filter.all ? 'selected-filter' : 'not-selected-filter'
					}`}
					onClick={() => handleFilterClick('all')}
				>
					All
				</button>
				<button
					className={`btn mx-1 ${
						filter.promoters ? 'selected-filter' : 'not-selected-filter'
					}`}
					onClick={() => handleFilterClick('promoters')}
				>
					Promoters
				</button>
				<button
					className={`btn mx-1 ${
						filter.passives ? 'selected-filter' : 'not-selected-filter'
					}`}
					onClick={() => handleFilterClick('passives')}
				>
					Passives
				</button>
				<button
					className={`btn mx-1 ${
						filter.detractors ? 'selected-filter' : 'not-selected-filter'
					}`}
					onClick={() => handleFilterClick('detractors')}
				>
					Detractors
				</button>
			</div>

			<Table striped bordered hover className='mt-3'>
				<thead>
					<tr>
						<th>Id</th>
						<th>Score</th>
						<th>Category</th>
						<th>Comments</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{filterData.map((comment, index) => (
						<tr key={index}>
							<td>{comment.business_id}</td>
							<td>{comment.score}</td>
							<td>
								{comment.score >= 9
									? 'Promoter'
									: comment.score >= 7
									? 'Passive'
									: 'Detractor'}
							</td>
							<td>{comment.feedback}</td>
							<td>{new Date(comment.last_prompt_date).toLocaleDateString()}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default NPSComments;
