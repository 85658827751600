/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useContext, useMemo } from 'react';
import {
	Container,
	Row,
	Col,
	Button,
	Navbar,
	Nav,
	Tabs,
	Tab,
	Modal,
	Badge,
} from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useBetween } from 'use-between';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import { exportComponentAsPNG } from 'react-component-export-image';
import { toast } from 'react-toastify';

import './index.scss';
import {
	Logo,
	Leap,
	Tree,
	Tree1,
	Ecodrive,
	Brand,
	Download,
	Carbon,
	Shopify,
	HelpArrow,
	Instagram,
	Pinterest,
	WebsiteIcon,
	GreenLeaf,
	Linkedin,
	Calendar1,
	Logout,
	Setting,
	Truck,
} from '../../../../components/icons';
import Background from '../../../../assets/images/dashboard/background_1.png';
import Cards from '../../../../assets/images/dashboard/cards.png';
import LeapImg from '../../../../assets/images/dashboard/leap.png';
import Help from '../../../../assets/images/dashboard/help.png';
import layer from '../../../../assets/images/dashboard/layer.png';
import Marketing from '../../../../assets/images/dashboard/marketing.png';
import useClient from '../../useClient';
import welcome from '../../../../assets/images/dashboard/welcome.png';
import QR from '../../../../assets/images/dashboard/QR.png';
import Website from '../../../../assets/pdf/Website.pdf';
import Email from '../../../../assets/pdf/Email.pdf';
import Paid from '../../../../assets/pdf/Paid.pdf';
import Social from '../../../../assets/pdf/Social.pdf';
import Blog from '../../../../assets/pdf/Blog.pdf';
import Packaging from '../../../../assets/pdf/Packaging.pdf';
import Message from '../../../../assets/pdf/Message.pdf';
import { numberWithCommas } from '../../../../plugins';
import GenerateSVG from '../../../../components/GenerateSVG';
import Trees from '../../../../assets/images/dashboard/trees.png';
import ReachGoal from '../../../../components/reachGoal';
import { Ecodrive as EcodriveCtx } from '../../../../context/store';
import useFetch from '../../../../hooks/useFetch';
import { useLogout } from '../../../../hooks/useAuth';
import NPSFeedback from '../../../../components/NPS/Client/NPSFeedback';
import { ClientLayout } from '../../../../lauyouts';
import ProjectContent from './projectContent';

const ClientDashboard = () => {
	const navigate = useNavigate();
	const logout = useLogout();

	const { firstLoad, setFirst, putIntegration, getBusiness } =
		useBetween(useClient);

	const [{ business }] = useContext(EcodriveCtx);
	const isSubscriptionActive =
		'subscription' in business ? business.subscription?.active : true;

	const { data: integrations } = useFetch(
		business?.id && `/integrations/${business.id}/business`,
	);
	const shopifyIntegration = useMemo(() => {
		if (Array.isArray(integrations?.integrations)) {
			return integrations.integrations.find((el) => el.shopUrl);
		}
	}, [integrations?.integrations]);

	const [treesPerTransaction, setTpT] = useState(1);
	useEffect(() => {
		if (shopifyIntegration?.treesPerTransaction) {
			setTpT(shopifyIntegration.treesPerTransaction);
		}
	}, [shopifyIntegration?.treesPerTransaction]);

	const shopSlug = useMemo(() => {
		if (shopifyIntegration) {
			const [shop] = shopifyIntegration.shopUrl.split('.');
			return shop;
		} else if (business?.id) {
			return business.id;
		}
	}, [shopifyIntegration, business?.id]);

	const { data: projects = [] } = useFetch(
		business?.id && `/transactions/${business.id}/business`,
	);

	const { width, height } = useWindowSize();
	const [key, setKey] = useState('website');
	const [confetti] = useState(true);
	const [businessInfo, setBusinessInfo] = useState();

	const badge1 = useRef();
	const badge2 = useRef();
	const badge3 = useRef();
	const brandAssets = useRef();
	const treeContent = useRef();
	const insertCards = useRef();
	const strageryChecklist = useRef();
	const marketplace = useRef();
	const contractSpecial = useRef();
	const shopifyApp = useRef();

	const handleModalClose = () => setFirst(false);

	const plus = () => {
		const formData = new FormData();
		formData.append('treesPerTransaction', treesPerTransaction + 1);
		putIntegration(shopifyIntegration.id, formData).then(({ data }) => {
			setTpT(parseInt(data.treesPerTransaction));
			toast.success(
				`Update successful! Trees Per Order set to ${treesPerTransaction + 1}`,
			);
		});
	};

	const minus = () => {
		if (treesPerTransaction <= 1) {
			toast.error('value must be greater than 0');
			return;
		}
		const formData = new FormData();
		formData.append('treesPerTransaction', treesPerTransaction - 1);
		putIntegration(shopifyIntegration.id, formData).then(({ data }) => {
			setTpT(parseInt(data.treesPerTransaction));
			toast.success(
				`Update successful! Trees Per Order set to ${treesPerTransaction - 1}`,
			);
		});
	};

	const folderLink = {
		CA: 'https://drive.google.com/drive/folders/1qwqQW2KY-5P2btPMNT7LZRRPBuBjClQy',
		MAD: 'https://drive.google.com/drive/folders/1z3xuHIIE9-KIIGVNL-l4qxhkJoHQgBVw',
		OR: 'https://drive.google.com/drive/folders/150URo_0mg6sIxOtUc4diF1vd0Ilszd9M',
		HI: 'https://drive.google.com/drive/folders/1jiB_Du58oP_YHKFaYxVq8qqH_pZTaAIJ',
		KEN: 'https://drive.google.com/drive/folders/1GgBYUF0zqD6JafmzshotHe9cv0y4l6Ib',
		MICH:
			'https://drive.google.com/drive/folders/18tiOMEh6e_OlyuUPq8gDVVfm62d4eLUd',
		HOND:
			'https://photos.google.com/share/AF1QipOpjwfPsyJ23u68c08GEqH7nRgIaU0lw3fRcjDoSnv29pxjz7htHYYGRV-Zz43iMw?pli=1&key=YTJDVXZHbDVHUkFLaGdBSTFEbmdxOFJNbVVoMm5B',
		MONT:
			'https://drive.google.com/drive/folders/1c5SKhVA_alndgFOYLbKdUUc22nZa-LTx',
		KELP:
			'https://drive.google.com/drive/u/0/folders/1sA1I3Jq4wrFuEvlB32zyoOAM0gzbOpdr',
	};

	// const updateTrees = (trees) => {
	//   const formData = new FormData();
	//   formData.append('user_id', userId);
	//   formData.append('trees_per_order', trees)
	//   formData.append('business_id', business.business_id);

	//   // TODO: sync on updates to where/when we calculate total trees planted
	//   // updateTPO(formData)
	//   //   .then((response) => {
	//   //     toast.success(`Update successful! Trees Per Order set to ${response.trees_per_order}`);
	//   //   })
	// }

	// useEffect(() => {
	// 	getTotalTreesPlanted(business.id);
	// }, [business.id, getTotalTreesPlanted]);

	const treesPlanted = projects.reduce(
		(acc, proj) => acc + parseInt(proj.treesOrdered),
		0,
	);

	return (
		<ClientLayout>
			<div className='client-dashboard'>
				<Modal size='xl' centered show={!isSubscriptionActive}>
					<Modal.Header className='justify-content-center'>
						<Modal.Title>
							<Badge bg='danger'>Subscription Cancelled</Badge>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Your subscription is currently disabled, to keep making use of the
						platofrm please resume your subscription
					</Modal.Body>
					<Modal.Footer>
						<Link to='/settings/billing'>
							<Button variant='success'>Resume Subscription</Button>
						</Link>
					</Modal.Footer>
				</Modal>
				<Modal
					className='first-enter'
					show={firstLoad}
					onHide={handleModalClose}
					centered
				>
					<Modal.Header closeButton>
						<img src={welcome} alt='welcome' />
						<Modal.Title>Welcome!</Modal.Title>
						<p>Your account creation is complete.</p>
						<p>You may close this dialogue and begin using the dashboard in full!</p>
						<Button
							className='enter-dashboard'
							variant='secondary'
							onClick={handleModalClose}
						>
							Enter Dashboard
						</Button>
						<Leap />
					</Modal.Header>
				</Modal>
				{firstLoad && (
					<Confetti
						width={width}
						colors={['#EDE6CC', '#FFFFFF', '#11312B']}
						recycle={confetti}
						height={height}
					/>
				)}
				<div className='dashboard-header'>
					<Navbar expand='lg'>
						<Navbar.Brand href='https://ecodrive.community'>
							<Logo />
						</Navbar.Brand>
						<Navbar.Toggle aria-controls='navbar-nav' />
						<Navbar.Collapse id='navbar-nav' className='justify-content-end'>
							<Nav>
								<Nav.Link
									onClick={() => navigate('/partner-dashboard', { replace: true })}
								>
									<span>Planet Partners</span>
								</Nav.Link>
								<Nav.Link
									onClick={() => navigate('/client-dashboard', { replace: true })}
								>
									<span>Dash</span>
								</Nav.Link>
								<Nav.Link
									className='active'
									onClick={() => navigate('/client-resources', { replace: true })}
								>
									<span>Resources</span>
								</Nav.Link>
								<Nav.Link
									className='public-dash'
									onClick={() => {
										window.open(`/public-dashboard/${shopSlug || business.id}`, '_blank');
									}}
								>
									<span>Public Dash</span>
								</Nav.Link>
								<Nav.Link onClick={() => navigate('/settings', { replace: true })}>
									<span>
										<Setting width='18' height='18' />
									</span>
								</Nav.Link>
								<Nav.Link onClick={() => logout()}>
									<span>
										<Logout width='16' height='16' />
									</span>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</div>
				<NPSFeedback />
				<Container className='dashboard-content'>
					<Row className='content-header'>
						<Col lg={6} md={12}>
							<Leap />
							<h1>Welcome</h1>
							<h2>We are so excited you're here!</h2>
							<p>
								The world just got a little better because of brands like you that are
								dedicated to making a positive impact. This page is designed to help you
								seamlessly incorporate your new initiative into your brand. The more
								business you do, the more trees you plant!
							</p>
						</Col>
						<Col lg={6} md={12}>
							<img src={Background} alt='background' />
						</Col>
						<div className='more-help'>
							<span onClick={() => brandAssets.current.scrollIntoView()}>
								Brand Assets
							</span>
							<span onClick={() => treeContent.current.scrollIntoView()}>
								Tree Content
							</span>
							<span onClick={() => insertCards.current.scrollIntoView()}>
								Insert Cards
							</span>
							<span onClick={() => strageryChecklist.current.scrollIntoView()}>
								Strategy Checklist
							</span>
							<span onClick={() => shopifyApp.current.scrollIntoView()}>
								Shopify App
							</span>
							<span onClick={() => marketplace.current.scrollIntoView()}>
								Marketplace
							</span>
							<span onClick={() => contractSpecial.current.scrollIntoView()}>
								Contact Specialists
							</span>
							<a
								href='mailto:trevor@ecodrive.community?cc=blake@ecodrive.community&subject=Audit%20My%20Business%20-%20Ecodrive%20Dashbaord'
								target='_blank'
								rel='noreferrer'
							>
								Supply chain audits
							</a>
						</div>
					</Row>

					{shopifyIntegration ? (
						<Row className='trees-per-order'>
							<Col lg={6} md={12}>
								<h2 className='d-md-block d-lg-none'>Trees per order</h2>
								<div className='order-container'>
									<div className='order-header'>
										<span>Trees per order?</span>
										<span className='order-info' />
									</div>
									<div className='order-content'>
										<label>How many trees would you like to plant?</label>
										<div className='order-counts'>
											<Button disabled={treesPerTransaction === 1} onClick={() => minus()}>
												-
											</Button>
											<span>{treesPerTransaction}</span>
											<Button onClick={() => plus()}>+</Button>
										</div>
										<Leap />
										<Leap />
									</div>
								</div>
							</Col>
							<Col lg={6} md={12}>
								<h2 className='d-none d-lg-block'>Trees per order</h2>
								<p>
									If you want to ensure you are going climate positive on orders (product
									lifecycle, shipping, etc.) you can talk to our specialists as well.
								</p>
							</Col>
						</Row>
					) : null}

					<Row className='brand-assets' ref={brandAssets}>
						<Col lg={6} md={12}>
							<h1 className='d-md-block d-lg-none'>
								Ecodrive <br />
								Brand Assets
							</h1>
							<Row>
								<Col sm={6} xs={12} className='asset-item'>
									<a
										href='https://drive.google.com/drive/folders/1lyfoPc1Qz6wRbVsmU6oK--FhCfmVfzeb'
										target='_blank'
										rel='noreferrer'
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Tree className='rotate-45' width='40px' fill='white' />
											<Ecodrive />
											<span>Logos</span>
										</div>
									</a>
								</Col>
								<Col sm={6} xs={12} className='asset-item'>
									<a
										href='https://drive.google.com/drive/folders/1W65Eg3M15G7RRUpeW1kIR1ib_q8EK7vd'
										target='_blank'
										rel='noreferrer'
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Tree1 />
											<span>Icons</span>
										</div>
									</a>
								</Col>
								<Col sm={6} xs={12} className='asset-item'>
									<a
										href='https://drive.google.com/drive/folders/1W2-JGqNoD8vSyoLH2UY8-9XD472u2qEQ'
										target='_blank'
										rel='noreferrer'
									>
										<div className='qr d-flex flex-column align-items-center justify-content-center'>
											<img src={QR} alt='QR' />
											<span>QR codes</span>
										</div>
									</a>
								</Col>
								<Col sm={6} xs={12} className='asset-item'>
									<a
										href='https://drive.google.com/drive/folders/1N411tvst03tCPi4G14anqbH1batFVrEZ'
										target='_blank'
										rel='noreferrer'
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<div className='d-flex align-items-center justify-content-center'>
												<Tree className='rotate-45' width='40px' fill='white' />
												<Ecodrive />
											</div>
											<span className='mt-0'>Seals</span>
										</div>
									</a>
								</Col>
							</Row>
						</Col>
						<Col lg={6} md={12} className='position-relative'>
							<h1 className='d-none d-lg-block'>
								Ecodrive <br />
								Brand Assets
							</h1>
							<p>
								Logos, seals, icons and QR codes available for your use across all
								channels to help message your intiative with us!
							</p>
							<Button
								variant='link'
								href='https://drive.google.com/drive/folders/1vKbzWgTfTAlc_dYrJbS3qNKSOcAGMsH7'
								target='_blank'
								className='dashboard-button'
							>
								DOWNLOAD ALL BRAND ASSETS
							</Button>
						</Col>
						<Leap />
					</Row>

					<Row className='tree-content' ref={treeContent}>
						<Col className='d-flex flex-column align-items-center justify-content-center'>
							<img src={Trees} alt='trees' />
							<Button
								variant='link'
								href={
									folderLink[businessInfo?.project] ||
									'https://drive.google.com/drive/folders/1DTklmi6x-Gauc16oa27UWwMLCi9qQw63'
								}
								target='_blank'
								className='dashboard-button'
							>
								Download Ecodrive Tree Content
							</Button>
						</Col>
					</Row>

					<div ref={treeContent}>
						<ProjectContent projects={projects} />
					</div>

					<Row className='ecodrive-badge trophy-case'>
						<Col xs={12}>
							<h2>Get Your Ecodrive Badge</h2>
						</Col>
						<div className='trophy-content'>
							<div className='trophy-choices'>
								<div className='badge-container' ref={badge1}>
									<div className='badge-item badge1'>
										<div className='brand'>
											<Brand width='61px' height='91px' />
										</div>
										<div className='trees-planted'>
											<p>{numberWithCommas(treesPlanted)}</p>
											<label>Trees Planted</label>
											<p>{numberWithCommas(treesPlanted / 100)}</p>
											<label>Work days Created</label>
										</div>
										<div
											className='download'
											onClick={() => {
												exportComponentAsPNG(badge1, {
													fileName: 'Badge.png',
													html2CanvasOptions: { backgroundColor: null },
												});
											}}
										>
											<Download />
										</div>
									</div>
								</div>
								<div className='badge-container' ref={badge2}>
									<div className='badge-item badge2'>
										<p>
											We're a carbon <span>positive</span> business.
										</p>
										<div className='brand'>
											<Brand width='12px' height='18px' />
										</div>
										<div className='leaf'>
											<Carbon width='115px' height='96px' />
											<div className='trees'>
												<p>{numberWithCommas(treesPlanted)}</p>
												<label>Trees Planted</label>
											</div>
										</div>
										<div
											className='download'
											onClick={() => {
												exportComponentAsPNG(badge2, {
													fileName: 'Badge.png',
													html2CanvasOptions: { backgroundColor: null },
												});
											}}
										>
											<Download />
										</div>
									</div>
								</div>
								<div className='badge-container' ref={badge3}>
									<div className='badge-item badge3'>
										<img src={layer} alt='layer' />
										<div className='business'>
											<div className='leaf'>
												<GreenLeaf width='78px' height='78px' />
											</div>
											<div className='climate'>
												<h3>
													Climate Positive
													<br />
													Business with Ecodrive
												</h3>
											</div>
										</div>
										<div className='logo'>
											<Logo width='128px' height='26px' />
										</div>
										<div
											className='download'
											onClick={() => {
												exportComponentAsPNG(badge3, {
													fileName: 'Badge.png',
													html2CanvasOptions: { backgroundColor: null },
												});
											}}
										>
											<Download />
										</div>
									</div>
								</div>
							</div>
						</div>

						<Col xs={12} className='mt-4'>
							<h2>Customize Your Ecodrive Badge</h2>
						</Col>
						<Col xs={12}>
							<GenerateSVG businessId={business.id} />
						</Col>
					</Row>

					<ReachGoal />
					<Row className='insert-cards' ref={insertCards}>
						<Col lg={5} md={12}>
							<img src={Cards} alt='cards' />
						</Col>
						<Col lg={7} md={12}>
							<h2>Insert Cards</h2>
							<ul>
								<li>
									Increases your customers experience by allowing them to learn more
									about the trees when they receive their order from you
								</li>
								<li>“shareable” people want to show that they are doing their part</li>
							</ul>

							<h4>Description:</h4>
							<ul>
								<li>Seal represents a certificate of tree planting from Ecodrive</li>
								<li>
									QR code that your business can scan to see photos of the trees, the
									land, and learn more about their impact
								</li>
								<li>Printed on post 100% recycled/recyclable paper</li>
								<li>3X5 - can easily fit in any package without being overwhelming</li>
							</ul>
							<Button
								variant='link'
								href='mailto:blake@ecodrive.community?cc=trevor@ecodrive.community&subject=Insert%20Cards%20-%20Ecodrive%20Dash'
								className='dashboard-button'
								target='_blank'
							>
								Get A Quote
							</Button>
						</Col>
					</Row>

					<Row className='more-trees'>
						<Col>
							<div className='trees-content'>
								<div className='content-detail'>
									<Calendar1 />
									<div>
										<h2 className='holiday-calendar'>Holiday Marketing Calendar</h2>
										<p>
											Check out the holiday calendar to see all kinds of fun eco-friendly
											holidays that you can come up with creative campaigns for throughout
											the year.
											<br />
											The biggest of which is Earth Month/Day in April where you can
											feature planting trees at the forefront of your brand.
										</p>
									</div>
								</div>
								<Button
									variant='link'
									href='https://www.canva.com/design/DAEgcgfEoMg/SNubZOlmyudCi5J0gRtJdA/view?utm_content=DAEgcgfEoMg&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton#1'
									target='_blank'
								>
									GO TO HOLIDAY CALENDAR
								</Button>
							</div>
						</Col>
					</Row>

					<Row
						className='marketing-strategy position-relative'
						ref={strageryChecklist}
					>
						<Col sm={12}>
							<h2>
								Your Marketing Strategy <br /> Checklist
							</h2>
							<Tabs
								id='marketing-strategy-tabs'
								activeKey={key}
								onSelect={(k) => setKey(k)}
								className=''
							>
								<Tab eventKey='website' title='Website Optimizations'>
									<p>
										Placing clear callouts that one tree will be planted for every
										purchase along the business’s purchase journey helps increase
										conversion. Brands have seen as much as a 17% increase in conversion
										rate when callouts are added. See full breakdown for placement
										examples. Download Strategy Breakdown
									</p>
									<div className='d-flex'>
										<Button
											variant='link'
											className='dashboard-button'
											download='Ecodrive Website Strategy Sheet.pdf'
											target='_self'
											href={Website}
										>
											Download strategy
										</Button>
									</div>
								</Tab>
								<Tab eventKey='email' title='Email / SMS'>
									<p>
										Communicating via email/SMS is one of the most effective ways to
										implement Ecodrive. Sending out monthly campaigns updating your
										businesses and integrating tree planting into abandoned cart +
										post-purchase flows helps drive revenue and bring people back to your
										site. Download Strategy Breakdown
									</p>
									<div className='d-flex'>
										<Button
											variant='link'
											className='dashboard-button'
											download='Ecodrive Email_SMS Strategy Sheet.pdf'
											target='_self'
											href={Email}
										>
											Download strategy
										</Button>
									</div>
								</Tab>
								<Tab eventKey='media' title='Paid Media'>
									<p>
										Adding tree planting callouts into proven copy or creative for social
										paid media ads has proven to be effective in driving purchases. Simple
										language like “we plant a tree with every purchase deciduous_tree
										emoji” with the tree emoji can lead to top-performing creatives and
										campaigns. Download Strategy Breakdown
									</p>
									<div className='d-flex'>
										<Button
											variant='link'
											className='dashboard-button'
											download='Ecodrive Paid Media Strategy Sheet.pdf'
											target='_self'
											href={Paid}
										>
											Download strategy
										</Button>
									</div>
								</Tab>
								<Tab eventKey='social' title='Organic Social'>
									<p>
										Featuring tree planting across your social channels is a great way to
										mix up your feed and increase engagement. Posting the impact stats of
										how many trees you’ve planted, lbs of CO2 that will be sequestered,
										etc. is easy to post on placements like Instagram stories. Download
										Strategy Breakdown
									</p>
									<div className='d-flex'>
										<Button
											variant='link'
											className='dashboard-button'
											download='Ecodrive Organic Social Strategy Sheet.pdf'
											target='_self'
											href={Social}
										>
											Download strategy
										</Button>
									</div>
								</Tab>
								<Tab eventKey='pr' title='PR + Influencer + Blog'>
									<p>
										Adding an element of tree planting opens up new topics to cover across
										media outlet channels like blogs or PR. Having a story featuring what
										your brand is doing to help the environment helps expand to reach
										sustainable shoppers and tell the story behind your commitment.
										Download Strategy Breakdown
									</p>
									<div className='d-flex'>
										<Button
											variant='link'
											className='dashboard-button'
											download='Ecodrive PR, Blog, Influencer Strategy Sheet.pdf'
											target='_self'
											href={Blog}
										>
											Download strategy
										</Button>
									</div>
								</Tab>
								<Tab eventKey='packaging' title='Packaging'>
									<p>
										Adding insert cards, callouts, or badges to packaging is a unique way
										to communicate what you are doing with your businesses. It increases
										business engagement and retention by calling out that there is a real
										impact made through their purchase in a physical way that’s shareable.
										Download Strategy Breakdown
									</p>
									<div className='d-flex'>
										<Button
											variant='link'
											className='dashboard-button'
											download='Ecodrive Packaging Strategy Sheet.pdf'
											target='_self'
											href={Packaging}
										>
											Download strategy
										</Button>
									</div>
								</Tab>
							</Tabs>
							<Leap />
							<img src={LeapImg} alt='leap' />
						</Col>
					</Row>

					<div className='reach-goal'>
						<h2>Messaging do's and don'ts</h2>
						<div className='mt-4'>
							<Button
								variant='link'
								download="Messaging Do's & Don'ts Quick Tips.pdf"
								href={Message}
								target='_self'
							>
								Download
							</Button>
						</div>
					</div>

					<Row className='more-trees' ref={shopifyApp}>
						<Col>
							<div className='trees-content'>
								<div className='content-detail'>
									<Shopify />
									<div>
										<h2>Interactive tree planting for businesses!</h2>
										<p>
											Download our FREE shopify app that allows businesses to plant a tree
											and make an impact alongside you in their shopping cart at checkout
										</p>
									</div>
								</div>
								<Button
									variant='link'
									href='https://apps.shopify.com/ecodrive'
									target='_blank'
								>
									Install Now
								</Button>
							</div>
						</Col>
					</Row>

					<Row className='marketing-section' ref={marketplace}>
						<h2>Showcase Your Brand on Our Exclusive Marketplace</h2>
						<Col className='marketing-container'>
							<div className='marketing-wrapper'>
								<h4>An Earth-First and Tree Focused Shoppable Marketplace</h4>
								<p className='mb-5'>
									Exclusive to Ecodrive clients on SHOPIFY that plant trees and protect
									our planet to showcase your initiatve to sustainable consumers!
								</p>

								<Row className='bullet-container'>
									<Col lg={6} className='bullet-wrapper'>
										<div className='marketing-img mb-5'>
											<img src={Marketing} alt='Marketing' />
										</div>
										<div className='btn-container justify-content-end'>
											<Button
												variant='link'
												href='https://ecosystem.market/'
												target='_blank'
											>
												EXPLORE SITE
											</Button>
										</div>
									</Col>
									<Col lg={6} className='bullet-wrapper'>
										<div className='bullet-group'>
											<div className='mb-4'>
												<div className='bullet-item'>
													<span>1</span>
													<p className='h6'>
														Seamlessly integrates into your Shopify as a sales channel. Free
														to list and you keep 70% of sale!
													</p>
												</div>
												<div className='bullet-item'>
													<span>2</span>
													<p className='h6'>
														When your products are added to the Ecosystem and a sale is made,
														the order will appear in your Shopify Orders for fulfillment
													</p>
												</div>
												<div className='bullet-item'>
													<span>3</span>
													<p className='h6'>
														<strong>Bonus:</strong> We give you the business email address!
													</p>
												</div>
											</div>
											<h5 className='text-left'>Have a sustainable product?</h5>
										</div>
										<div className='btn-container justify-content-start'>
											<Button
												variant='link'
												className='btn-join'
												href='mailto:partners@ecodrive.commnuity?subject=Eco-friendly%20Marketplace&body=We%20are%20interested%20in%20listing%20our%20Shopify%20products%20on%20your%20eco-friendly%20marketplace!'
												target='_blank'
											>
												GET IN TOUCH
											</Button>
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>

					<Row className='need-help' ref={contractSpecial}>
						<Col>
							<div className='help-header'>
								<img src={Help} alt='help' />
								<div className='overlay' />
							</div>
							<div className='help-content'>
								<div>
									<h2>Need Help?</h2>
									<p>Talk to an Ecodrive brand sustainability marketing specialists!</p>
								</div>
								<HelpArrow />
								<Button
									variant='link'
									href='mailto:trevor@ecodrive.community?cc=blake@ecodrive.community&subject=Help%20-%20Ecodrive%20Dash%20Contact'
								>
									Click here to Chat
								</Button>
							</div>
						</Col>
					</Row>

					<Row className='more-trees supply-chain'>
						<Col>
							<div className='trees-content'>
								<div className='content-detail'>
									<Truck />
									<div>
										<h2 className='holiday-calendar'>
											Supply Chain CO<sub>2</sub> Audits
										</h2>
										<p>
											Audit and reduce your supply chain and retail distribution efforts
											with Ecodrive's emissions experts to help boost B2B relationships,
											open more retail doors and do more for your planet with us! We use an
											EPA backed formula that takes into account your total weights of
											packages (pallets) shipped, mode of transport and miles traveled to
											determine your CO2 footprint from transportation. Audit fees start at
											$1,000 annually.
										</p>
									</div>
								</div>
								<Button
									variant='link'
									href='mailto:trevor@ecodrive.community?cc=blake@ecodrive.community&subject=Audit%20My%20Business%20-%20Ecodrive%20Dashbaord'
									target='_blank'
								>
									GET A QUOTE
								</Button>
							</div>
						</Col>
					</Row>
				</Container>

				<Container fluid className='dashboard-footer'>
					<Container>
						<Row>
							<Col md={4} sm={12}>
								<Logo width='131px' fill='white' />
								<div className='social-wrapper'>
									<Button
										variant='link'
										className='social-item'
										target='_blank'
										href='https://www.instagram.com/ecodrive/'
									>
										<Instagram />
									</Button>
									<Button
										variant='link'
										className='social-item'
										target='_blank'
										href='https://www.linkedin.com/company/ecodriveventures/'
									>
										<Linkedin />
									</Button>
									<Button
										variant='link'
										className='social-item'
										target='_blank'
										href='https://www.pinterest.com/ecodrivecommunity/'
									>
										<Pinterest />
									</Button>
									<Button
										variant='link'
										className='social-item'
										target='_blank'
										href='https://ecodrive.community/'
									>
										<WebsiteIcon />
									</Button>
								</div>
								<p>Need help?</p>
								<Button
									variant='link'
									target='_blank'
									href='mailto:support@ecodrive.community'
								>
									support@ecodrive.community
								</Button>
							</Col>
							<Col md={4} sm={12} className='d-flex flex-column align-items-start'>
								<Link className='btn-link' to={'/client-resources'}>
									Home
								</Link>
								<Button
									variant='link'
									target='_blank'
									href='https://blog.ecodrive.community'
								>
									Blog
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://brands.ecodrive.community/tree/plant.html'
								>
									More about Trees
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://brands.ecodrive.community/contact'
								>
									Join Ecodrive
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://apps.shopify.com/ecodrive'
								>
									Install the app
								</Button>
							</Col>
							<Col md={4} sm={12} className='d-flex flex-column align-items-start'>
								<Link className='btn-link' to={'/settings'}>
									Settings
								</Link>
								<Link className='btn-link' to={'/partner-dashboard'}>
									Planet partners
								</Link>
								<Link className='btn-link' to={'/client-dashboard'}>
									Internal Dash
								</Link>
								<Button
									variant='link'
									href='https://ecodrive.community/policies/privacy-policy'
									target='_blank'
								>
									Privacy policy
								</Button>
								<Button
									variant='link'
									href='https://ecodrive.community/policies/terms-of-service'
									target='_blank'
								>
									Terms of Use
								</Button>
							</Col>
						</Row>
					</Container>
				</Container>

				<Container>
					<Row className='copyright'>
						<Col className='d-flex align-items-center justify-content-between'>
							<span>All rights reserved 2022</span>
							<div>
								<Button
									variant='link'
									target='_blank'
									href='https://donors.edenprojects.org/user/ecodrive/'
								>
									Proof of planting
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://ecodrive.community/policies/terms-of-service'
								>
									Terms & Conditions
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://ecodrive.community/policies/privacy-policy'
								>
									Privacy Policy
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</ClientLayout>
	);
};

export default ClientDashboard;
