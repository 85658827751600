import { useState, useEffect } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ecodriveApi } from '../../libs/axios';
import { useGetMe } from '../useGetMe';

interface UseConnectShopifyProps {
	onConnected: () => void;
}

export const useConnectShopify = (props: UseConnectShopifyProps) => {
	const { onConnected } = props;
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [connecting, setConnecting] = useState(false);
	const { me, isLoading } = useGetMe();

	useEffect(() => {
		const handleConnect = async (shop: string, code: string) => {
			setConnecting(true);
			setSearchParams((params) => {
				return new URLSearchParams();
			});

			try {
				if (!me) throw new Error('There was an error loading the user profile');

				const { id: userId, businessId } = me;

				await ecodriveApi.get(
					`/connect?code=${code}&shop=${shop}&userId=${userId}&businessId=${businessId}&integration=shopify`,
					{
						headers: {
							authorization: 'Bearer ' + localStorage.getItem('authorization'),
						},
					},
				);

				toast.success('Shopify Integration has been updated');
				onConnected();
			} catch (e) {
				console.error(e);
				toast.error(
					'There was an error connecting your shopify account. Please try again.',
				);
			} finally {
				setConnecting(false);
			}
		};

		if (location.search && !isLoading) {
			const code = searchParams.get('code');
			const shop = searchParams.get('shop');

			if (code && shop) {
				handleConnect(shop, code);
			}
		}
	}, [searchParams, location, isLoading, me, onConnected, setSearchParams]);

	return {
		connecting,
	};
};
