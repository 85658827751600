/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { Container, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useBetween } from 'use-between';
import useAuth from '../../../../auth/useAuth';

import { disallowWhitespace } from '../../../../../plugins';

import './index.scss';
import { Shopify, Magento, Api, Tree } from '../../../../../components/icons';
import { ecodriveApi } from '../../../../../libs/axios';
import { Ecodrive } from '../../../../../context/store';

const Connect = ({ data, route, prev, next }) => {
	const [url, setUrl] = useState('');
	const [connecting, setConnecting] = useState(false);
	const { setIntegrationId } = useBetween(useAuth);
	const [{ user, business }] = useContext(Ecodrive);

	useEffect(() => {
		if (Object.keys(data).length) {
			handleConnect();
		}
	}, [data]);

	const goBack = () => {
		prev();
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		window.location.replace(
			`https://${url}.myshopify.com/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_KEY}&scope=${process.env.REACT_APP_SHOPIFY_SCOPE}&redirect_uri=${window.location.origin}/setup`,
		);
	};

	const handleConnect = async () => {
		setConnecting(true);

		await ecodriveApi
			.get(
				`/connect?code=${data.code}&shop=${data.shop}&userId=${user.profile?.id}&businessId=${business.id}&integration=${route}`,
				{
					headers: {
						authorization: 'Bearer ' + localStorage.getItem('authorization'),
					},
				},
			)
			.then((response) => {
				setIntegrationId(response.integration_id);
				next();
			})
			.catch((error) => {
				console.log(error);
			});

		setConnecting(false);
	};

	return (
		<Container fluid className='setup-info connect-info'>
			{!connecting && (
				<>
					<div className='content-header'>
						{route === 'shopify' && <Shopify />}
						{route === 'magento' && <Magento />}
						{route === 'api' && <Api />}
						<span>Connect {route}</span>
					</div>
					<p>Please enter your {route} URL</p>

					<Form onSubmit={(event) => handleSubmit(event)}>
						<Row>
							<Col>
								<Form.Group className='mb-3'>
									<Form.Label>{route} URL</Form.Label>
									<InputGroup className='mb-3'>
										<InputGroup.Text>https://</InputGroup.Text>
										<Form.Control
											value={url}
											onChange={(event) => setUrl(disallowWhitespace(event.target.value))}
											placeholder='store-name'
											required
										/>
										<InputGroup.Text>.myshopify.com</InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className='action-buttons'>
								<Button
									type='button'
									className='button button-primary'
									onClick={() => goBack()}
								>
									Cancel
								</Button>
								<Button
									type='submit'
									className='button button-secondary'
									disabled={url === ''}
								>
									Connect
								</Button>
							</Col>
						</Row>
						<Row>
							<Col className='need-help'>
								<a
									target='_blank'
									href='mailto:support@ecodrive.community'
									rel='noreferrer'
								>
									Need help?
								</a>
							</Col>
							<Col className='need-help need-help--extra'>
								<a
									style={{ cursor: 'pointer' }}
									onClick={() => {
										next();
									}}
								>
									Skip connecting Shopify
									<br />
									(you can always connect a store later)
								</a>
							</Col>
						</Row>
					</Form>
				</>
			)}

			{connecting && (
				<>
					<div className='content-header'>
						<span>Connecting...</span>
					</div>
					<p>Please do not refresh the page</p>

					<div className='connecting'>
						<div className='connecting-wrapper'>
							<div className='connecting-content'>
								<Tree />
							</div>
						</div>
						<div className='connecting-loader' />
					</div>
				</>
			)}
		</Container>
	);
};

export default Connect;
