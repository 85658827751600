import { useState, useEffect } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { useBetween } from 'use-between';

import './index.scss';
import Upload from '../../../../../components/upload';
import { Tree } from '../../../../../components/icons';
import { toast } from 'react-toastify';
import useAuth from '../../../../auth/useAuth';
import useClient from '../../../../client/useClient';
import { Field, Formik, Form as FForm } from 'formik';
import * as Yup from 'yup';

const Config = ({
	data,
	setData,
	prev,
	finish,
	status,
	setStatus,
	setLogo,
	milestones,
	setMilestones,
	url,
	setUrl,
	logo,
	brandText,
	setBrandText,
	brandSubText,
	setBrandSubText,
}) => {
	const [validated, setValidated] = useState(false);
	const [logoUpload, setLogoUpload] = useState(false);

	const { businessId } = useBetween(useAuth);
	const { business } = useBetween(useClient);

	const goBack = () => {
		setData({
			url,
			milestones,
		});
		prev();
	};

	const submit = (values) => {
		setData({
			url,
			milestones,
		});
		setUrl(values.Website);
		setBrandText(values.main);
		setBrandSubText(values.sub);

		setTimeout(() => {
			if (!logo) {
				toast.error('Please upload the logo');
			} else if (!url) {
				toast.error('Please fill the website url');
			} else if (!validated) {
				toast.error('Please select the three (3) tree planting milestones');
			} else {
				finish();
			}
		});
	};

	useEffect(() => {
		milestones.length === 3 ? setValidated(true) : setValidated(false);
	}, [milestones]);

	return (
		<Container className='setup-info config-info'>
			<Formik
				onSubmit={submit}
				initialValues={{
					Website: 'https://',
					main: 'The Call of The Forest',
					sub: '',
				}}
				validationSchema={Yup.object({
					Website: Yup.string().url().required('Please enter a valid URL'),
					main: Yup.string().required(
						'Please enter a main title for your Public Dashboard',
					),
					sub: Yup.string().required(
						'Pleas enter a subtitle for your Public Dashboard',
					),
				})}
			>
				{() => (
					<FForm>
						{!status && (
							<>
								<div className='content-title mb-5'>
									<span>Upload Your Logo</span>
									<span>.png or .svg</span>
								</div>

								<Upload
									businessId={businessId ? businessId : business.business_id}
									setLogo={setLogo}
									logo={logo}
									setStatus={setStatus}
									setLogoUpload={setLogoUpload}
								/>

								<div className='business-url mt-5 pb-2'>
									<h2>URL for Business Dashboard</h2>
									<span>
										When businesses click on your logo where do you want them to be
										redirected?
									</span>

									<Field name='Website'>
										{({ field, meta }) => (
											<Form.Group className='mb-3'>
												<Form.Control
													placeholder='yourwebsite.com'
													type='text'
													required
													{...field}
												/>
												{meta.touched && meta.error && (
													<Form.Text className='text-danger'>{meta.error}</Form.Text>
												)}
											</Form.Group>
										)}
									</Field>
								</div>

								<div className='mt-5 business-url pb-2'>
									<h2 className='mb-4'>Brand announcement</h2>
									<Field name='main'>
										{({ field, meta }) => (
											<Form.Group className='mb-3 input-limit'>
												<Form.Label>Main message</Form.Label>
												<Form.Control
													// value={brandText}
													// onChange={(event) => setBrandText(event.target.value)}
													maxLength={50}
													required
													{...field}
												/>
												<Form.Label className='limit-label'>
													{field.value.length} / 50
												</Form.Label>
												{meta.touched && meta.error && (
													<Form.Text className='text-danger'>{meta.error}</Form.Text>
												)}
											</Form.Group>
										)}
									</Field>
									<Field name='sub'>
										{({ field, meta }) => (
											<Form.Group className='mb-3 input-limit'>
												<Form.Label>Sub message</Form.Label>
												<Form.Control
													maxLength={100}
													placeholder='Your Brand Announcement'
													as='textarea'
													rows={3}
													required
													{...field}
												/>
												<Form.Label className='limit-label'>
													{field.value.length} / 100
												</Form.Label>
												{meta.touched && meta.error && (
													<Form.Text className='text-danger'>{meta.error}</Form.Text>
												)}
											</Form.Group>
										)}
									</Field>
								</div>

								{/* <Milestones
            data={milestones}
            setData={setMilestones}
            description="Select the three (3) tree planting milestones you want to display on your dashboard."
          /> */}

								<div className='action-buttons'>
									<Button className='button button-primary' onClick={() => goBack()}>
										Back
									</Button>
									<Button
										className='button button-secondary'
										// onClick={() => {
										// 	submit();
										// }}
										type='submit'
									>
										Finish
									</Button>
								</div>
							</>
						)}
						{status && (
							<>
								<div className='content-header'>
									{logoUpload ? <span>Uploading...</span> : <span>Finishing Up...</span>}
								</div>
								<p>Please do not refresh the page</p>

								<div className='connecting'>
									<div className='connecting-wrapper'>
										<div className='connecting-content'>
											<Tree />
										</div>
									</div>
									<div className='connecting-loader' />
								</div>
							</>
						)}
					</FForm>
				)}
			</Formik>
		</Container>
	);
};

export default Config;
