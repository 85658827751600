import { Button, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { Chevron, Home, Logo } from '../icons';

import './index.scss';

const Navbar = ({ title, title2, show, setShow }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='mobile-settings-nav'>
        <Link to='/' className='logo'>
          <Logo width="145" height="30" />
        </Link>
        <Button onClick={(() => {setShow(!show)})} className="btn-toggle">
          <span className="navbar-toggler-icon"></span>
        </Button>
      </div>
      <nav className='settings-nav'>
        <div className='breadcrumb'>
          <Link to="/" className='home'><Home width="20" height="20" /></Link>
          <Chevron width="20" height="20" />
          <Link to="/settings">Settings</Link>
          <Chevron width="20" height="20" />
          {title2 && (
            <>
            <Link to="/settings/billing">Billing</Link>
            <Chevron width="20" height="20" />
            <p>{title2}</p>
            </>
          )}
          {
            !title2 && (
              <p>{title}</p>
            )
          }
          
        </div>
        <Nav className='setting-navbar'>
          <Nav.Link onClick={() => navigate('/client-dashboard', { replace: true })}>
            <span>Dashboard</span>
          </Nav.Link>
          <Nav.Link className='active' onClick={() => navigate('/settings', { replace: true })}>
            <span>Settings</span>
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/client-resources', { replace: true })}>
            <span>Resources</span>
          </Nav.Link>
        </Nav>
      </nav>
    </div>
  )
}

export default Navbar;
