import { useEffect } from 'react';
import { z } from 'zod';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const eventSchema = z.object({
	data: z.object({
		type: z.string().nullish(),
	}),
});

const Header = styled.div({
	background: 'white',
	borderTopLeftRadius: '8px',
	borderTopRightRadius: '8px',
	display: 'flex',
	justifyContent: 'right',
	position: 'relative',
	padding: '4px 8px 4px 0',
});

const Container = styled.div({
	width: '90vw',
	height: '90vh',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
});

interface ZapEditorModalProps {
	readonly url?: string;
	readonly onClose: () => void;
	readonly onEvent: (event: 'zap:unpause:done' | 'zap:pause:done') => void;
}

export const ZapEditorModal = (props: ZapEditorModalProps) => {
	const { url, onClose, onEvent } = props;

	useEffect(() => {
		const handleMessage = (event: unknown) => {
			const parsed = eventSchema.safeParse(event);

			if (parsed.success) {
				if (
					parsed.data.data.type === 'zap:unpause:done' ||
					parsed.data.data.type === 'zap:pause:done'
				) {
					onEvent(parsed.data.data.type);
				}
			}
		};

		if (url) {
			window.addEventListener('message', handleMessage);
		}

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [url, onEvent]);

	return (
		<Modal open={Boolean(url)} onClose={onClose}>
			<Container>
				<Header>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Header>
				<iframe
					title='zap-editor'
					style={{
						width: '100%',
						height: '100%',
					}}
					src={url}
				></iframe>
			</Container>
		</Modal>
	);
};
