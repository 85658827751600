import React from 'react';
import GaugeChart from 'react-gauge-chart';

const NPSGauge = ({ npsScore }) => (
	<GaugeChart
		id='gauge-chart'
		nrOfLevels={25}
		textColor='#000000'
		colors={['#c2c8d1', '#aad8d4', '#77cfc6']}
		arcWidth={0.3}
		percent={(npsScore + 100) / 200}
		hideText
	/>
);

export default NPSGauge;
