import React from 'react';
import './BubbleSelection.scss';

const BubbleSelection = ({ value, setValue }) => {
	const options = Array.from({ length: 11 }, (_, i) => i);

	return (
		<div className='bubble-selection'>
			{options.map((option, index) => (
				<div
					key={index}
					className={`bubble-option ${value === option ? 'selected' : ''}`}
					onClick={() => setValue(option)}
				>
					{option}
				</div>
			))}
		</div>
	);
};

export default BubbleSelection;
