import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const Bold = ({ isActive, onClick }) => (
	<OverlayTrigger overlay={<Tooltip>Ctrl+B</Tooltip>}>
		<Button variant='light' onClick={onClick} active={isActive('BOLD')}>
			<i className='bi bi-type-bold'></i>
		</Button>
	</OverlayTrigger>
);

export default Bold;
