import axios from "axios";
import { env } from "../utils/config";

export const ecodriveApi = axios.create({
  baseURL: env.api.host,
});

ecodriveApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("authorization");
  if (typeof token === "string") {
    config.headers.authorization = "Bearer " + token;
  }

  return config;
});
