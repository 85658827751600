import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useBetween } from 'use-between';
import useClient from '../../../pages/client/useClient';
import { format } from 'date-fns';
import { calculateNPS } from '../calculateNPS';

const NPSTrends = () => {
	const [data, setData] = useState([]);
	const { getNPSData } = useBetween(useClient);

	useEffect(() => {
		const fetchData = async () => {
			let npsData = await getNPSData();
			npsData = npsData.filter((item) => item.completed);

			let totalScore = 0;
			const chartData = npsData.map((item, index) => {
				totalScore += calculateNPS([item]);
				const averageScore = totalScore / (index + 1);

				return {
					score: averageScore,
					date: format(new Date(item.last_prompt_date), 'MM/dd/yyyy'),
				};
			});

			setData(chartData);
		};

		fetchData();
	}, [getNPSData]);

	const series = [
		{
			name: 'NPS Score',
			data: data.map((item) => item.score),
		},
	];

	const options = {
		chart: {
			id: 'basic-bar',
			toolbar: {
				show: false,
			},
		},
		xaxis: {
			categories: data.map((item) => item.date),
		},
		yaxis: {
			min: -100,
			max: 100,
			labels: {
				formatter: (value) => {
					return value.toFixed(0);
				},
			},
		},
		stroke: {
			colors: ['#77cfc6'],
		},
	};
	return (
		<div>
			<h2>Trends</h2>
			<ReactApexChart options={options} series={series} type='line' height={350} />
		</div>
	);
};

export default NPSTrends;
