import { useState } from 'react';

import Navbar from '../../../../components/navbar';
import Sidebar from '../../../../components/sidebar';

import './index.scss';
import { ThemeProvider } from '@emotion/react';
import { settingsTheme } from '../../../../theme';

const SettingsLayout = ({
	children,
	title,
	title2 = undefined,
	className = '',
}) => {
	const [show, setShow] = useState(false);

	return (
		<ThemeProvider theme={settingsTheme}>
			<div className={`settings-layout ${className}`}>
				<Sidebar title={title} show={show} setShow={setShow} />
				<main className='settings-main'>
					<Navbar title={title} title2={title2} show={show} setShow={setShow} />
					{children}
				</main>
			</div>
		</ThemeProvider>
	);
};

export default SettingsLayout;
