import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { BackArrow } from '../icons';

import './LeftFooter.scss';

// TODO: remove this (maybe)
const DefaultButtonText = () => {
	return (
		<div className='custom-modal__default-button'>
			<BackArrow />
			<span>GO BACK</span>
		</div>
	);
};

const LeftFooter = ({
	buttonDisabled = false,
	buttonCallback,
	closeCallback,
	buttonTextOrComponent = <DefaultButtonText />,
}) => {
	let primaryButtonClass = `custom-modal__left-button`;
	if (!buttonDisabled) {
		primaryButtonClass = primaryButtonClass + ' active';
	}

	return (
		<div className='custom-modal__left-footer'>
			<div className={primaryButtonClass} onClick={closeCallback}>
				{buttonTextOrComponent}
			</div>
		</div>
	);
};

export default LeftFooter;
