import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import './index.scss';
import { Cup } from "../icons";

const Milestones = ({ data, setData, description }) => {
  const [milestones, setMilestones] = useState(data);

  useEffect(() => {
    setMilestones(data)
  }, [data])

  const toggleMilestone = (milestone) => {
    const index = milestones.indexOf(milestone);
    let tempMilestones = [...milestones];
    let orderedMilestones = [];

    if(index < 0) {
      tempMilestones.push(milestone);
    } else {
      tempMilestones.splice(index, 1);
    }

    if (tempMilestones.includes('100')) {
      orderedMilestones.push('100');
    }

    if (tempMilestones.includes('1000')) {
      orderedMilestones.push('1000');
    }

    if (tempMilestones.includes('100000')) {
      orderedMilestones.push('100000');
    }

    if (tempMilestones.includes('1000000')) {
      orderedMilestones.push('1000000');
    }

    setMilestones(orderedMilestones);
    setData(orderedMilestones);
  }

  return (
    <div className="milestone-choice">
      <label>Select Your Milestone</label>
      {description && (
        <p>{description}</p>
      )}

      <div className="milestones">
        <Button
          className={`${milestones.includes('100') ? 'active' : ''}`}
          onClick={() => toggleMilestone('100')}
        >
          <div className="image-container">
            <Cup />
          </div>
          <p>100</p>
        </Button>
        <Button
          className={`${milestones.includes('1000') ? 'active' : ''}`}
          onClick={() => toggleMilestone('1000')}
        >
          <div className="image-container">
            <Cup />
          </div>
          <p>1,000</p>
        </Button>
        <Button
          className={`${milestones.includes('100000') ? 'active' : ''}`}
          onClick={() => toggleMilestone('100000')}
        >
          <div className="image-container">
            <Cup />
          </div>
          <p>100,000</p>
        </Button>
        <Button
          className={`${milestones.includes('1000000') ? 'active' : ''}`}
          onClick={() => toggleMilestone('1000000')}
        >
          <div className="image-container">
            <Cup />
          </div>
          <p>1,000,000</p>
        </Button>
      </div>
    </div>
  )
}

export default Milestones;