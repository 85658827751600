/* eslint-disable react-hooks/exhaustive-deps */
import { useParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useBetween } from 'use-between';

import Header from '../../../components/header';
import { Tree } from '../../../components/icons';
import withErrorHandling from '../../../components/withErrorHandling';
import { Container } from 'react-bootstrap';
import useAuth from '../useAuth';
import '../index.scss';
import { ecodriveApi } from '../../../libs/axios';
import { Ecodrive } from '../../../context/store';
import { USER_ENUM } from '../../../context/store/actions/user.actions';
import { AuthLayout } from '../../../lauyouts';

const Confirm = () => {
	const { token } = useParams();
	const navigate = useNavigate();
	const { verify } = useBetween(useAuth);
	const [, dispatch] = useContext(Ecodrive);
	useEffect(() => {
		ecodriveApi
			.get('/confirm', {
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: 'Bearer ' + token,
				},
			})
			.then(({ data }) => {
				localStorage.setItem('authorization', data.token);
				dispatch(USER_ENUM.EMIT_TOKEN_EVENT, { token: data.token });
				verify();
				toast.success('Successfully verified');
				navigate('/setup');
			});
	}, [token]);

	return (
		<AuthLayout>
			<div className='verify-container'>
				<div className='banner' />
				<Header showLinks={false} />
				<Container className='verify-info'>
					<label>Your email has been verified!</label>
					<p>Please do not refresh the page</p>
					<div className='connecting'>
						<div className='connecting-wrapper'>
							<div className='connecting-content'>
								<Tree />
							</div>
						</div>
						<div className='connecting-loader' />
					</div>
				</Container>
			</div>
		</AuthLayout>
	);
};
export default withErrorHandling(Confirm);
