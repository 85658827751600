import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useBetween } from 'use-between';
import { Button, Col, Form, Row } from 'react-bootstrap';
import withErrorHandling from '../../../../components/withErrorHandling';
import SettingsLayout from '../layout';
import useClient from '../../useClient';
import { EyeClose, EyeOpen, OutsideLink } from '../../../../components/icons';
import { validatePassword } from '../../../../plugins';
import Smartlook from 'smartlook-client';

import './index.scss';
import { Ecodrive } from '../../../../context/store';

const Password = () => {
	const { updatePasssword } = useBetween(useClient);

	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [passwordOld, setPasswordOld] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const [showPasswordOld, setShowPasswordOld] = useState(false);

	const [{ user }] = useContext(Ecodrive);
	useEffect(() => {
		if ('id' in user.profile) {
			Smartlook.identify(user.profile.id);
		}
	}, [user.profile]);

	const handleSubmit = (event) => {
		event.preventDefault();

		if (password !== passwordConfirm) {
			toast.error('Password does not match!');
		} else if (!validatePassword(password)) {
			toast.error(
				'Password must be at least 8 characters long, contain a number and a letter',
			);
		} else {
			const formData = new FormData();
			formData.append('newPassword', password);
			formData.append('confirmPassword', passwordConfirm);
			formData.append('oldPassword', passwordOld);

			updatePasssword(formData, user.profile.id).then((data) => {
				setPassword('');
				setPasswordConfirm('');
				setPasswordOld('');
				setShowPassword(false);
				setShowPasswordOld(false);
				toast.success('Password Updated');
			});
		}
	};

	return (
		<SettingsLayout title='Password'>
			<div className='password-container'>
				<h1>Change Password</h1>
				<Form onSubmit={(event) => handleSubmit(event)}>
					<Row>
						<Col md={12} sm={12}>
							<Form.Group className='password-form mb-3'>
								<Form.Label>Old Password</Form.Label>
								<Form.Control
									value={passwordOld}
									onChange={(event) => setPasswordOld(event.target.value)}
									placeholder='Old Password'
									type={`${showPasswordOld ? 'text' : 'password'}`}
									required
								/>
								<Form.Label
									className='show-password'
									onClick={() => {
										setShowPasswordOld(!showPasswordOld);
									}}
								>
									{showPasswordOld ? <EyeOpen /> : <EyeClose />}
								</Form.Label>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={12} sm={12}>
							<Form.Group className='password-form mb-3'>
								<Form.Label>New Password</Form.Label>
								<Form.Control
									value={password}
									onChange={(event) => setPassword(event.target.value)}
									placeholder='New Password'
									type={`${showPassword ? 'text' : 'password'}`}
									required
								/>
								<Form.Label
									className='show-password'
									onClick={() => {
										setShowPassword(!showPassword);
									}}
								>
									{showPassword ? <EyeOpen /> : <EyeClose />}
								</Form.Label>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={12} sm={12}>
							<Form.Group className='password-form mb-3'>
								<Form.Label>Confirm New Password</Form.Label>
								<Form.Control
									value={passwordConfirm}
									onChange={(event) => setPasswordConfirm(event.target.value)}
									placeholder='Confirm New Password'
									type={`${showPasswordConfirm ? 'text' : 'password'}`}
									required
								/>
								<Form.Label
									className='show-password'
									onClick={() => {
										setShowPasswordConfirm(!showPasswordConfirm);
									}}
								>
									{showPasswordConfirm ? <EyeOpen /> : <EyeClose />}
								</Form.Label>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<Button type='submit' className='button-submit full-width my-3'>
								Update password
							</Button>
						</Col>
					</Row>
				</Form>

				<div className='links-group'>
					<a
						rel='noreferrer'
						href='https://ecodrive.community/'
						target='_blank'
						className='outside-link'
					>
						<p>About Ecodrive</p>
						<OutsideLink />
					</a>
					<a
						href='https://ecodrive.community/policies/privacy-policy'
						target='_blank'
						className='outside-link'
						rel='noreferrer'
					>
						<p>Privacy Policy</p>
						<OutsideLink />
					</a>
					<a
						href='https://ecodrive.community/policies/terms-of-service'
						target='_blank'
						className='outside-link'
						rel='noreferrer'
					>
						<p>Terms of Use</p>
						<OutsideLink />
					</a>
				</div>
			</div>
		</SettingsLayout>
	);
};

export default withErrorHandling(Password);
