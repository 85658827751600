/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBetween } from 'use-between';
import useAuth from '../../auth/useAuth';

import './index.scss';
import Header from './../../../components/header';
import Billing from './steps/billing';
import Payment from './steps/payment';
import Connect from './steps/connect';
import Config from './steps/config';
import useClient from '../useClient';
import { toast } from 'react-toastify';

import getStored from '../../../utils/getStored';
import { OnboardingLayout } from '../../../lauyouts';
import { Ecodrive } from '../../../context/store';

import * as Yup from 'yup';
import { USER_ENUM } from '../../../context/store/actions/user.actions';
import { ecodriveApi } from '../../../libs/axios';

const billingSchema = Yup.object({
	address1: Yup.string().required(),
	address2: Yup.string(),
	city: Yup.string().required(),
	country: Yup.string().required(),
	state: Yup.string().required(),
	zip: Yup.string().required(),
});

const paymentSchema = Yup.object({
	customer: Yup.string().required(),
	id: Yup.string().required(),
});

const Setup = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [{ business }, dispatch] = useContext(Ecodrive);

	const [step, setStep] = useState(0);
	const [joinInfo] = useState('shopify');
	const [billingInfo, setBillingInfo] = useState(getStored('billingInfo'));
	const [paymentInfo, setPaymentInfo] = useState(getStored('paymentInfo'));
	const [connectInfo, setConnectInfo] = useState(getStored('connectInfo'));
	const [treeInfo] = useState(0);
	const [configInfo, setConfigInfo] = useState(getStored('configInfo'));
	const [logo, setLogo] = useState('');
	const [milestones, setMilestones] = useState([100, 1000, 10000]);
	const [url, setUrl] = useState(configInfo.url ? configInfo.url : '');
	const [saving, setSaving] = useState(false);
	const [brandSubText, setBrandSubText] = useState(
		'Positive impact on your environment',
	);
	const [brandText, setBrandText] = useState('The Call of The Forest');
	const { businessId, stripeCustomerId, role } = useBetween(useAuth);
	const { putBusiness, postCustomTrees, putContent, setFirst } =
		useBetween(useClient);

	let wrapSetStep = (newStep) => {
		localStorage.setItem('currentSetupStep', newStep);
		setStep(newStep);
	};

	useEffect(() => {
		billingSchema
			.validate(business?.billing)
			.then(() => {
				setStep(1);
			})
			.then(() =>
				paymentSchema.validate(business.subscription).then(() => {
					setStep(2);
				}),
			)
			.catch((e) => console.error(e));
	}, [business]);

	const finishStep = async () => {
		const customTreesFormData = new FormData();
		customTreesFormData.append('trees', treeInfo);
		customTreesFormData.append('type', 'custom');

		let httpUrl = '';
		if (url.includes('https://') || url.includes('http://')) {
			httpUrl = url;
		} else {
			httpUrl = 'https://' + url;
		}

		const formData = new FormData();
		formData.append('email', billingInfo.email);
		formData.append('businessName', billingInfo.business);
		formData.append('businessLegalName', billingInfo.businessLegal);
		formData.append('address1', billingInfo.address1);
		formData.append('address2', billingInfo.address2);
		formData.append('city', billingInfo.city);
		formData.append('country', billingInfo.country);
		formData.append('state', billingInfo.state);
		formData.append('zip', billingInfo.zipCode);
		formData.append('companySite', httpUrl);
		formData.append('active', 1);

		setSaving(true);
		putBusiness(formData)
			.catch((err) => console.log(err))
			.then((putBusinessResponse) => {
				if (treeInfo) {
					return postCustomTrees(customTreesFormData)
						.then((customTreesResponse) => {
							return customTreesResponse;
						})
						.catch((err) => {
							console.error(err);
						});
				}
			})
			.then(async () => {
				await putContent({
					logoImage: logo,
					logoLink: httpUrl,
					navText: brandText,
					headerText: brandText,
					subText: brandSubText,
				});

				const { data: user } = await ecodriveApi.get('/users/me');
				return { user };
			})
			.then(({ user }) => {
				setFirst(true);
				setSaving(false);
				dispatch(USER_ENUM.FILL_PROFILE, user);
				toast.success('Business successfully created');
				navigate('/client-resources');
			})
			.catch((error) => {
				toast.error('Occurring error while saving business info.');
				console.error(error);
				setSaving(false);
			});
	};

	useEffect(() => {
		if (role === '1') {
			navigate('/dashboard');
		} else {
			if (joinInfo === 'shopify' && !connectInfo?.shop) {
				return;
			}
			if (!!businessId && !!stripeCustomerId) {
				navigate('/client-resources');
				// TODO: sync on updates to where/when we calculate total trees planted
				// fetchTPO(userId, businessId)
			}
		}
	}, []);

	useEffect(() => {
		if (location.search) {
			const searchParams = new URLSearchParams(location.search);
			const code = searchParams.get('code');
			const shop = searchParams.get('shop');

			if (code && shop && joinInfo === 'shopify') {
				setConnectInfo({
					code,
					shop,
				});
				wrapSetStep(2);
			}
		}
	}, [location]);

	useEffect(() => {
		localStorage.setItem('billingInfo', JSON.stringify(billingInfo));
	}, [billingInfo]);

	useEffect(() => {
		localStorage.setItem('paymentInfo', JSON.stringify(paymentInfo));
	}, [paymentInfo]);

	useEffect(() => {
		localStorage.setItem('connectInfo', JSON.stringify(connectInfo));
	}, [connectInfo]);

	useEffect(() => {
		localStorage.setItem('configInfo', JSON.stringify(configInfo));
	}, [configInfo]);

	return (
		<OnboardingLayout>
			<div className='setup-container'>
				<div className='banner' />
				<Header showLinks={false} />
				{step === 0 && (
					<Billing
						data={billingInfo}
						setData={setBillingInfo}
						next={() => wrapSetStep(1)}
					/>
				)}
				{step === 1 && (
					<Payment
						data={paymentInfo}
						setData={setPaymentInfo}
						prev={() => wrapSetStep(0)}
						next={() => wrapSetStep(2)}
					/>
				)}
				{step === 2 && (
					<Connect
						data={connectInfo}
						setData={setConnectInfo}
						route={joinInfo}
						prev={() => wrapSetStep(1)}
						next={() => wrapSetStep(3)}
					/>
				)}
				{step === 3 && (
					<Config
						data={configInfo}
						setData={setConfigInfo}
						prev={() => wrapSetStep(2)}
						finish={() => finishStep()}
						status={saving}
						setStatus={setSaving}
						setLogo={setLogo}
						milestones={milestones}
						setMilestones={setMilestones}
						url={url}
						setUrl={setUrl}
						logo={logo}
						brandText={brandText}
						setBrandText={setBrandText}
						brandSubText={brandSubText}
						setBrandSubText={setBrandSubText}
					/>
				)}
			</div>
		</OnboardingLayout>
	);
};

export default Setup;
