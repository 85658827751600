import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

export const IntegrationsLoading = () => {
	return (
		<>
			<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
				<Stack>
					<Skeleton variant='rectangular' animation='wave' height={320} />
					<Skeleton variant='text' height={40} />
				</Stack>
			</Grid>
			<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
				<Stack>
					<Skeleton variant='rectangular' animation='wave' height={320} />
					<Skeleton variant='text' height={40} />
				</Stack>
			</Grid>
			<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
				<Stack>
					<Skeleton variant='rectangular' animation='wave' height={320} />
					<Skeleton variant='text' height={40} />
				</Stack>
			</Grid>
		</>
	);
};
