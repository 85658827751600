/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import {
	Container,
	Row,
	Col,
	InputGroup,
	FormControl,
	Button,
	Form,
	Overlay,
	Tooltip,
} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useBetween } from 'use-between';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import './index.scss';
import Header from '../../../components/header';
import { Back, Tree2 } from '../../../components/icons';
import Upload from '../../../components/upload';
import useClient from '../../client/useClient';
import { AdminLayout } from '../../../lauyouts';

const AVAILABLE_PROJECTS = [
	{
		value: 'MAD',
		display_name: 'Madagascar',
	},
	{
		value: 'THAI',
		display_name: 'Thailand',
	},
	{
		value: 'KELP',
		display_name: 'Kelp',
	},
	{
		value: 'KEN',
		display_name: 'Kenya',
	},
	{
		value: 'MICH',
		display_name: 'Michigan',
	},
	{
		value: 'MONT',
		display_name: 'Montana',
	},
	{
		value: 'HOND',
		display_name: 'Honduras',
	},
	{
		value: 'HI',
		display_name: 'Hawaii',
	},
];

const Client = () => {
	const params = useParams();
	const businessId = params.id;

	const {
		postCustomTrees,
		getBusinessById,
		putBusiness,
		getContent,
		putContent,
		getIntegrationByBusinessId,
		getUserAdmin,
		putAdminUser,
		putIntegration,
	} = useBetween(useClient);
	const [addCusTrees, setAddCusTrees] = useState(0);
	const [addFreeTrees, setAddFreeTrees] = useState(0);
	const [url, setUrl] = useState('');

	// we are going to need this info when we putBusiness()
	const [email, setEmail] = useState('');
	const [businessName, setBusinessName] = useState('');
	const [businessLegalName, setBusinessLegalName] = useState('');
	const [address1, setAddress1] = useState('');
	const [address2, setAddress2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [country, setCountry] = useState('US');
	const [companySite, setCompanySite] = useState('');
	const [active, setActive] = useState(1);

	// we are going to need this info when we putContent
	const [, setContentId] = useState('');
	const [logo, setLogo] = useState('');
	const [navText, setNavText] = useState('');
	const [logoLink, setLogoLink] = useState('');
	const [headerText, setHeaderText] = useState('');
	const [subText, setSubText] = useState('');
	const [, setMilestones] = useState('');

	// we are going to need these to update the business' admin user
	const [adminUserId, setAdminUserId] = useState(null);
	const [adminEmail, setAdminEmail] = useState('');
	const [adminFirstName, setAdminFirstName] = useState('');
	const [adminLastName, setAdminLastName] = useState('');
	const [adminPhone, setAdminPhone] = useState('');
	const [adminRole, setAdminRole] = useState(1);

	const [copied, setCopied] = useState(false);

	// we are going to need this to plant custom trees
	const [manualIntegrationId, setManualIntegrationId] = useState('');
	const [allIntegrations, setAllIntegrations] = useState([]);
	const [originalIntegrations, setOriginalIntegrations] = useState([]);

	const target = useRef(null);

	const navigate = useNavigate();

	const copyUrl = () => {
		navigator.clipboard.writeText(url);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	const saveProject = async () => {
		for (let integration of allIntegrations) {
			let hasChanged = false;
			originalIntegrations.forEach((ogIntegration) => {
				if (
					integration.integration_id === ogIntegration.integration_id &&
					integration.project !== ogIntegration.project
				) {
					hasChanged = true;
				}
			});
			if (!hasChanged) continue;

			const formData = new FormData();
			formData.append('project', integration.project);
			try {
				await putIntegration(integration.integration_id, formData);
				toast.success(
					`INTEGRATION ${integration.integration.toUpperCase()} ${integration.type.toUpperCase()} UPDATED`,
				);
			} catch (err) {
				toast.error(
					`ERROR UDPATING INTEGRATION: ${integration.integration.toUpperCase()} ${integration.type.toUpperCase()}`,
				);
			}
			setOriginalIntegrations(allIntegrations);
		}
	};

	const fetchAdminUser = (adminUserId) => {
		return getUserAdmin(adminUserId).then((res) => {
			setAdminUserId(adminUserId);
			setAdminEmail(res.email);
			setAdminFirstName(res.firstName);
			setAdminLastName(res.lastName);
			setAdminRole(res.role);
			setAdminPhone(res.phone);
			return res;
		});
	};

	const fetchBusiness = () => {
		return getBusinessById(businessId)
			.then((business) => {
				setUrl(
					business.company_site ||
						`${document.location.origin}/public-dashboard/${business.id}`,
				);
				setEmail(business.email);
				setBusinessLegalName(business.businessLegalName);
				setBusinessName(business.businessName);
				setAddress1(business.address1);
				setAddress2(business.address2);
				setCity(business.city);
				setState(business.state);
				setZip(business.zip);
				if (business.country) {
					setCountry(business.country);
				}
				setCompanySite(business.companySite);
				setActive(business.active);
				return fetchAdminUser(business.userId);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const fetchContent = () => {
		return getContent(businessId).then((data) => {
			setContentId(data.id);
			setLogo(data.logoImage);
			setNavText(data.navText);
			setLogoLink(data.logoLink);
			setHeaderText(data.headerText);
			setSubText(data.subText);
			setMilestones(data.milestones);
			setManualIntegrationId(data.integrationId);
			return data;
		});
	};

	const fetchManualIntegration = () =>
		getIntegrationByBusinessId(businessId).then((data) => {
			let manualIntegration = data.find((el) => el.type === 'manual');
			let shopifyIntegration = data.find(
				(el) => el.type === 'order' && el.integration === 'shopify',
			);
			setManualIntegrationId(manualIntegration.integration_id);
			setAllIntegrations(data);
			setOriginalIntegrations(data);

			if (shopifyIntegration) {
				let splitUrl = shopifyIntegration.shop_url.split('.');
				setUrl(`${document.location.origin}/public-dashboard/${splitUrl[0]}`);
			}
			return data;
		});

	const updateContent = () => {
		return putContent({
			navText: navText,
			logoLink: logoLink,
			headerText: headerText,
			subText: subText,
			logoImage: logo,
			businessId: businessId,
		}).then((response) => {
			toast.success(`Content Updated!`);
		});
	};

	const addCustomTrees = async (isFree = false) => {
		try {
			await postCustomTrees({
				trees: isFree ? addFreeTrees : addCusTrees,
				type: isFree ? 'free' : 'custom',
				business: businessId,
			});
			toast.success('Trees planted successfully!');
			if (isFree) {
				setAddFreeTrees(0);
			} else {
				setAddCusTrees(0);
			}
		} catch (error) {
			toast.warn(error.response.data.message);
		}
	};

	const updateBusiness = () => {
		const formData = new FormData();
		formData.append('businessLegalName', businessLegalName);
		formData.append('businessName', businessName);
		formData.append('email', email);
		formData.append('address1', address1);
		formData.append('address2', address2);
		formData.append('city', city);
		formData.append('state', state);
		formData.append('zip', zip);
		formData.append('country', country);
		formData.append('companySite', companySite);

		return putBusiness(formData, businessId).then((response) => {
			toast.success('User profile updated successfully!');
		});
	};

	const updateAdminUser = () => {
		return putAdminUser({
			userId: adminUserId,
			firstName: adminFirstName,
			lastName: adminLastName,
			userRole: adminRole,
			email: adminEmail,
			phone: adminPhone,
		}).then((response) => {
			toast.success('User profile updated successfully!');
		});
	};

	useEffect(() => {
		fetchBusiness();
		fetchContent();
		fetchManualIntegration();
	}, []);

	return (
		<AdminLayout>
			<div className='client-container'>
				<div className='banner' />
				<Header showLinks={true} />
				<Container className='client-info'>
					<div className='client-header'>
						<div onClick={() => navigate('/dashboard')} className='go-back'>
							<Back />
						</div>
						<img src={logo} alt='client-logo' />
					</div>

					<div className='client-content'>
						{url !== '' && (
							<>
								<button
									className='public-url mb-4 btn btn-link'
									ref={target}
									onClick={() => {
										copyUrl();
									}}
								>
									{url}
								</button>
								<p>Please click the above link to copy to the clipboard!</p>
								<Overlay target={target.current} show={copied} placement='top'>
									{(props) => (
										<Tooltip id='overlay-example' {...props}>
											Copied
										</Tooltip>
									)}
								</Overlay>
							</>
						)}
						<h1>Settings</h1>
						<p>
							Update client profile, billing information, logo, milestones, and tress
							planted here. Be sure to save!
						</p>

						<Row className='mt-4'>
							<Col sm={12} xs={12}>
								<h2>Select Active Projects</h2>
							</Col>
							{allIntegrations.map((integration) => {
								return (
									<div key={integration.id}>
										<Col sm={12} xs={12}>
											<label className='form-label'>{`${integration.integration.toUpperCase()} ${integration.type.toUpperCase()}`}</label>
										</Col>
										<Col sm={12} xs={12}>
											<Form.Select
												defaultValue={'MAD'}
												onChange={(event) => {
													let integrationsClone = [];
													allIntegrations.forEach((el) => {
														let newObjectClone = Object.assign({}, el);
														if (el.integration_id === integration.integration_id) {
															newObjectClone.project = event.target.value;
														}
														integrationsClone.push(newObjectClone);
													});
													setAllIntegrations(integrationsClone);
												}}
												value={integration.project}
												className='project-select'
												required
											>
												{AVAILABLE_PROJECTS.map((val) => (
													<option value={val.value} key={val.value}>
														{val.display_name}
													</option>
												))}
											</Form.Select>
										</Col>
									</div>
								);
							})}
							<Col xs={12} className='action-buttons justify-content-center'>
								<Button
									className='button button-secondary'
									onClick={() => saveProject()}
								>
									Update Project
								</Button>
							</Col>
						</Row>

						<Row className='mt-4'>
							<Col sm={12} xs={12}>
								<h2>Add Custom Trees</h2>
								<InputGroup className='mb-3'>
									<InputGroup.Text>
										<Tree2 />
									</InputGroup.Text>
									<FormControl
										value={addCusTrees}
										onChange={(event) => {
											let parsedString = parseInt(event.target.value);
											if (event.target.value === '') {
												setAddCusTrees(event.target.value);
												return;
											}
											if (
												isNaN(event.target.value) ||
												parsedString < 0 ||
												event.target.value.includes('.')
											) {
												return;
											}
											setAddCusTrees(parseInt(event.target.value));
										}}
									/>
								</InputGroup>
							</Col>
							<Col xs={12} className='action-buttons justify-content-center'>
								<Button
									className='button button-secondary'
									disabled={!addCusTrees}
									onClick={() => addCustomTrees()}
								>
									Add Trees
								</Button>
							</Col>
						</Row>

						<Row className='mt-4'>
							<Col sm={12} xs={12}>
								<h2>Add Free Trees</h2>
								<InputGroup className='mb-3'>
									<InputGroup.Text>
										<Tree2 />
									</InputGroup.Text>
									<FormControl
										value={addFreeTrees}
										onChange={(event) => {
											let parsedString = parseInt(event.target.value);
											if (event.target.value === '') {
												setAddFreeTrees(event.target.value);
												return;
											}
											if (
												isNaN(event.target.value) ||
												parsedString < 0 ||
												event.target.value.includes('.')
											) {
												return;
											}
											setAddFreeTrees(parseInt(event.target.value));
										}}
									/>
								</InputGroup>
							</Col>
							<Col xs={12} className='action-buttons justify-content-center'>
								<Button
									className='button button-secondary'
									disabled={!addFreeTrees}
									onClick={() => addCustomTrees(true)}
								>
									Add Free Trees
								</Button>
							</Col>
						</Row>

						<Row className='mt-5'>
							<h2 className='mb-5'>Business Email</h2>
							<Col>
								<Row>
									<Col xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type='email'
												value={email}
												onChange={(event) => setEmail(event.target.value)}
												placeholder='Business Email'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12} className='action-buttons'>
										<Button
											className='button button-primary'
											onClick={() => fetchBusiness()}
										>
											Cancel
										</Button>
										<Button
											className='button button-secondary'
											onClick={() => updateBusiness()}
										>
											Save
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className='mt-5'>
							<h2 className='mb-5'>Business Admin User</h2>
							<Col>
								<Row>
									<Col xs={12} md={6}>
										<Form.Group className='mb-3'>
											<Form.Label>Admin First Name</Form.Label>
											<Form.Control
												type='text'
												value={adminFirstName}
												onChange={(event) => setAdminFirstName(event.target.value)}
												placeholder='Admin First Name'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12} md={6}>
										<Form.Group className='mb-3'>
											<Form.Label>Admin Last Name</Form.Label>
											<Form.Control
												type='text'
												value={adminLastName}
												onChange={(event) => setAdminLastName(event.target.value)}
												placeholder='Admin Last Name'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Admin Email</Form.Label>
											<Form.Control
												type='email'
												value={adminEmail}
												onChange={(event) => setAdminEmail(event.target.value)}
												placeholder='Admin Email'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12} className='action-buttons'>
										<Button
											className='button button-primary'
											onClick={() => fetchAdminUser(adminUserId)}
										>
											Cancel
										</Button>
										<Button
											className='button button-secondary'
											onClick={() => updateAdminUser()}
										>
											Turn Admin
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className='mt-5'>
							<Col xs={12} className='mb-5'>
								<h2 className='mb-5'>Edit Your Logo</h2>
								<Upload
									setLogo={setLogo}
									logo={logo}
									setStatus={() => null}
									setLogoUpload={() => null}
								/>
							</Col>
							<Col xs={12} className='action-buttons'>
								<Button
									className='button button-primary'
									onClick={() => fetchContent()}
								>
									Cancel
								</Button>
								<Button
									className='button button-secondary'
									onClick={() => updateContent()}
								>
									Save
								</Button>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</AdminLayout>
	);
};

export default Client;
