import React from "react";
import { Button, Card } from "react-bootstrap";

const PlacesDropdown = ({ places, onSelect }) => {
  if (places.length) {
    return (
      <Card
        style={{
          position: "absolute",
          maxWidth: 600,
          zIndex: 100,
          maxHeight: "12rem",
          overflowY: "auto",
        }}
      >
        <Card.Body className="d-grid gap-1">
          {places.map((place) => (
            <Button
              key={place.place_id}
              variant="light"
              onClick={() => onSelect(place)}
            >
              {place.formatted_address}
            </Button>
          ))}
        </Card.Body>
      </Card>
    );
  } else {
    return <></>;
  }
};

export default PlacesDropdown;
