import './index.scss';

import { Logo } from '../icons';
import Desktop from '../../assets/images/dashboard/desktop.png';

const DashPreview = ({ navText, logo, logoLink, headerText, subText }) => {
	return (
		<div className='dash-preview'>
			<img className='desktop-bg' src={Desktop} alt='' />
			<div className='page-inner'>
				<span className='logo'>
					<Logo />
				</span>
				<a href={logoLink} className='nav-link'>
					Back to site -&gt;
				</a>
				<div className='content'>
					<a href={logoLink} className='logo-link'>
						<img className='logo' src={logo} alt='' />
					</a>
					<h2>{headerText}</h2>
					<p>{subText}</p>
				</div>
			</div>
		</div>
	);
};

export default DashPreview;
