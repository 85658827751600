import React, { useEffect, useState } from 'react';
import Tree100 from '../../assets/images/dashboard/100.png';
import Tree1000 from '../../assets/images/dashboard/1000.png';
import TreeCustom from '../../assets/images/dashboard/custom.png';
import Celebrate from '../../assets/images/dashboard/celebrate.png';
import Layer2 from '../../assets/images/dashboard/layer2.png';
import {
	Button,
	Col,
	Form,
	InputGroup,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import { useBetween } from 'use-between';
import useClient from '../../pages/client/useClient';
import { toast } from 'react-toastify';
import useAuth from '../../pages/auth/useAuth';

const ReachGoal = () => {
	const { getBusiness, postCustomTrees, getActiveProject, activeProject } =
		useBetween(useClient);
	const { userId } = useBetween(useAuth);

	const [plant, setPlant] = useState(false);
	const [amount, setAmount] = useState(1);
	const [status, setStatus] = useState(false);
	const [celebrate, setCelebrate] = useState(false);

	const handleCustomModalClose = () => {
		setTimeout(() => {
			setCelebrate(false);
		});
		setPlant(false);
	};
	const openTreeModal = (data) => {
		setAmount(data);
		setTimeout(() => {
			setPlant(true);
		});
	};

	useEffect(getActiveProject, [getActiveProject]);

	const plantTrees = (event) => {
		event.preventDefault();
		setStatus(true);
		const customTreesFormData = new FormData();
		customTreesFormData.append('trees', amount);
		customTreesFormData.append('type', 'custom');
		postCustomTrees(customTreesFormData)
			.then((response) => {
				toast.success(
					`${amount} tree${amount === '1' ? '' : 's'} ${
						response.message || 'planted successfully'
					}`,
				);
				setStatus(false);
				setCelebrate(true);
				getBusiness({ user_id: userId });
			})
			.catch(() => {
				toast.error('Failed to add extra trees');
				setStatus(false);
			});
	};

	if (!activeProject) return null;

	return (
		<>
			<Modal
				className='plant-extra'
				show={plant}
				onHide={handleCustomModalClose}
				centered
			>
				<Modal.Header closeButton>
					{!celebrate ? (
						<>
							<Form className='z-1' onSubmit={(event) => plantTrees(event)}>
								<div className='position-relative'>
									{amount === 100 && <img src={Tree100} alt='welcome' />}
									{amount === 1000 && <img src={Tree1000} alt='welcome' />}
									{amount !== 100 && amount !== 1000 && (
										<>
											<img src={TreeCustom} alt='welcome' />
											<Form.Group className='mb-3 custom-trees'>
												<InputGroup className='mb-3'>
													<InputGroup.Text>Trees:</InputGroup.Text>
													<Form.Control
														value={amount}
														onChange={(event) => setAmount(event.target.value)}
														required
													/>
												</InputGroup>
											</Form.Group>
										</>
									)}
								</div>
								<Form.Group className='mb-3 px-4'>
									<Form.Check type='checkbox'>
										<Form.Check.Input type='checkbox' required />
										<Form.Check.Label>
											Please check this box to confirm you want to plan {amount} trees for
											${(amount * activeProject.price).toFixed(2)}.&nbsp;This charge will
											be reflected on your next billing statement per out{' '}
											<Button
												variant='link'
												href='https://ecodrive.community/policies/terms-of-service'
												target='_blank'
											>
												Terms of Use
											</Button>
											.
										</Form.Check.Label>
									</Form.Check>
								</Form.Group>
								<Row>
									<Col className='action-buttons'>
										<Button
											className='button mx-2 button-primary'
											onClick={() => handleCustomModalClose()}
										>
											Cancel
										</Button>
										<Button className='button mx-2 button-secondary' type='submit'>
											{!status ? <>Confirm</> : <Spinner animation='border' />}
										</Button>
									</Col>
								</Row>
							</Form>
						</>
					) : (
						<>
							<img src={Celebrate} alt='Celebrate' />
							<p>Your order is confirmed, we'll get planting!</p>
							<Row>
								<Col className='action-buttons'>
									<Button
										className='button mx-2 button-secondary'
										onClick={handleCustomModalClose}
									>
										Done
									</Button>
								</Col>
							</Row>
						</>
					)}
					<img className='b-layer' src={Layer2} alt='layer' />
				</Modal.Header>
			</Modal>
			<div className='reach-goal'>
				<h2>Need a boost to reach your goal?</h2>
				<p>Trees will be reflected on your next monthly billing statement.</p>
				<div>
					<Button
						onClick={() => {
							openTreeModal(100);
						}}
					>
						Plant 100 trees
					</Button>
					<Button
						onClick={() => {
							openTreeModal(1000);
						}}
					>
						Plant 1,000 trees
					</Button>
					<Button
						onClick={() => {
							openTreeModal(1);
						}}
					>
						Custom
					</Button>
				</div>
			</div>
		</>
	);
};

export default ReachGoal;
