import { createTheme } from '@mui/material';
import GilroyMediumTtf from '../assets/fonts/Gilroy/Gilroy-Medium.ttf';
import GilroyBoldTtf from '../assets/fonts/Gilroy/Gilroy-Bold.ttf';
import GilroySemiBoldTtf from '../assets/fonts/Gilroy/Gilroy-SemiBold.ttf';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const sharedVariables = {
	eco: {
		forest: {
			dark: '#12342e',
			light: '#1a3f38',
		},
		cream: {
			dark: '#d7d4bf',
			light: '#ece6cf',
		},
	},
	typography: {
		fontFamily: ['Gilroy', 'sans-serif'],
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
      @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gilroy'), local('Gilroy-Medium'), url(${GilroyMediumTtf}) format('ttf');
      }

      @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: local('Gilroy'), local('Gilroy-Bold'), url(${GilroyBoldTtf}) format('ttf');
      }

      @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Gilroy'), local('Gilroy-Bold'), url(${GilroySemiBoldTtf}) format('ttf');
      }
      `,
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
				},
			},
		},
	},
};

export const clientTheme = createTheme({
	palette: {
		primary: {
			main: '#224b45',
			contrastText: '#fff',
		},
		secondary: {
			main: '#FFAF67',
			contrastText: '#fff',
		},
	},
	...sharedVariables,
});

export const settingsTheme = createTheme({
	palette: {
		primary: {
			main: '#77CFC6 ',
			contrastText: '#fff',
		},
		secondary: {
			main: '#FFAF67',
			contrastText: '#fff',
		},
	},
	...sharedVariables,
});
