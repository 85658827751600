import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useBetween } from 'use-between';
import useAuth from '../useAuth';
import plantATree from '../../../assets/images/auth/plantATree.svg';
import '../index.scss';
import { Logo } from '../../../components/icons';
import { phoneMask } from '../../../utils/numberMasks';
import withErrorHandling from '../../../components/withErrorHandling';
import { AuthLayout } from '../../../lauyouts';
import MaskedInput from 'react-text-mask';

const RegisterSchema = Yup.object().shape({
	firstName: Yup.string()
		.matches(/^[a-zA-Z\s]*[aeiouAEIOU]+[a-zA-Z\s]*$/, 'Invalid First Name')
		.required('First Name is required'),
	lastName: Yup.string()
		.matches(/^[a-zA-Z\s]*[aeiouAEIOU]+[a-zA-Z\s]*$/, 'Invalid Last Name')
		.required('Last Name is required'),
	phone: Yup.string()
		.matches(
			/^(\+(\d{1,2}))? ?\(?\d{3}\)? ?\d{3}(-| |)\d{4}/,
			'Invalid phone number',
		)
		.required('Phone is required'),
	email: Yup.string()
		.matches(
			/^[^\s@🙂-🙏]+@[^\s@🙂-\u{1F64F}]+\.[^\s@🙂-\u{1F64F}]+$/u,
			'Invalid Email',
		)
		.required('Email is required'),
	emailConfirm: Yup.string()
		.oneOf([Yup.ref('email'), null], 'Emails must match')
		.required('Email confirmation is required'),
	password: Yup.string()
		.min(8, 'Password must have at least 8 characters')
		.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
		.matches(/[0-9]/, 'Password must contain at least one digit')
		.required('Password is required'),
	passwordConfirm: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Password confirmation is required'),
});

const Register = () => {
	let navigate = useNavigate();
	const { authed, businessId, stripeCustomerId, register } = useBetween(useAuth);
	const [error, setError] = useState('');

	const {
		values,
		handleChange,
		handleBlur,
		handleSubmit,
		errors,
		touched,
		isSubmitting,
	} = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
			emailConfirm: '',
			password: '',
			passwordConfirm: '',
		},
		validationSchema: RegisterSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setError(null);
			const formData = new FormData();
			formData.append('firstName', values.firstName);
			formData.append('lastName', values.lastName);
			formData.append('email', values.email);
			formData.append('phone', values.phone);
			formData.append('password', values.password);
			try {
				await register(formData);
				navigate('/verify');
			} catch (e) {
				setError(e.message);
			}
			setSubmitting(false);
		},
	});

	useEffect(() => {
		if (authed) {
			if (!!businessId && !!stripeCustomerId) {
				navigate('/client-resources');
			} else {
				navigate('/setup');
			}
		}
	}, [businessId]);

	return (
		<AuthLayout>
			<div className='auth-container'>
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0'
					charSet='utf-8'
				></meta>
				<Row>
					<Col md={7} sm={12} className='content px-4 py-5'>
						<div className='content-wrapper'>
							<Logo />
							<h1>Register to Ecodrive</h1>
							<Form onSubmit={handleSubmit}>
								<Row>
									<Col>
										<p className='form-title'>Please enter your contact info</p>
									</Col>
								</Row>
								<Row>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>First Name</Form.Label>
											<Form.Control
												type='text'
												name='firstName'
												value={values.firstName}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder='Your First Name'
												required
											/>
											{errors.firstName && touched.firstName && (
												<div>{errors.firstName}</div>
											)}
										</Form.Group>
									</Col>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Last Name</Form.Label>
											<Form.Control
												type='text'
												name='lastName'
												value={values.lastName}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder='Your Last Name'
												required
											/>
											{errors.lastName && touched.lastName && <div>{errors.lastName}</div>}
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={12} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Phone</Form.Label>
											<MaskedInput
												mask={phoneMask}
												name='phone'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.phone}
												guide={false}
												render={(ref, props) => (
													<Form.Control
														ref={ref}
														type='tel'
														placeholder='Your Phone'
														required
														{...props}
													/>
												)}
											/>
											{errors.phone && touched.phone && <div>{errors.phone}</div>}
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type='text'
												name='email'
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder='Your Email'
												required
											/>
											{errors.email && touched.email && <div>{errors.email}</div>}
										</Form.Group>
									</Col>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Email Confirm</Form.Label>
											<Form.Control
												type='text'
												name='emailConfirm'
												value={values.emailConfirm}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder='Your Email Confirm'
												required
											/>
											{errors.emailConfirm && touched.emailConfirm && (
												<div>{errors.emailConfirm}</div>
											)}
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Password</Form.Label>
											<Form.Control
												type='password'
												name='password'
												value={values.password}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder='Password'
												required
											/>
											{errors.password && touched.password && <div>{errors.password}</div>}
										</Form.Group>
									</Col>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Password Confirm</Form.Label>
											<Form.Control
												type='password'
												name='passwordConfirm'
												value={values.passwordConfirm}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder='Password Confirm'
												required
											/>
											{errors.passwordConfirm && touched.passwordConfirm && (
												<div>{errors.passwordConfirm}</div>
											)}
										</Form.Group>
									</Col>
								</Row>
								<Row>
									{error && (
										<Col sm={12} className='my-3'>
											<p className='error mb-0'>{error}</p>
										</Col>
									)}
									<Col md={6} sm={12}>
										<Button
											type='submit'
											className={[
												'button button-secondary full-width my-3',
												isSubmitting && 'bg-secondary',
											].join(' ')}
											disabled={isSubmitting}
										>
											Register
										</Button>
									</Col>
								</Row>
							</Form>
							<p
								className='link mt-3'
								onClick={() => navigate('/login', { replace: true })}
							>
								Already have an account? Login here
							</p>
						</div>
						<div className='banner-img'>
							<img src={plantATree} alt='Plant a tree' />
						</div>
					</Col>
					<Col
						md={5}
						sm={12}
						className='banner py-5 d-none d-md-block'
						style={{
							backgroundImage: `url(${plantATree})`,
						}}
					></Col>
				</Row>
			</div>
		</AuthLayout>
	);
};

export default withErrorHandling(Register);
