/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Spinner, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';

import './dashboardContent.scss';

import { ecodriveApi } from '../../../../libs/axios';

const SIMPLE_PARAGRAPH_WITH_PICTURE_VARIANT_CONFIGS = {
	left: {
		lg: {
			paragraph: {
				order: '1',
				span: 5,
			},
			image: {
				offset: 1,
				span: 6,
			},
		},
	},
	right: {
		lg: {
			paragraph: {
				span: 5,
				offset: 1,
				order: '3',
			},
			image: {
				span: 6,
				offset: 0,
			},
		},
	},
};

const DashboardContent = ({ projectId }) => {
	const [components, setComponents] = useState([]);
	const [loading, setLoading] = useState(false);

	const retrieveProjectDashComponents = useCallback(async () => {
		if (projectId) {
			setLoading(true);
			try {
				const { data } = await ecodriveApi.get(
					`/planting-projects/${projectId}/component`,
				);
				setComponents(data.components);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data);
				}
				toast.error(error.message);
			}
		}
		setLoading(false);
	}, [projectId, setComponents]);

	useEffect(() => {
		retrieveProjectDashComponents();
	}, [retrieveProjectDashComponents]);

	if (loading) {
		return (
			<Row className='justify-content-center'>
				<Spinner variant='light' />
			</Row>
		);
	}

	function componentFactory(cmp) {
		const { name, order, ...properties } = cmp;
		switch (name) {
			case 'BANNER':
				const { image: banner, link } = properties;
				return (
					<Container fluid='sm' key={order} className='dashboard-content'>
						<Row className='tree-content'>
							<Col xs={12} className='d-flex flex-column align-items-center'>
								<img alt='banner' src={banner} />
								<Button
									className='dashboard-button'
									variant='link'
									target='_blank'
									href={link}
								>
									CLICK TO SEE MORE TREES
								</Button>
							</Col>
						</Row>
					</Container>
				);
			case 'SIMPLE_PARAGRAPH_WITH_PICTURE':
				const { header, paragraph, image, variant } = properties;
				return (
					<Container fluid='sm' key={order} className='dashboard-content'>
						<Row className='what-we-plant row'>
							<Col
								className='d-flex align-items-center'
								xs={{ order: '1', span: 12 }}
								lg={SIMPLE_PARAGRAPH_WITH_PICTURE_VARIANT_CONFIGS[variant].lg.paragraph}
							>
								<div className='plant-content'>
									<h2>{header}</h2>
									<p>{paragraph}</p>
									<Button
										variant='link'
										target='_blank'
										href='https://brands.ecodrive.community/tree/plant.html'
									>
										LEARN MORE
									</Button>
								</div>
							</Col>
							<Col
								className='d-flex align-items-center justify-content-center'
								xs={{ order: '2', span: 12 }}
								lg={SIMPLE_PARAGRAPH_WITH_PICTURE_VARIANT_CONFIGS[variant].lg.image}
							>
								<div className='img-wrapper'>
									<Image alt={'plant'} src={image} />
								</div>
							</Col>
						</Row>
					</Container>
				);
			case 'EMBEDDED_MAP':
				const { URI, coords } = properties;
				return (
					<Container key={order}>
						<iframe title={coords} width={'100%'} src={URI} height='550px'></iframe>
					</Container>
				);

			default:
				toast.warn('There is an unhandled Project Dashboard Component');
				return <></>;
		}
	}

	return components.sort((a, b) => a.order - b.order).map(componentFactory);
};

export default DashboardContent;
