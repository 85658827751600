/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useBetween } from 'use-between';
import { useNavigate } from 'react-router-dom';
import withErrorHandling from '../../../components/withErrorHandling';
import useAuth from '../useAuth';
import Header from '../../../components/header';
import { Tree } from '../../../components/icons';
import { Button, Container } from 'react-bootstrap';
import '../index.scss';
import { toast } from 'react-toastify';
import { AuthLayout } from '../../../lauyouts';

const Verify = () => {
	const { verified, userId, email, businessId, stripeCustomerId, resend } =
		useBetween(useAuth);
	const navigate = useNavigate();

	const emailResend = () => {
		resend(userId).then(() => {
			toast.success('A new verification email has been sent');
		});
	};

	useEffect(() => {
		if (verified === 'true') {
			if (!!businessId && !!stripeCustomerId) {
				navigate('/client-resources');
			} else {
				navigate('/setup');
			}
		}
	}, [verified]);

	return (
		<AuthLayout>
			<div className='verify-container'>
				<div className='banner' />
				<Header showLinks={false} />
				<Container className='verify-info'>
					<label>Your account has been created!</label>
					{email && (
						<p>
							Please verify your email address by clicking the link we sent to{' '}
							<span className='underline'>{email}</span>.
						</p>
					)}
					<p className='get-email'>
						Didn't get an email? Check your spam or{' '}
						<Button
							variant='link'
							className='btn-resend'
							onClick={() => emailResend()}
						>
							click here
						</Button>{' '}
						to resend.
					</p>
					<div className='connecting'>
						<div className='connecting-wrapper'>
							<div className='connecting-content'>
								<Tree />
							</div>
						</div>
						<div className='connecting-loader' />
					</div>
				</Container>
			</div>
		</AuthLayout>
	);
};
export default withErrorHandling(Verify);
