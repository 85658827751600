import { useState } from 'react';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import { useBetween } from 'use-between';
import useAuth from '../../../../auth/useAuth';
import { toast } from 'react-toastify';
import useClient from '../../../useClient';

import { basicValidation, allowOnlyLetters } from '../../../../../plugins';

import './index.scss';
import { useAddressState } from '../../../../../hooks/useAddressState';
import PlacesDropdown from '../../../../../components/placesDropdown';
import useHandleAddressComponents from '../../../../../hooks/useHandleAddressComponents';
import usePlacesApi from '../../../../../hooks/usePlacesApi';
import MaskedInput from 'react-text-mask';
import { phoneMask } from '../../../../../utils/numberMasks';

const Billing = ({ data, setData, next }) => {
	const [firstName, setFirstName] = useState(data.firstName || '');
	const [lastName, setLastName] = useState(data.lastName || '');
	const [email, setEmail] = useState(data.email || '');
	const [phone, setPhone] = useState(data.phone || '');
	const [addresses, setAddress, handleAddressChange] = useAddressState();
	const [business, setBusiness] = useState(data.business || '');
	const [businessLegal, setBusinessLegal] = useState(data.businessLegal || '');
	const [businessID, setBusinessID] = useState(data.businessID || '');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { postBusiness, postIntegration, setFirst, postContent } =
		useBetween(useClient);
	const { setManualIntegrationId, setBusinessId } = useBetween(useAuth);

	const finishStep = async () => {
		postBusiness({
			email,
			businessName: business,
			businessLegalName: businessLegal,
			country: addresses.country,
			address1: addresses.address1,
			address2: addresses.address2,
			city: addresses.city,
			state: addresses.state,
			zip: addresses.zip,
		})
			.then(async (business) => {
				const integrationFormData = new FormData();
				setBusinessId(business.id);
				integrationFormData.append('businessId', business.id);
				integrationFormData.append('type', 'manual');
				integrationFormData.append('integration', 'custom');
				integrationFormData.append('project', 'KEN');

				const integration = await postIntegration(integrationFormData);
				localStorage.setItem('manual_integration', integration.id);
				setManualIntegrationId(integration.id);

				const contentFormData = new FormData();
				contentFormData.append('businessId', business.id);
				contentFormData.append('integrationId', integration.id);
				contentFormData.append('navText', '');
				contentFormData.append('logoImage', '');
				contentFormData.append('logoLink', '');
				contentFormData.append('headerText', 'The Call of The Forest');
				contentFormData.append('subText', 'Positive impact on your environment');

				await postContent(contentFormData);
				localStorage.setItem('lastAuth', new Date().valueOf());
				setFirst(true);
				setBusinessID(business.id);
				next();
			})
			.catch((error) => {
				setIsSubmitting(false);
				if (error.isAxiosError) {
					const {
						response: {
							data: { message },
						},
					} = error;
					toast.error(message);
				} else {
					console.error(error);
				}
			});
	};

	function isPhoneNumberValid(phone) {
		const regexMatch = /^[+\d()\s-]*$/;
		const cleanedPhone = phone.replace(/\s/g, '').replace(/-/g, '');

		if (!regexMatch.test(phone)) {
			return false;
		}

		if (
			cleanedPhone.length >= 7 &&
			cleanedPhone.length <= 15 &&
			(cleanedPhone.match(/\+/g) || []).length <= 1
		) {
			if (cleanedPhone.includes('(') && cleanedPhone.includes(')')) {
				const openingIndex = cleanedPhone.indexOf('(');
				const closingIndex = cleanedPhone.indexOf(')');
				if (closingIndex - openingIndex >= 1) {
					return true;
				}
			} else if (cleanedPhone.includes('(') || cleanedPhone.includes(')')) {
				return false;
			}

			return true;
		}

		return false;
	}
	const handleSubmit = (event) => {
		event.preventDefault();
		setIsSubmitting(true);
		if (!isPhoneNumberValid(phone)) {
			toast.error('invalid phone number');
			return;
		} else {
			setData({
				firstName,
				lastName,
				email,
				phone,
				country: addresses.country,
				address1: addresses.address1,
				address2: addresses.address2,
				city: addresses.city,
				state: addresses.state,
				zipCode: addresses.zip,
				business,
				businessLegal,
				businessID,
			});

			finishStep();
		}
	};
	const [places, handlePlaceSelect, getPlacesProps] = usePlacesApi();
	const [countries, handleCountrySelect, , handleCountryChange] =
		usePlacesApi('country');
	const [states, handleStateSelect, , handleStateChange] = usePlacesApi(
		'administrative_area_level_1',
	);
	const [cities, handleCitySelect, , handleCityChange] =
		usePlacesApi('locality');
	const [zips, handleZipSelect, , handleZipChange] = usePlacesApi('postal_code');
	const handleAddressComponents = useHandleAddressComponents(setAddress);

	return (
		<Container className='setup-info billing-info'>
			<label>Welcome</label>
			<p>
				Please enter your <strong>billing</strong> info
			</p>

			<Form onSubmit={(event) => handleSubmit(event)}>
				<Row>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>First Name</Form.Label>
							<Form.Control
								value={firstName}
								onChange={(event) =>
									setFirstName(allowOnlyLetters(event.target.value, firstName))
								}
								placeholder='Your First Name'
								required
							/>
						</Form.Group>
					</Col>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Last Name</Form.Label>
							<Form.Control
								value={lastName}
								onChange={(event) =>
									setLastName(allowOnlyLetters(event.target.value, lastName))
								}
								placeholder='Your Last Name'
								required
							/>
						</Form.Group>
					</Col>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type='email'
								value={email}
								onChange={(event) =>
									setEmail(basicValidation(event.target.value, email))
								}
								placeholder='Your Email'
								required
							/>
						</Form.Group>
					</Col>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Phone</Form.Label>
							<MaskedInput
								guide={false}
								mask={phoneMask}
								value={phone}
								className='profile-item-input'
								onChange={(event) => setPhone(event.target.value)}
								placeholder='Your Phone'
								render={(ref, props) => <Form.Control ref={ref} required {...props} />}
							/>
						</Form.Group>
					</Col>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Business Name</Form.Label>
							<Form.Control
								value={business}
								onChange={(event) => setBusiness(event.target.value)}
								placeholder='Business Name'
								// required
							/>
						</Form.Group>
					</Col>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Business Legal Name</Form.Label>
							<Form.Control
								value={businessLegal}
								onChange={(event) => setBusinessLegal(event.target.value)}
								placeholder='Your Business Legal Name'
								// required
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Search your address</Form.Label>
							<Form.Control {...getPlacesProps()} placeholder='Search your address' />
							<PlacesDropdown
								places={places}
								onSelect={(place) =>
									handlePlaceSelect(place.place_id, handleAddressComponents)
								}
							/>
						</Form.Group>
					</Col>
					<Col xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Address Line 1</Form.Label>
							<Form.Control
								value={addresses.address1}
								onChange={handleAddressChange('address1')}
								placeholder='Your Address Line 1'
								required
							/>
						</Form.Group>
					</Col>
					<Col xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Address Line 2</Form.Label>
							<Form.Control
								value={addresses.address2}
								onChange={handleAddressChange('address2')}
								placeholder='Your Address Line 2'
							/>
						</Form.Group>
					</Col>
					<Col sm={6} xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>City</Form.Label>
							<Form.Control
								value={addresses.city}
								placeholder='Your City'
								onChange={(ev) => {
									const setCity = handleAddressChange('city');
									handleCityChange(ev);
									setCity(ev);
								}}
								required
							/>
							<PlacesDropdown
								places={cities}
								onSelect={(place) =>
									handleCitySelect(place.place_id, handleAddressComponents)
								}
							/>
						</Form.Group>
					</Col>
					<Col sm={3} xs={6}>
						<Form.Group className='mb-3'>
							<Form.Label>State</Form.Label>
							<Form.Control
								value={addresses.state}
								placeholder='Your State'
								onChange={(ev) => {
									const setState = handleAddressChange('state');
									setState(ev);
									handleStateChange(ev);
								}}
								required
							/>
							<PlacesDropdown
								places={states}
								onSelect={(place) =>
									handleStateSelect(place.place_id, handleAddressComponents)
								}
							/>
						</Form.Group>
					</Col>
					<Col sm={3} xs={6}>
						<Form.Group className='mb-3'>
							<Form.Label>Zipcode</Form.Label>
							<Form.Control
								value={addresses.zip}
								maxLength={5}
								placeholder='Your Zipcode'
								onChange={(ev) => {
									const setZip = handleAddressChange('zip');
									setZip(ev);
									handleZipChange(ev);
								}}
								required
							/>
							<PlacesDropdown
								places={zips}
								onSelect={(place) =>
									handleZipSelect(place.place_id, handleAddressComponents)
								}
							/>
						</Form.Group>
					</Col>

					<Col xs={12}>
						<Form.Group className='mb-3'>
							<Form.Label>Country</Form.Label>
							<Form.Control
								value={addresses.country}
								onChange={(ev) => {
									const setCountry = handleAddressChange('country');
									setCountry(ev);
									handleCountryChange(ev);
								}}
								placeholder='Your Country'
							/>
							<PlacesDropdown
								places={countries}
								onSelect={(place) =>
									handleCountrySelect(place.place_id, handleAddressComponents)
								}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col className='action-buttons'>
						<Button
							type='submit'
							className='button button-secondary'
							disabled={isSubmitting}
						>
							{isSubmitting ? 'Submitting...' : 'Next'}
						</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
};

export default Billing;
