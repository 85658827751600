import React from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';

const Headers = ({ isActive, onToggle, activeHeader, onHeaderClick }) => {
	return (
		<Dropdown drop='down' as={ButtonGroup}>
			<Button size='sm' variant='light' active={isActive} onClick={onToggle}>
				<i className='bi bi-fonts'></i>
			</Button>
			<Dropdown.Toggle variant='light' size='sm' split />
			<Dropdown.Menu>
				<Dropdown.Item
					active={activeHeader('header-one')}
					onClick={() => onHeaderClick('header-one')}
				>
					H1
				</Dropdown.Item>
				<Dropdown.Item
					active={activeHeader('header-two')}
					onClick={() => onHeaderClick('header-two')}
				>
					H2
				</Dropdown.Item>
				<Dropdown.Item
					active={activeHeader('header-three')}
					onClick={() => onHeaderClick('header-three')}
				>
					H3
				</Dropdown.Item>
				<Dropdown.Item
					active={activeHeader('header-four')}
					onClick={() => onHeaderClick('header-four')}
				>
					H4
				</Dropdown.Item>
				<Dropdown.Item
					active={activeHeader('header-five')}
					onClick={() => onHeaderClick('header-five')}
				>
					H5
				</Dropdown.Item>
				<Dropdown.Item
					active={activeHeader('header-six')}
					onClick={() => onHeaderClick('header-six')}
				>
					H6
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default Headers;
