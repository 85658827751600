export function calculateNPS(data) {
	let promoters = 0;
	let passives = 0;
	let detractors = 0;

	data.forEach((item) => {
		if (item.score >= 9) {
			promoters += 1;
		} else if (item.score >= 7) {
			passives += 1;
		} else {
			detractors += 1;
		}
	});

	const totalResponses = data.length;

	const promotersPercentage = (promoters / totalResponses) * 100;
	const detractorsPercentage = (detractors / totalResponses) * 100;

	const NPS = promotersPercentage - detractorsPercentage;

	return NPS;
}
