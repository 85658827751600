import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import IntegrationCard from '../../../../components/integrationCard/IntegrationCard';
import SettingsLayout from '../layout';
import RequestIntegrationModal from '../../../../components/RequestIntegrationModal/RequestIntegrationModal';
import {
	useIntegrations,
	useConnectShopify,
	getBusinessIntegrationsKey,
} from '../../../../hooks/integrations';
import { useAuthenticateZapier } from '../../../../hooks/zapier/useAuthenticateZapier';
import { useEcodrive } from '../../../../context/store/Ecodrive';
import { getZapierAccessToken } from '../../../../utils/zapier';
import { IntegrationsLoading } from './IntegrationsLoading';
import { IntegrationModal, ZapierIntegrationModal } from './IntegrationModal';

const CustomizedButton = styled(Button)({
	backgroundColor: '#77cfc6',
	color: '#fff',
	width: '100%',
	fontWeight: 600,
	margin: '5% 0',
	'&:hover': {
		backgroundColor: '#77cfc6',
	},
});

const WelcomeHeader = styled(Typography)({
	fontWeight: 'bold',
	color: 'black',
	marginBottom: '0.75rem',
	maxWidth: '70%',
	letterSpacing: '0.5px',
});

const SubHeading = styled(Typography)({
	paddingTop: '10px',
	color: '#000',
	fontSize: '13px',
	fontWeight: '500',
	maxWidth: '80%',
	letterSpacing: '0.1px',
});

const Integrations = () => {
	const client = useQueryClient();
	const [openModal, setOpenModal] = useState(false);
	const [selectedIntegrationTitle, setSelectedIntegrationTitle] = useState<
		undefined | string
	>();
	const [selectedIntegrationModalOpen, setSelectedIntegrationModalOpen] =
		useState(false);
	const [
		{
			business: { id: businessId },
		},
	] = useEcodrive() as any;
	const { isAuthenticating, handleAuthenticateWithZapier } =
		useAuthenticateZapier();

	const { connecting: isShopifyConnecting } = useConnectShopify({
		onConnected: () => {
			client.invalidateQueries([getBusinessIntegrationsKey]);
		},
	});

	if (!process.env.REACT_APP_ZAPIER_CLIENT_ID)
		throw new Error('The Zapier Client ID is missing from the environment');

	const { isLoading, integrations } = useIntegrations({
		zapierClientId: process.env.REACT_APP_ZAPIER_CLIENT_ID,
		businessId,
	});

	const selectedIntegration = integrations.find(
		(i) => i.title === selectedIntegrationTitle,
	);

	const handleModal = () => setOpenModal(!openModal);

	const handleZapTemplateOnClick = async (title: string) => {
		if (!getZapierAccessToken()) {
			await handleAuthenticateWithZapier();
		}

		if (getZapierAccessToken()) {
			setSelectedIntegrationTitle(title);
			setSelectedIntegrationModalOpen(true);
		}
	};

	return (
		<>
			<SettingsLayout title='Integrations'>
				{selectedIntegration &&
					selectedIntegrationModalOpen &&
					(selectedIntegration.type === 'zapier' ? (
						<ZapierIntegrationModal
							template={selectedIntegration}
							isOpen={selectedIntegrationModalOpen}
							onCloseModal={() => setSelectedIntegrationModalOpen(false)}
							integration={selectedIntegration.businessIntegration}
							zap={selectedIntegration.zap}
							businessId={businessId}
						/>
					) : (
						<IntegrationModal
							isOpen={selectedIntegrationModalOpen}
							onCloseModal={() => setSelectedIntegrationModalOpen(false)}
							platformIntegration={selectedIntegration}
							integration={selectedIntegration.businessIntegration}
							labels={selectedIntegration.events}
							businessId={businessId}
						/>
					))}
				<RequestIntegrationModal openModal={openModal} handleModal={handleModal} />
				<div className='container mt-3'>
					<div className='heading-section pt-2 pb-1'>
						<WelcomeHeader variant='h1'>
							Connect your favorite apps and make an impact across any customer or
							employee action!
						</WelcomeHeader>
						<SubHeading>
							To get started, simply select activate integration, set up how you'd like
							to plant and login to that account! Please note, some of the integrations
							available are powered through Zapier and you may be prompted to login or
							create an account to activate.
						</SubHeading>
					</div>
					<div className='row pt-3'>
						<Grid container spacing={2}>
							{isLoading ? (
								<IntegrationsLoading />
							) : (
								<>
									{integrations.map((integration, index) => {
										if (integration.type === 'zapier') {
											const disabled = integration.slug !== 'amazon-seller-central';

											return (
												<Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
													<IntegrationCard
														logo={integration.logo}
														description={integration.description}
														platform={integration.platform}
														title={integration.title}
														isActive={integration.isActive}
														labels={integration.events}
														disabled={disabled}
														actionText={
															disabled
																? 'Coming Soon'
																: isAuthenticating
																? 'Authenticating with Zapier...'
																: integration.businessIntegration
																? 'Edit Integration'
																: 'Activate Integration'
														}
														onClick={() => handleZapTemplateOnClick(integration.title)}
													/>
												</Grid>
											);
										} else {
											return (
												<Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
													<IntegrationCard
														logo={integration.logo}
														description={integration.description}
														title={
															integration.title.charAt(0).toUpperCase() +
															integration.title.slice(1)
														}
														isActive={integration.isActive}
														disabled={isShopifyConnecting}
														actionText={
															isShopifyConnecting
																? 'Connecting Shopify'
																: integration.title === 'custom'
																? 'Create Impact'
																: integration.isActive
																? 'Edit Integration'
																: 'Activate Integration'
														}
														labels={integration?.events}
														platform={integration?.platform}
														onClick={() => {
															setSelectedIntegrationTitle(integration.title);
															setSelectedIntegrationModalOpen(true);
														}}
													/>
												</Grid>
											);
										}
									})}
									<Grid item xs={12}>
										<CustomizedButton variant='contained' onClick={handleModal} fullWidth>
											Request new integration
										</CustomizedButton>
									</Grid>
								</>
							)}
						</Grid>
					</div>
				</div>
			</SettingsLayout>
		</>
	);
};

export default Integrations;
