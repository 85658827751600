import SettingsLayout from "../layout";

import "./index.scss";

const Members = () => {
  return (
    <SettingsLayout title="Members"></SettingsLayout>
  );
}

export default Members;
