import React from 'react';
import Modal from '@mui/material/Modal';

import { Shopify } from '../icons'; //We can change this to the integration logo.

import './SuccessModal.scss';

interface SuccessModalProps {
	platform: string;
	logo: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ platform, logo }) => {
	return (
		<div className='success-modal'>
			<div className='success-modal__image'>
				<div className='success-modal__connecting'>
					<div className='success-modal__connecting-wrapper'>
						<div className='success-modal__connecting-content'>
							<Shopify />
						</div>
					</div>
					<div className='success-modal__connecting-loader' />
				</div>
			</div>
			<div className='success-modal__container text-center pt-4'>
				<h1 className='text-success-modal'>Success!</h1>
				<p className='text-light-grey fs-6'>
					You have successfully activated the {platform} integration.
				</p>
			</div>
		</div>
	);
};

export default SuccessModal;
