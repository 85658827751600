import { useMutation } from 'react-query';

import { ecodriveApi } from '../../libs/axios';

interface PostIntegrationData {
	readonly businessId: string;
	readonly type: string;
	readonly integration: string;
	readonly authToken?: string;
	readonly project: string;
	readonly treesPerTransaction?: number;
}

export const usePostIntegration = () => {
	const mutate = useMutation((data: PostIntegrationData) =>
		ecodriveApi.post('/integrations', data),
	);

	return mutate;
};
