/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useBetween } from 'use-between';

import './index.scss';
import Header from './../../../components/header';
import Chart1 from '../../../assets/images/admin/chart_1.png';
import Chart2 from '../../../assets/images/admin/chart_2.png';
import Chart3 from '../../../assets/images/admin/chart_3.png';
import Minus from '../../../assets/images/admin/minus.png';
import Plus from '../../../assets/images/admin/plus.png';
import Up from '../../../assets/images/admin/up.png';
import { Back } from '../../../components/icons';
import Statistics from './statistics';
import Status from './status';
import Filters from '../../../components/filters';
import TransactionsFilters from '../../../components/transactionsFilters';
import useAdmin from '../useAdmin';
import useClient from '../../client/useClient';
import { numberWithCommas, formatDateTime, formatDate } from '../../../plugins';
import Projects from '../../../components/projects';
import { AdminLayout } from '../../../lauyouts';

const Dashboard = () => {
	let navigate = useNavigate();

	const { businessesInfo, getBusinesses } = useBetween(useAdmin);
	const {
		getAdminDashboardOrders,
		getAdminDashboardBusinesses,
		getAdminDashboardBusinessTransactions,
	} = useBetween(useClient);
	const [client, setClient] = useState(0);
	const [viewingBusiness, setViewingBusiness] = useState(null);
	const [, setTrees] = useState(0);
	const [, setOrders] = useState(0);
	const [recentTransactions, setRecentTransactions] = useState([]);
	const [csvData, setCsvData] = useState([]);
	const [from, setFrom] = useState('');
	const [fromTransaction, setFromTransaction] = useState('');
	const [to, setTo] = useState(new Date());
	const [toTransaction, setToTransaction] = useState(new Date());
	const [totalBusinesses, setTotalBusinesses] = useState(0);
	const [allBusinessesList, setAllBusinessesList] = useState([]);
	const [lostBusinesses, setLostBusinesses] = useState(0);
	const [display, setDisplay] = useState('newest');
	const [displayTransactions, setDisplayTransactions] = useState('date');
	const [orderTransactions, setOrderTransactions] = useState('desc');

	const [totalOrders, setTotalOrders] = useState(0);
	const [totalTrees, setTotalTrees] = useState(0);

	const filterFrom = (date) => {
		setFrom(date);
		if (date > to) {
			return;
		}
		getAdminDashboardOrders({
			from: formatDate(date),
			to: formatDate(to),
		}).then((data) => {
			setTotalOrders(data.orders);
			setTotalTrees(data.total_trees);
		});
		getAdminDashboardBusinesses({
			from: formatDate(date),
			to: formatDate(to),
		}).then((data) => {
			setTotalBusinesses(data.stats.total_businesses);
			setAllBusinessesList(data.stats.businesses);

			const csv = [];
			let lost = 0;
			if (data.stats.businesses.length > 0) {
				// eslint-disable-next-line array-callback-return
				data.stats.businesses.forEach((thisBusiness) => {
					if (!thisBusiness.active) {
						lost++;
					}
					csv.push({
						businessName: thisBusiness.business_name,
						trees: thisBusiness.total_trees,
						status: thisBusiness.active ? 'Active' : 'Inactive',
						joined: formatDateTime(thisBusiness.created_at),
					});
				});
				setCsvData(csv);
				setLostBusinesses(lost);
			}
		});
	};

	const filterFromTransaction = (date) => {
		setFromTransaction(date);
	};

	const filterTo = (date) => {
		setTo(date);
		// if (from) {
		getAdminDashboardOrders({
			from: formatDate(from),
			to: formatDate(date),
		}).then((data) => {
			setTotalOrders(data.orders);
			setTotalTrees(data.total_trees);
		});
		getAdminDashboardBusinesses({
			from: formatDate(from),
			to: formatDate(date),
		}).then((data) => {
			setTotalBusinesses(data.stats.total_businesses);
			setAllBusinessesList(data.stats.businesses);

			const csv = [];
			let lost = 0;
			if (data.stats.businesses.length > 0) {
				// eslint-disable-next-line array-callback-return
				data.stats.businesses.forEach((thisBusiness) => {
					if (!thisBusiness.active) {
						lost++;
					}
					csv.push({
						businessName: thisBusiness.business_name,
						trees: thisBusiness.total_trees,
						status: thisBusiness.active ? 'Active' : 'Inactive',
						joined: formatDateTime(thisBusiness.created_at),
					});
				});
				setCsvData(csv);
				setLostBusinesses(lost);
			}
		});
		// } else {
		// 	getAdminDashboardOrders().then((data) => {
		// 		setTotalOrders(data.orders);
		// 		setTotalTrees(data.total_trees);
		// 	});
		// 	getAdminDashboardBusinesses().then((data) => {
		// 		setTotalBusinesses(data.stats.total_businesses);
		// 		setAllBusinessesList(data.stats.businesses);

		// 		const csv = [];
		// 		let lost = 0;
		// 		if (data.stats.businesses.length > 0) {
		// 			// eslint-disable-next-line array-callback-return
		// 			data.stats.businesses.forEach((thisBusiness) => {
		// 				if (thisBusiness.active !== 1) {
		// 					lost++;
		// 				}
		// 				csv.push({
		// 					businessName: thisBusiness.business_name,
		// 					trees: thisBusiness.total_trees,
		// 					status: thisBusiness.active === 1 ? 'Active' : 'Inactive',
		// 					joined: formatDateTime(thisBusiness.created_at),
		// 				});
		// 			});
		// 			setCsvData(csv);
		// 			setLostBusinesses(lost);
		// 		}
		// 	});
		// }
	};

	const filterToTransactions = (date) => {
		setToTransaction(date);
	};

	const sortBy = (data) => {
		setDisplay(data);
		// if (from) getBusinesses(data, formatDate(from), formatDate(to));
		// else getBusinesses(data);
	};

	const sortByTransactions = (data) => {
		setDisplayTransactions(data);
		filterTransactions();
	};

	const orderByTransactions = (data) => {
		setOrderTransactions(data);
		filterTransactions();
	};

	const filterTransactions = () => {};

	const setClientInfo = (id) => {
		setClient(id);
		getAdminDashboardBusinessTransactions(id).then((data) => {
			setRecentTransactions(data.transactions || []);
			setViewingBusiness(data);
		});
	};

	const back = () => {
		setClient(0);
		setViewingBusiness(null);
		setFromTransaction('');
		setToTransaction(new Date());
		setRecentTransactions([]);
	};

	const headers = [
		{ label: 'Business Name', key: 'businessName' },
		{ label: 'Trees Planted', key: 'trees' },
		{ label: 'Status', key: 'status' },
		{ label: 'Joined', key: 'joined' },
	];

	const headersTransactions = [
		{ label: 'Event ID', key: 'event_id' },
		{ label: 'Status', key: 'payment_successful' },
		{ label: 'Trees', key: 'treesOrdered' },
		{ label: 'Amount', key: 'order_value' },
		{ label: 'Type', key: 'type' },
		{ label: 'Created At', key: 'created_at' },
	];

	useEffect(() => {
		// getBusinesses(display);
		getAdminDashboardOrders().then((data) => {
			setTotalOrders(data.orders);
			setTotalTrees(data.total_trees);
		});
		getAdminDashboardBusinesses().then((data) => {
			setTotalBusinesses(data.stats.total_businesses);
			setAllBusinessesList(data.stats.businesses);

			const csv = [];
			let lost = 0;
			if (data.stats.businesses.length > 0) {
				// eslint-disable-next-line array-callback-return
				data.stats.businesses.forEach((thisBusiness) => {
					if (!thisBusiness.active) {
						lost++;
					}
					csv.push({
						businessName: thisBusiness.business_name,
						trees: thisBusiness.total_trees,
						status: thisBusiness.active ? 'Active' : 'Inactive',
						joined: formatDateTime(thisBusiness.created_at),
					});
				});
				setCsvData(csv);
				setLostBusinesses(lost);
			}
		});
	}, []);

	useEffect(() => {
		let totalTrees = 0;
		let totalOrders = 0;
		let lost = 0;
		const csv = [];
		if (businessesInfo.length > 0) {
			// eslint-disable-next-line array-callback-return
			Object.keys(businessesInfo).map((idx) => {
				if (businessesInfo[idx].details.status !== 'Active') {
					lost++;
				}
				totalTrees += businessesInfo[idx].total_trees;
				totalOrders += businessesInfo[idx].total_orders;
				csv.push({
					businessName: businessesInfo[idx].details.business_name,
					trees: businessesInfo[idx].total_trees,
					status: businessesInfo[idx].details.status,
					joined: formatDateTime(businessesInfo[idx].details.created_at),
				});
			});
			setCsvData(csv);
			setTrees(totalTrees);
			setOrders(totalOrders);
			// setTotalBusinesses(businessesInfo.length);
			setLostBusinesses(lost);
		}
	}, [businessesInfo]);

	const renderClientList = () => {
		if (allBusinessesList.length === 0) return null;

		let allBusinessClone = [...allBusinessesList];

		if (display === 'newest') {
			allBusinessClone.sort((a, b) => {
				let aCreatedAtDate = new Date(a.created_at);
				let bCreatedAtDate = new Date(b.created_at);
				if (aCreatedAtDate > bCreatedAtDate) return -1;
				if (aCreatedAtDate === bCreatedAtDate) return 0;
				if (aCreatedAtDate < bCreatedAtDate) return 1;
				return null;
			});
		}
		if (display === 'oldest') {
			allBusinessClone.sort((a, b) => {
				let aCreatedAtDate = new Date(a.created_at);
				let bCreatedAtDate = new Date(b.created_at);
				if (aCreatedAtDate < bCreatedAtDate) return -1;
				if (aCreatedAtDate === bCreatedAtDate) return 0;
				if (aCreatedAtDate > bCreatedAtDate) return 1;
				return null;
			});
		}
		if (display === 'mosttrees') {
			allBusinessClone.sort((a, b) => {
				let aTrees = parseInt(a.transactions[0]?.total_trees || 0);
				let bTrees = parseInt(b.transactions[0]?.total_trees || 0);
				if (aTrees > bTrees) return -1;
				if (aTrees === bTrees) return 0;
				if (aTrees < bTrees) return 1;
				return null;
			});
		}
		if (display === 'fewtrees') {
			allBusinessClone.sort((a, b) => {
				let aTrees = parseInt(a.transactions[0]?.total_trees || 0);
				let bTrees = parseInt(b.transactions[0]?.total_trees || 0);
				if (aTrees < bTrees) return -1;
				if (aTrees === bTrees) return 0;
				if (aTrees > bTrees) return 1;
				return null;
			});
		}

		return allBusinessClone.map((thisBusiness, index) => (
			<div
				className='client-row cursor-pointer'
				key={thisBusiness.id}
				onClick={() => {
					setClientInfo(thisBusiness.id);
				}}
			>
				<span className='row-name'>{thisBusiness.businessName}</span>
				<span className='row-status'>
					{thisBusiness.transactions[0]?.total_trees || 0}
				</span>
				<span className='row-value'>
					{thisBusiness.active ? 'Active' : 'Inactive'}
				</span>
				<span className='row-time'>{formatDateTime(thisBusiness.created_at)}</span>
			</div>
		));
	};

	const [tab, setTab] = useState('clients');

	return (
		<AdminLayout>
			<div className={`dashboard-container ${client ? 'dashboard-client' : ''}`}>
				<div className='banner' />
				<Header showLinks={true} />
				<Container className='dashboard-info'>
					<Row>
						<Col md={3} sm={6} xs={12} className='mb-4'>
							<div className='status-card highlight left-order'>
								<div className='card-content'>
									<p>Lifetime Trees Planted</p>
									<span>{numberWithCommas(totalTrees)}</span>
								</div>
								<img src={Chart1} alt='Chart' />
							</div>
						</Col>
						<Col md={3} sm={6} xs={12} className='mb-4'>
							<div className='status-card left-order'>
								<div className='card-content'>
									<p>Total Brands</p>
									<span>{numberWithCommas(totalBusinesses)}</span>
								</div>
								<img src={Chart2} alt='Chart' />
							</div>
						</Col>
						<Col md={3} sm={6} xs={12} className='mb-4'>
							<div className='status-card right-order'>
								<img src={Chart3} alt='Chart' />
								<div className='card-content'>
									<p>Work Days Created</p>
									<span>{numberWithCommas(totalTrees / 10)}</span>
								</div>
							</div>
						</Col>
						<Col md={3} sm={6} xs={12} className='mb-4'>
							<div className='status-card right-order'>
								<img src={Chart3} alt='Chart' />
								<div className='card-content'>
									<p>LBS of Carbon Removed</p>
									<span>{numberWithCommas(totalTrees * 680)}</span>
								</div>
							</div>
						</Col>
					</Row>
					{client === 0 ? (
						<Row>
							<Col className='mb-4'>
								<div className='dashboard-filter'>
									<p className='filter-title mb-3'>Climate Positive Orders</p>
									<Filters
										fminDate={''}
										fmaxDate={to}
										tminDate={from}
										tmaxDate={new Date()}
										from={from}
										setFrom={filterFrom}
										to={to}
										setTo={filterTo}
										display={display}
										isAdmin={true}
										setDisplay={sortBy}
										headers={headers}
										csvData={csvData}
									/>
								</div>
							</Col>
						</Row>
					) : (
						<>
							<Row>
								<Col className='client-header'>
									<div className='go-back' onClick={() => back()}>
										<Back />
									</div>
									<div className='client-logo'>
										<p>{viewingBusiness?.businessName}</p>
									</div>
									<Button
										className='button button-primary'
										onClick={() => navigate(`/client/${client}`)}
									>
										Client Info
									</Button>
								</Col>
							</Row>
							<Row>
								<Col className='mb-4 '>
									<div className='transactions-filter'>
										<p className='transactions-title mb-3'>Filter Transactions</p>
										<TransactionsFilters
											fminDate={''}
											fmaxDate={toTransaction}
											tminDate={fromTransaction}
											tmaxDate={new Date()}
											from={fromTransaction}
											setFrom={filterFromTransaction}
											to={toTransaction}
											setTo={filterToTransactions}
											display={displayTransactions}
											isAdmin={true}
											setDisplay={sortByTransactions}
											headers={headersTransactions}
											csvData={csvData}
											order={orderTransactions}
											setOrder={orderByTransactions}
										/>
									</div>
								</Col>
							</Row>
						</>
					)}
					<Row>
						<Col md={8} sm={12} className='mb-3'>
							<div className='dashboard-card clients-card'>
								{client ? (
									<>
										<p className='card-title'>Recent Transactions</p>
										<div className='clients-container'>
											<div className='client-row row-header'>
												<span className='row-name'>Event ID</span>
												<span className='row-status'>Status</span>
												<span className='row-status'>Trees</span>
												<span className='row-value'>Amount</span>
												<span className='row-value'>Type</span>
												<span className='row-time'>Created At</span>
											</div>
											{recentTransactions.length > 0 &&
												recentTransactions
													.filter((transaction) => {
														const { created_at } = transaction;
														const createdAt = new Date(created_at);
														if (fromTransaction && !toTransaction)
															return createdAt >= fromTransaction;
														if (!fromTransaction && toTransaction)
															return createdAt <= toTransaction;
														if (fromTransaction && toTransaction)
															return (
																createdAt >= fromTransaction && createdAt <= toTransaction
															);
														return true;
													})
													.sort((a, b) => {
														if (displayTransactions === 'type') {
															if (orderTransactions === 'asc') return b.type > a.type ? -1 : 1;
															return a.type > b.type ? -1 : 1;
														}
														if (displayTransactions === 'date') {
															if (orderTransactions === 'asc')
																return new Date(a.created_at) - new Date(b.created_at);
															return new Date(b.created_at) - new Date(a.created_at);
														}
													})
													.map((transaction) => {
														let thisId = transaction.eventId;
														let thisPaid = transaction.orderValue;
														if (transaction.type === 'custom') {
															thisId = transaction.usageId;
															thisPaid = transaction.totalPaid;
														}
														if (transaction.type === 'free') {
															thisId = transaction.id;
															thisPaid = transaction.totalPaid;
														}
														return (
															<div className='client-row' key={transaction.id}>
																<span className='row-name'>{thisId}</span>
																<span className='row-status'>
																	{transaction.paymentSuccessful}
																</span>
																<span className='row-status'>{transaction.treesOrdered}</span>
																<span className='row-value'>${thisPaid}</span>
																<span className='row-value'>{transaction.type}</span>
																<span className='row-time'>
																	{formatDateTime(transaction.created_at)}
																</span>
															</div>
														);
													})}
										</div>
										{/* <Button className="button button-secondary mx-auto" onClick={() => {showAllOrders()}}>View All Invoices</Button> */}
									</>
								) : (
									<>
										<Row className='bg-light rounded mb-2'>
											<Col
												onClick={() => setTab('clients')}
												xs={6}
												className={[tab === 'clients' && 'bg-white', 'rounded'].join(' ')}
											>
												<p className='card-title mb-0 text-center p-2'>Recent Clients</p>
											</Col>
											<Col
												onClick={() => setTab('projects')}
												xs={6}
												className={[tab === 'projects' && 'bg-white', 'rounded'].join(' ')}
											>
												<p className='card-title m-0 text-center p-2'>Projects</p>
											</Col>
										</Row>
										{tab === 'clients' && (
											<div className='clients-container'>
												<div className='client-row row-header'>
													<span className='row-name'>Business</span>
													<span className='row-status'>Trees planted</span>
													<span className='row-value'>Status</span>
													<span className='row-time'>Joined</span>
												</div>
												{renderClientList()}
											</div>
										)}
										{tab === 'projects' && <Projects />}
									</>
								)}
							</div>
						</Col>
						<Col md={4} sm={12} className='mb-3'>
							<Statistics
								orders={viewingBusiness?.transactions?.length || totalOrders}
								trees={
									viewingBusiness?.transactions?.reduce(
										(acc, transaction) => acc + parseInt(transaction.treesOrdered),
										0,
									) || totalTrees
								}
								startDate={
									viewingBusiness ? new Date(viewingBusiness.created_at) : undefined
								}
							/>
							<Status
								color={'#17a98f'}
								icon={Up}
								label='Net New Businesses'
								business={totalBusinesses - lostBusinesses}
							/>
							<Status
								color={'#feae66'}
								icon={Plus}
								label='Businesses Added'
								business={totalBusinesses}
							/>
							<Status
								color={'#feae66'}
								icon={Minus}
								label='Businesses Lost'
								business={lostBusinesses}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		</AdminLayout>
	);
};

export default Dashboard;
