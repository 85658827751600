import {
	AtomicBlockUtils,
	CompositeDecorator,
	Editor,
	EditorState,
	RichUtils,
} from 'draft-js';
import React, { useRef, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { LinkEntity, linkStrategy } from './Entities/LinkEntity';
import { mediaBlockRenderer } from './Entities/MediaEntity';
import {
	Bold,
	Headers,
	Italic,
	Link,
	Media,
	OrderedList,
	UnorderedList,
} from './Toolbar';

export const createDecorators = () =>
	new CompositeDecorator([{ component: LinkEntity, strategy: linkStrategy }]);

const RichEditor = ({ editorState, onChange, projectId }) => {
	const ref = useRef();

	const [lastHeading, setLastHeading] = useState('');
	const _toggleHeadingStyle = () => {
		if (RichUtils.getCurrentBlockType(editorState) === 'unstyled') {
			onChange(RichUtils.toggleBlockType(editorState, lastHeading));
		} else {
			onChange(RichUtils.toggleBlockType(editorState, 'unstyled'));
		}
	};

	const handleKeyCommand = (command) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			onChange(newState);
			return 'handled';
		}
		return 'not-handled';
	};

	const getActiveStyle = (style) =>
		editorState.getCurrentInlineStyle().has(style);

	const getActiveBlockType = (style) =>
		RichUtils.getCurrentBlockType(editorState) === style;

	const getHeadingsActiveState = [
		'header-one',
		'header-two',
		'header-three',
		'header-four',
		'header-five',
		'header-six',
	].some((h) => RichUtils.getCurrentBlockType(editorState) === h);

	const _onHeadingClick = (size) => {
		setLastHeading(size);
		onChange(RichUtils.toggleBlockType(editorState, size));
	};
	const _onBoldClick = (e) => {
		onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
	};
	const _onItalicClick = (e) => {
		onChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
	};
	const _onUlClick = (e) => {
		onChange(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
	};
	const _onOlClick = (e) => {
		onChange(RichUtils.toggleBlockType(editorState, 'ordered-list-item'));
	};

	const _onAddLink = (url) => {
		const selection = editorState.getSelection();
		const content = editorState.getCurrentContent();
		const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
			url: 'https://' + url,
		});
		const newEditorState = EditorState.push(
			editorState,
			contentWithEntity,
			'create-entity',
		);
		const entityKey = contentWithEntity.getLastCreatedEntityKey();
		onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
	};

	const _onAddImage = (url) => {
		const contentState = editorState.getCurrentContent();
		const contentStateWithEntity = contentState.createEntity(
			'image',
			'IMMUTABLE',
			{ src: url },
		);
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const newEditorState = EditorState.set(
			editorState,
			{ currentContent: contentStateWithEntity },
			'create-entity',
		);
		onChange(() => {
			setTimeout(() => ref.current.focus(), 0);
			return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
		});
	};

	const Toolbar = (
		<div className='DraftEditor-toolbar'>
			<ButtonGroup className='rounded-0' size='sm'>
				<Headers
					isActive={getHeadingsActiveState}
					onToggle={_toggleHeadingStyle}
					activeHeader={getActiveBlockType}
					onHeaderClick={_onHeadingClick}
				/>
				<Bold isActive={getActiveStyle} onClick={_onBoldClick} />
				<Italic isActive={getActiveStyle} onClick={_onItalicClick} />
				<UnorderedList isActive={getActiveBlockType} onClick={_onUlClick} />
				<OrderedList isActive={getActiveBlockType} onClick={_onOlClick} />
				<Link onClick={_onAddLink} />
				<Media onClick={_onAddImage} projectId={projectId} />
			</ButtonGroup>
		</div>
	);

	return (
		<>
			{Toolbar}
			<Editor
				ref={ref}
				blockRendererFn={mediaBlockRenderer}
				handleKeyCommand={handleKeyCommand}
				editorState={editorState}
				onChange={onChange}
			/>
		</>
	);
};

export default RichEditor;
