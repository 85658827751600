import { useContext, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useBetween } from 'use-between';

import SettingsLayout from '../layout';
import { DownloadIcon } from '../../../../components/icons';
import useClient from '../../useClient';
import Smartlook from 'smartlook-client';
import PaymentMethod from '../../../../components/PaymentMethod';

import DeactivateAccount from '../deactivateAccount';
import withErrorHandling from '../../../../components/withErrorHandling';
import './index.scss';
import { Ecodrive } from '../../../../context/store';

const Billing = () => {
	const [treesPerTransaction, setTreesPerTransaction] = useState(1);
	const [paymentBrand, setPaymentBrand] = useState('visa');
	const [paymentLastFour, setPaymentLastFour] = useState('1234');
	const [paymentExpMonth, setPaymentExpMonth] = useState('06');
	const [paymentExpYear, setPaymentExpYear] = useState('2027');
	const [businessEmail, setBusinessEmail] = useState(
		'placeholderemail@gmail.com',
	);
	const [pricePerTree, setPricePerTree] = useState(1);
	const [invoiceArray, setInvoiceArray] = useState([]);

	const {
		getStripeInfo,
		getIntegrationByBusinessId,
		getActiveProject,
		activeProject,
		isSubscriptionActive,
	} = useBetween(useClient);
	const [{ business, user }] = useContext(Ecodrive);

	useEffect(() => {
		if ('id' in business) {
			getActiveProject(business.id);
			setBusinessEmail(business.email);
		}
	}, [getActiveProject, business]);

	useEffect(() => {
		setPricePerTree(parseFloat(activeProject.price));
	}, [activeProject]);

	useEffect(() => {
		if (business.id) {
			getIntegrationByBusinessId(business.id).then((data) => {
				if (!data.length) return;
				if (data.length === 1) {
					setTreesPerTransaction(data[0].trees_per_transaction.toFixed(2));
				} else {
					let shopifyIntegration = data.find((el) => el.integration === 'shopify');
					setTreesPerTransaction(
						shopifyIntegration.trees_per_transaction.toFixed(2),
					);
				}
			});
		}
	}, [business.id, getIntegrationByBusinessId]);

	useEffect(() => {
		getStripeInfo(user.id).then((data) => {
			setInvoiceArray(data.invoices);
			setPaymentBrand(data.activeCard.brand);
			setPaymentLastFour(data.activeCard.last4);
			setPaymentExpMonth(data.activeCard.exp_month);
			setPaymentExpYear(data.activeCard.exp_year);

			Smartlook.identify(user.id);
		});
	}, [user.id, business.id, getStripeInfo]);

	return (
		<SettingsLayout title='Billing'>
			<div className='billing-container'>
				<h1>Billing</h1>
				<h6>Update and manage your billing info</h6>
				<div className='billing-info'>
					<div className='current-plan'>
						<h2>Current Plan</h2>
						<h6>How we charge you</h6>
						<span
							className={[
								'status',
								isSubscriptionActive === 'true' ? '' : 'status--off',
							].join(' ')}
						>
							{isSubscriptionActive === 'true' ? 'Active' : 'Off'}
						</span>
						<div className='plans'>
							<div className='item'>
								<span>$25</span>
								<em>per month</em>
							</div>
							<div className='item'>
								<span>${(treesPerTransaction * pricePerTree).toFixed(2)}</span>
								<em>per action</em>
							</div>
						</div>
						{isSubscriptionActive === 'true' && (
							<div className='actions'>
								<Button>
									{/* <BreakHeart /> */}
									<span>
										Need to pause your plan? <br />
										Please contact support@ecodrive.community
									</span>
								</Button>
							</div>
						)}
					</div>
					<PaymentMethod
						paymentBrand={paymentBrand}
						paymentLastFour={paymentLastFour}
						paymentExpMonth={paymentExpMonth}
						paymentExpYear={paymentExpYear}
					/>
				</div>
				{invoiceArray.length ? (
					<div className='invoice-payments'>
						<h2>Invoice and Payments</h2>
						<h6>See your recent charges</h6>
						<div className='invoice-payments-table'>
							<Table striped bordered>
								<thead>
									<tr>
										<th>INVOICE #</th>
										<th>DATE</th>
										<th>STATUS</th>
										<th>
											{/* invoiceArray.length ? <a href="#"><DownloadIcon />DOWNLOAD ALL</a> : null*/}
										</th>
									</tr>
								</thead>
								<tbody>
									{invoiceArray.map((invoice) => {
										return (
											<tr>
												<td>{invoice.number}</td>
												<td>{new Date(invoice.created * 1000).toDateString()}</td>
												<td>
													<span
														className={invoice.status === 'paid' ? 'status paid' : 'status'}
													>
														{invoice.status.toUpperCase()}
													</span>
												</td>
												<td>
													<a href={invoice.invoice_pdf}>
														<DownloadIcon />
														<span>Download</span>
													</a>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
					</div>
				) : null}
				<DeactivateAccount />
			</div>
		</SettingsLayout>
	);
};

export default withErrorHandling(Billing);
