import { useQuery } from 'react-query';
import { z } from 'zod';

import { ecodriveApi } from '../libs/axios';

const meSchema = z.object({
	id: z.string(),
	email: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	phone: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	verified: z.boolean().nullish(),
	businessId: z.string().nullish(),
	business_id: z.string().nullish(),
});

export type Me = z.infer<typeof meSchema>;

const fetch = async () => {
	const { data } = await ecodriveApi.get('/users/me');

	const parsed = meSchema.parse(data);

	return parsed;
};

export const useGetMe = () => {
	const { data, ...rest } = useQuery(['getMe'], fetch);

	return {
		me: data,
		...rest,
	};
};
