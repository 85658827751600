/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useContext } from 'react';
import {
	Container,
	Row,
	Col,
	Button,
	Form,
	Overlay,
	Tooltip,
} from 'react-bootstrap';
import { useBetween } from 'use-between';
import Card from 'react-credit-cards-2';

import useAuth from '../../auth/useAuth';
import useClient from '../useClient';
import './index.scss';
import Header from '../../../components/header';
import Upload from '../../../components/upload';
import Trees from '../../../components/trees';
import { toast } from 'react-toastify';
import {
	formatCreditCardNumber,
	formatCVC,
	formatExpirationDate,
} from '../../../plugins';
import { Tree } from '../../../components/icons';
import { Ecodrive } from '../../../context/store';

const Setting = () => {
	const { getUser, putUser } = useBetween(useAuth);
	const { tpo, changeCard, getBusiness, putBusiness } = useBetween(useClient);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [milestones] = useState([100, 1000, 10000]);
	const [billingFirstName, setBillingFirstName] = useState('');
	const [billingLastName, setBillingLastName] = useState('');
	const [billingEmail, setBillingEmail] = useState('');
	const [billingPhone, setBillingPhone] = useState('');
	const [billingBusiness, setBillingBusiness] = useState('');
	const [billingBusinessLegal, setBillingBusinessLegal] = useState('');
	const [billingAddress1, setBillingAddress1] = useState('');
	const [billingAddress2, setBillingAddress2] = useState('');
	const [billingCity, setBillingCity] = useState('');
	const [billingState, setBillingState] = useState('');
	const [logo, setLogo] = useState('');
	const [billingZip, setBillingZip] = useState('');
	const [treesPerOrder, setTreesPerOrder] = useState(1);
	const [cardNumber, setCardNumber] = useState('');
	const [brandSubText, setBrandSubText] = useState(
		'Positive impact on your environment',
	);
	const [brandText, setBrandText] = useState('The Call of The Forest');
	const [expDate, setExpDate] = useState('');
	const [cvv, setCvv] = useState('');
	const [focused, setFocused] = useState('');
	const [, setStatus] = useState(false);
	const [logoUpload, setLogoUpload] = useState(false);
	const [limit, setLimit] = useState(0);
	const [subLimit, setSubLimit] = useState(0);
	const [, setBillingStatus] = useState(true);
	const [publicUrl, setPublicUrl] = useState('');
	const [copied, setCopied] = useState(false);
	const [url, setUrl] = useState('');
	const target = useRef(null);

	const [{ user, business }] = useContext(Ecodrive);

	const fetchUser = () => getUser(user.id);
	const fetchBusiness = () => getBusiness({ user_id: user.id });

	const updateUser = () => {
		const formData = new FormData();
		formData.append('email', email);
		formData.append('firstName', firstName);
		formData.append('lastName', lastName);
		formData.append('phone', phone);
		formData.append('userRole', 0);

		putUser(user.id, formData).then(() => {
			toast.success('User profile updated successfully!');
		});
	};

	const updateBusiness = (msg) => {
		const formData = new FormData();
		formData.append('user_id', user.id);
		formData.append('first_name', billingFirstName);
		formData.append('last_name', billingLastName);
		formData.append('business_name', billingBusiness);
		formData.append('business_legal_name', billingBusinessLegal);
		formData.append('logo_text', brandText);
		formData.append('sub_logo_text', brandSubText);
		formData.append('email', billingEmail);
		formData.append('phone_number', billingPhone);
		formData.append('address1', billingAddress1);
		formData.append('address2', billingAddress2);
		formData.append('city', billingCity);
		formData.append('state', billingState);
		formData.append('zipcode', billingZip);
		formData.append('card_number', business.card_number);
		formData.append('expiry_date', business.expiry_date);
		formData.append('cvv', business.cvv);
		formData.append('shop_url', business.shop_url);
		formData.append('logo_image', logo);
		formData.append('url', business.url);
		formData.append('milestones', JSON.stringify(milestones));
		formData.append('status', 'Active');
		formData.append('shop_name', business.shop_name);
		formData.append('business_id', business.business_id);
		formData.append('register_platform', business.register_platform);
		formData.append('custom_planted_trees', business.custom_planted_trees);

		putBusiness(formData).then(() => {
			if (msg) {
				toast.success(msg);
			} else {
				toast.success('Billing information updated successfully!');
			}
		});
	};

	const incTree = () => {
		updateTrees(treesPerOrder + 1);
		setTreesPerOrder(treesPerOrder + 1);
	};

	const decTree = () => {
		updateTrees(treesPerOrder - 1);
		setTreesPerOrder(treesPerOrder - 1);
	};

	const updateTrees = (trees) => {
		const formData = new FormData();
		formData.append('user_id', user.id);
		formData.append('business_id', business.id);
		formData.append('trees_per_order', trees);

		// TODO: sync on updates to where/when we calculate total trees planted
		// updateTPO(formData)
		//   .then((response) => {
		//     toast.success(`Update successful! Trees Per Order set to ${response.trees_per_order}`);
		//   })
	};

	const copyUrl = () => {
		navigator.clipboard.writeText(publicUrl);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	const handleInputChange = (event) => {
		if (event.target.name === 'number') {
			event.target.value = formatCreditCardNumber(event.target.value);
			setCardNumber(event.target.value);
		} else if (event.target.name === 'expiry') {
			event.target.value = formatExpirationDate(event.target.value);
			setExpDate(event.target.value);
		} else if (event.target.name === 'cvc') {
			event.target.value = formatCVC(event.target.value, cardNumber);
			setCvv(event.target.value);
		}
	};

	const updateCard = () => {
		const formData = new FormData();
		formData.append('business_id', business.id);
		formData.append('card_number', cardNumber.replaceAll(' ', ''));
		formData.append('cvv', cvv);
		formData.append('expiry_date', expDate);

		changeCard(formData)
			.then((response) => {
				toast.success(`Card infomation updated successfully!`);
			})
			.catch((error) => {
				toast.error(`Failed to update the card Information`);
			});
	};

	useEffect(() => {
		fetchUser();
		fetchBusiness();
	}, []);

	useEffect(() => {
		if (Object.keys(business).length > 0) {
			setBillingFirstName(business.first_name);
			setBillingLastName(business.last_name);
			setBillingEmail(business.email);
			setBillingPhone(business.phone_number);
			setBillingBusiness(business.business_name ? business.business_name : '');
			setBillingBusinessLegal(
				business.business_legal_name ? business.business_legal_name : '',
			);
			setBillingAddress1(business.address1);
			setBillingAddress2(business.address2);
			setBillingCity(business.city);
			setBillingState(business.state);
			setBillingZip(business.zipcode);
			setBrandText(
				business.logo_text ? business.logo_text : 'The Call of The Forest',
			);
			setBrandSubText(
				business.sub_logo_text
					? business.sub_logo_text
					: 'Positive impact on your environment',
			);
			business.status === 'Active'
				? setBillingStatus(true)
				: setBillingStatus(false);
			setLogo(business.logo_image);
			setCardNumber(formatCreditCardNumber(`${business.card_number}`));
			setExpDate(business.expiry_date);
			setCvv(business.cvv);
			setUrl(business.url);
			setPublicUrl(
				`${document.location.origin}/public-dashboard/${business.shop_name}`,
			);
		}

		if (Object.keys(user).length > 0) {
			setFirstName(user.first_name);
			setLastName(user.last_name);
			setEmail(user.email);
			setPhone(user.phone);
		}

		setTreesPerOrder(tpo);
	}, [business, user, tpo]);

	useEffect(() => {
		setLimit(brandText.length);
	}, [brandText]);

	useEffect(() => {
		setSubLimit(brandSubText.length);
	}, [brandSubText]);

	return (
		<div className='setting-container'>
			<div className='banner' />
			<Header showLinks={true} />
			<Container className='setting-info'>
				{!logoUpload && (
					<div className='setting-content'>
						<h1>Settings</h1>
						<p>
							Update your profile, billing information, logo and tress planted here. Be
							sure to save!
						</p>

						{Object.keys(business).length > 0 &&
							business.register_platform === 'shopify' && (
								<Row className='mt-5'>
									<h2>Copy And Share!</h2>
									<a
										className='public-url mb-2'
										ref={target}
										onClick={() => {
											copyUrl();
										}}
									>
										{publicUrl}
									</a>
									<p>Please click the above link to copy to the clipboard!</p>
									<Overlay target={target.current} show={copied} placement='top'>
										{(props) => (
											<Tooltip id='overlay-example' {...props}>
												Copied
											</Tooltip>
										)}
									</Overlay>
								</Row>
							)}

						<Row className='mt-5'>
							<h2 className='mb-5'>Profile</h2>
							<Col>
								<Row>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>First Name</Form.Label>
											<Form.Control
												value={firstName}
												onChange={(event) => setFirstName(event.target.value)}
												placeholder='Your First Name'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Last Name</Form.Label>
											<Form.Control
												value={lastName}
												onChange={(event) => setLastName(event.target.value)}
												placeholder='Your Last Name'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type='email'
												value={email}
												onChange={(event) => setEmail(event.target.value)}
												placeholder='Your Email'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Phone</Form.Label>
											<Form.Control
												value={phone}
												onChange={(event) => setPhone(event.target.value)}
												placeholder='Your Phone'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12} className='action-buttons'>
										<Button className='button button-primary' onClick={() => fetchUser()}>
											Cancel
										</Button>
										<Button
											className='button button-secondary'
											onClick={() => updateUser()}
										>
											Save
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className='mt-5'>
							<h2 className='mb-4'>Edit Your Website Url</h2>
							<Col>
								<Row>
									<Col sm={12} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Control
												value={url}
												onChange={(event) => setUrl(event.target.value)}
												placeholder='Website url'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12} className='action-buttons'>
										<Button className='button button-primary' onClick={() => fetchUser()}>
											Cancel
										</Button>
										<Button
											className='button button-secondary'
											onClick={() => updateUser()}
										>
											Save
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>

						{/* <Row className="mt-5">
            <Col xs={12}>
              <Milestones data={milestones} setData={setMilestones} />
            </Col>
            <Col xs={12} className="action-buttons mt-3">
              <Button className="button button-primary" onClick={() => fetchBusiness()}>Cancel</Button>
              <Button className="button button-secondary" onClick={() => updateBusiness()}>Save</Button>
            </Col>
          </Row> */}

						<Row className='mt-5 justify-content-center'>
							<Col sm={6} xs={12} className='mb-5'>
								<h2 className='mb-5'>Edit Your Logo</h2>
								<Upload
									setLogo={setLogo}
									logo={logo}
									setStatus={setStatus}
									setLogoUpload={setLogoUpload}
								/>
							</Col>
							{Object.keys(business).length > 0 &&
								business.register_platform === 'shopify' && (
									<Col sm={6} xs={12} className='mb-5'>
										<h2 className='mb-5'>Update Trees Per Order</h2>
										<Trees
											count={treesPerOrder}
											setCount={setTreesPerOrder}
											plus={incTree}
											minus={decTree}
										/>
									</Col>
								)}
						</Row>
						<Row className='mt-5'>
							<h2 className='mb-5'>Edit your brand announcement</h2>
							<Col xs={12}>
								<Form.Group className='mb-3 input-limit'>
									<Form.Label>Main message</Form.Label>
									<Form.Control
										value={brandText}
										onChange={(event) => setBrandText(event.target.value)}
										maxLength={50}
										required
									/>
									<Form.Label className='limit-label'>{limit} / 50</Form.Label>
								</Form.Group>
								<Form.Group className='mb-3 input-limit'>
									<Form.Label>Sub message</Form.Label>
									<Form.Control
										as='textarea'
										rows={3}
										value={brandSubText}
										onChange={(event) => setBrandSubText(event.target.value)}
										placeholder='Your Brand Announcement'
										maxLength={100}
										required
									/>
									<Form.Label className='limit-label'>{subLimit} / 100</Form.Label>
								</Form.Group>
							</Col>
							<Col xs={12} className='action-buttons mt-4'>
								<Button
									className='button button-primary'
									onClick={() => fetchBusiness()}
								>
									Cancel
								</Button>
								<Button
									className='button button-secondary'
									onClick={() => updateBusiness('Dash messaging updated')}
								>
									Save
								</Button>
							</Col>
						</Row>

						<Row className='mt-5'>
							<h2 className='mb-5'>Edit your card info</h2>
							<Col>
								<Card
									number={cardNumber || ''}
									expiry={expDate || ''}
									cvc={cvv}
									name={''}
									focused={focused}
									className='card'
								/>
								<Row>
									<Col>
										<Form.Group className='mb-3'>
											<Form.Label>Credit Card Number</Form.Label>
											<Form.Control
												type='tel'
												name='number'
												pattern='[\d| ]{16,22}'
												onChange={(event) => {
													handleInputChange(event);
												}}
												onFocus={(event) => {
													setFocused(event.target.name);
												}}
												placeholder='Your Credit Card Number'
												value={cardNumber}
												required
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Expire Date</Form.Label>
											<Form.Control
												type='tel'
												name='expiry'
												pattern='\d\d/\d\d'
												onChange={(event) => {
													handleInputChange(event);
												}}
												onFocus={(event) => {
													setFocused(event.target.name);
												}}
												placeholder='MM/YY'
												value={expDate}
												autoComplete='cc-exp'
												maxLength={5}
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>CVV</Form.Label>
											<Form.Control
												type='tel'
												name='cvc'
												pattern='\d{3,4}'
												onChange={(event) => {
													handleInputChange(event);
												}}
												onFocus={(event) => {
													setFocused(event.target.name);
												}}
												placeholder='Your CVV'
												value={cvv}
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12} className='action-buttons mt-4'>
										<Button
											className='button button-primary'
											onClick={() => fetchBusiness()}
										>
											Cancel
										</Button>
										<Button
											className='button button-secondary'
											onClick={() => updateCard()}
										>
											Save
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className='mt-5'>
							<h2 className='mb-5'>Edit your billing info</h2>
							<Col>
								<Row>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>First Name</Form.Label>
											<Form.Control
												value={billingFirstName}
												onChange={(event) => setBillingFirstName(event.target.value)}
												placeholder='Your First Name'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Last Name</Form.Label>
											<Form.Control
												value={billingLastName}
												onChange={(event) => setBillingLastName(event.target.value)}
												placeholder='Your Last Name'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type='email'
												value={billingEmail}
												onChange={(event) => setBillingEmail(event.target.value)}
												placeholder='Your Email'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Phone</Form.Label>
											<Form.Control
												value={billingPhone}
												onChange={(event) => setBillingPhone(event.target.value)}
												placeholder='Your Phone'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Business Name</Form.Label>
											<Form.Control
												value={billingBusiness}
												onChange={(event) => setBillingBusiness(event.target.value)}
												placeholder='Business Name'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Business Legal Name</Form.Label>
											<Form.Control
												value={billingBusinessLegal}
												onChange={(event) => setBillingBusinessLegal(event.target.value)}
												placeholder='Business Legal Name'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Address Line 1</Form.Label>
											<Form.Control
												value={billingAddress1}
												onChange={(event) => setBillingAddress1(event.target.value)}
												placeholder='Your Address Line 1'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Address Line 2</Form.Label>
											<Form.Control
												value={billingAddress2}
												onChange={(event) => setBillingAddress2(event.target.value)}
												placeholder='Your Address Line 2'
											/>
										</Form.Group>
									</Col>
									<Col sm={6} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>City</Form.Label>
											<Form.Control
												value={billingCity}
												onChange={(event) => setBillingCity(event.target.value)}
												placeholder='Your City'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={3} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>State</Form.Label>
											<Form.Control
												value={billingState}
												onChange={(event) => setBillingState(event.target.value)}
												placeholder='Your State'
												required
											/>
										</Form.Group>
									</Col>
									<Col sm={3} xs={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Zipcode</Form.Label>
											<Form.Control
												value={billingZip}
												onChange={(event) => setBillingZip(event.target.value)}
												placeholder='Your Zipcode'
												required
											/>
										</Form.Group>
									</Col>
									<Col xs={12} className='action-buttons mt-4'>
										<Button
											className='button button-primary'
											onClick={() => fetchBusiness()}
										>
											Cancel
										</Button>
										<Button
											className='button button-secondary'
											onClick={() => updateBusiness()}
										>
											Save
										</Button>
									</Col>
								</Row>
								{/*
                <Row className="mt-5">
                  <Col sm={12} xs={12}>
                    <h2>
                      Monthly Billing Receipts: {billingStatus ? 'On' : 'Off'}
                    </h2>
                    <p>To pause or cancel subscription, please contact <a className='email-link' href="mailto:support@ecodrive.community">support@ecodrive.community</a> for assistance</p>
                  </Col>
                </Row>
            */}
							</Col>
						</Row>
					</div>
				)}
				{logoUpload && (
					<>
						<div className='content-header'>
							{logoUpload && logoUpload ? (
								<span>Uploading...</span>
							) : (
								<span>Finishing Up...</span>
							)}
						</div>
						<p>Please do not refresh the page</p>

						<div className='connecting'>
							<div className='connecting-wrapper'>
								<div className='connecting-content'>
									<Tree />
								</div>
							</div>
							<div className='connecting-loader' />
						</div>
					</>
				)}
			</Container>
		</div>
	);
};

export default Setting;
