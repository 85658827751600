import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const TreeStats = ({ projects, active }) => {
	if (!active) return null;
	const isActiveButHasntPlanted =
		projects.findIndex((proj) => proj.projectAssigned.id === active.id) < 0;

	return (
		<Container className='Cient-Dashboard__cream-container'>
			<Row className='g-3 justify-content-center'>
				{isActiveButHasntPlanted && (
					<Col
						xs={12}
						md={6}
						lg={4}
						key={active.projectKey}
						className='d-flex align-items-center justify-content-center my-2'
					>
						{active.silhouette && (
							<img width={80} height={80} src={active.silhouette} alt={active.title} />
						)}
						<div className='ms-4 d-flex flex-column justify-content-between'>
							<span className='fs-3'>{active.title}</span>
							<span>0 planted</span>
						</div>
					</Col>
				)}
				{projects.map(({ projectAssigned: p, treesOrdered }) => (
					<Col
						xs={12}
						md={6}
						lg={4}
						key={p.projectKey}
						className='d-flex align-items-center justify-content-center my-2'
					>
						{p.silhouette && (
							<img width={80} height={80} src={p.silhouette} alt={p.title} />
						)}
						<div className='ms-4 d-flex flex-column justify-content-between'>
							<span className='fs-3'>{p.title}</span>
							<span>{Intl.NumberFormat().format(treesOrdered)} planted</span>
						</div>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default TreeStats;
