import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../../../components/header/index';
import NPSDisplay from '../../../../components/NPS/Admin/NPSDisplay';
import NPSBreakdown from '../../../../components/NPS/Admin/NPSBreakdown';
import NPSTrends from '../../../../components/NPS/Admin/NPSTrends';
import NPSComments from '../../../../components/NPS/Admin/NPSComments';
import './NPSPage.scss';
import { AdminLayout } from '../../../../lauyouts';

const NPSPage = () => {
	const npsHeadingStyle = {
		marginBottom: '3rem',
		marginTop: '2rem',
		fontSize: '40px',
		fontWeight: 'bold',
		color: '#464A4F',
		justifyContent: 'center',
	};

	return (
		<AdminLayout>
			<div className='nps-page'>
				<div className='nps-container'>
					<div className='banner' />
					<Header showLinks={true} />
				</div>
				<div className='pt-5 nps-info'>
					<Container>
						<Row>
							<Col md={12}>
								<h2 style={npsHeadingStyle}>NPS Dashboard</h2>
							</Col>
						</Row>
						<Row className='mb-3 center-cols'>
							<Col md={5} className='col-container'>
								<NPSDisplay />
							</Col>
							<Col md={6} className='col-container'>
								<NPSBreakdown />
							</Col>
						</Row>

						<Row className='mb-3'>
							<Col md={12} className='col-container'>
								<NPSTrends />
							</Col>
						</Row>
						<Row>
							<Col md={12} className='col-container'>
								<NPSComments />
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</AdminLayout>
	);
};

export default NPSPage;
