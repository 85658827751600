import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { ecodriveApi } from '../../libs/axios';
import { toast } from 'react-toastify';

const defaultHelper = 'Upload an image or paste a URL';

const MediaUpload = ({
	onChange,
	width,
	value,
	uploadUrl,
	setFormData,
	fit = 'cover',
	accept = 'image/png, image/gif, image/jpeg',
}) => {
	const lastMod = '?lastMod=' + new Date().toISOString();
	const [loading, setLoading] = useState(false);

	const [helper, setHelper] = useState({
		message: defaultHelper,
		error: false,
	});

	const handleError = (err) => {
		setTimeout(() => {
			setHelper({
				message: defaultHelper,
				error: false,
			});
		}, 1200);
		setHelper({
			message: err.message,
			error: true,
		});
	};

	const handleFileUpload = () => {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', accept);
		input.onchange = async function (e) {
			setLoading(true);
			const formData = new FormData();
			formData.set('file', e.target.files[0]);
			if (setFormData) {
				setFormData(formData);
			}
			try {
				const { data } = await ecodriveApi.post(uploadUrl, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
				onChange(data.Location);
				toast.success('Content uploaded successfully');
			} catch (error) {
				handleError(error);
			}
			setLoading(false);
			input.remove();
		};

		input.click();
	};

	return (
		<Card style={{ width }}>
			{value && (
				<Card.Img
					width={'100%'}
					height={210}
					variant='top'
					style={{ objectFit: fit }}
					src={value + lastMod}
				/>
			)}
			<Card.Body>
				<Form.Group>
					<Form.Control
						className='p-2'
						value={value}
						onChange={(e) => onChange(e.target.value)}
						type='text'
					/>
					<Form.Text className={helper.error && 'text-danger'}>
						{helper.message}
					</Form.Text>
				</Form.Group>
			</Card.Body>
			<Card.Footer className='d-grid'>
				<Button
					disabled={loading}
					variant={'secondary'}
					size='sm'
					onClick={(e) => {
						handleFileUpload();
					}}
				>
					Upload <i className='bi bi-cloud-arrow-up'></i>
				</Button>
			</Card.Footer>
		</Card>
	);
};

export default MediaUpload;
