import "./index.scss";
import { numberWithCommas } from "../../../../plugins";

function getMonthDifference(startDate, endDate) {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}

const Statistics = ({ orders, trees, startDate = new Date("2022-05-22") }) => {
  let amountOfMonthsBusinessHas = getMonthDifference(startDate, new Date());

  return (
    <div className="dashboard-card statistic-card">
      <p className="card-title">Tree Statistics</p>
      <div className="statistic-wrapper">
        <p>Total Trees: {numberWithCommas(trees)}</p>
        <p>LBS of Co2: {numberWithCommas(trees * 680)}</p>
        <p>Work Days Created: {numberWithCommas(trees / 10)}</p>
        <p>Total Orders: {numberWithCommas(orders)}</p>
        <p>
          Avg Trees/Month:{" "}
          {numberWithCommas(Math.floor(trees / amountOfMonthsBusinessHas))}
        </p>
      </div>
    </div>
  );
};

export default Statistics;
