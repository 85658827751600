import './index.scss';
import { numberWithCommas } from '../../../../plugins';

const Status = ({ color, icon, label, business }) => {

  return (
    <div className="dashboard-card cstatus-card" style={{ background: `${color}` }}>
      <img src={icon} alt="icon" />
      <div>
        <label>{label}</label>
        <p>{numberWithCommas(business)}</p>
      </div>
    </div>
  )
}

export default Status;