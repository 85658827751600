import React from 'react'
import ReactMarkdown from 'react-markdown';

import './index.scss'

const ProjectCard = (props) => {
  let projectCardClass = 'project-card';
  if (props.disabled) {
    projectCardClass += ' project-card--disabled';
  }

  return (
    <div className={projectCardClass} onClick={props.disabled ? () => null : props.onClick}>
      <div className='project-card__top-container'>
        <div
          className='project-card__thumbnail-container'
          style={{backgroundImage: `url("${props.img}")`}}
        >
        </div>
        <div className='project-card__text-box'>
          <div className='project-card__platformName-container'>
            <span id="project-platformName" className={`project-card__platformName`}>
              {props.platformName}
            </span>
            <div className='project-card__platformTextBox'>
            <span className="project-card__platformText"><ReactMarkdown>{props.copy}</ReactMarkdown></span>
            </div>
          </div>
        </div>
      </div>

      <div className='project-card__header-container'>
        <span>{props.disabled ? 'Inventory Unavailable' : 'Learn More'}</span>
      </div>
    </div>
  );
};

export default ProjectCard;
