import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { BUSINESS_ENUM } from '../actions/business.actions';

const string = Yup.string().nullable();
const email = Yup.string().email();

const subscriptionValidator = Yup.object({
	subscriptionId: string,
	customerId: string,
	active: Yup.boolean(),
});

const billingValidator = Yup.object({
	address1: string.required(),
	address2: string,
	city: string.required(),
	state: string.required(),
	country: string.required(),
	zip: string.required(),
	email: email.required(),
});

const businessValidator = Yup.object({
	id: string.required(),
	businessName: string.required(),
	businessLegalName: string.required(),
	companySite: string,
	projectId: string.required().nullable(),
	project: string.nullable(),
	step: Yup.string().oneOf(['payment', 'content']).nullable(),
});

const BusinessReducer = (state, action) => {
	let business;
	const { type, ...next } = action;
	try {
		switch (action.type) {
			case BUSINESS_ENUM.FILL_BUSINESS:
				business = businessValidator
					.concat(subscriptionValidator)
					.concat(billingValidator)
					.validateSync(next, {
						stripUnknown: true,
					});

				return {
					id: business.id,
					name: business.businessName,
					legalName: business.businessLegalName,
					email: business.email,
					companySite: business.companySite,
					billing: {
						address1: business.address1,
						address2: business.address2,
						city: business.city,
						state: business.state,
						country: business.country,
						zip: business.zip,
					},
					subscription: {
						id: business.subscriptionId,
						customer: business.customerId,
						active: Boolean(business.active || business.subscriptionId),
					},
					project: {
						id: business.projectId,
						key: business.project,
					},
					step: business.step,
				};
			case BUSINESS_ENUM.FILL_BUSINESS_PUBLIC:
				business = businessValidator.validateSync(next, {
					stripUnknown: true,
				});

				return {
					id: business.id,
					name: business.businessName,
					legalName: business.businessLegalName,
					email: business.email,
					subscription: {
						active: Boolean(business.active || business.subscriptionId),
					},
					project: {
						id: business.projectId,
						key: business.project,
					},
				};

			case BUSINESS_ENUM.CLEAR:
				return {};
			default:
				throw new Error('Unknown action: ' + action.type);
		}
	} catch (e) {
		toast.error('[Business Store] ' + e.message, { toastId: 'business' });
		return state;
	}
};

export default BusinessReducer;
