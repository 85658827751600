import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useBetween } from 'use-between';
import { toast } from 'react-toastify';
import useClient from '../../pages/client/useClient';
import useAuth from '../../pages/auth/useAuth';
import { ecodriveApi } from '../../libs/axios';
import './ShopifyUrlForm.scss';

const ShopifyUrlForm = ({ onConnect }) => {
	const location = useLocation();
	const { getIntegrationByBusinessId } = useBetween(useClient);
	const { businessId, userId, integrationId, setIntegrationId } =
		useBetween(useAuth);

	const [url, setUrl] = useState('');
	const [connecting, setConnecting] = useState(false);
	const [existingShopifyDomain, setExistingShopifyDomain] = useState(null);
	const fetchShopifyIntegration = () => {
		return getIntegrationByBusinessId(businessId).then((data) => {
			let shopifyIntegration = data.find(
				(el) => el.type === 'order' && el.integration === 'shopify',
			);

			if (shopifyIntegration) {
				setIntegrationId(shopifyIntegration.integration_id);
				let splitShopifyDomain = shopifyIntegration.shopUrl.split('.myshopify.');

				setExistingShopifyDomain(splitShopifyDomain[0]);
			}
		});
	};

	const handleConnect = async ({ shop, code }) => {
		setConnecting(true);

		await ecodriveApi
			.get(
				`/connect?code=${code}&shop=${shop}&userId=${userId}&businessId=${businessId}&integration=shopify`,
				{
					headers: {
						authorization: 'Bearer ' + localStorage.getItem('authorization'),
					},
				},
			)
			.then((response) => {
				setIntegrationId(response.data.integration_id);
				localStorage.setItem('integration_id', response.data.integration_id);
				let splitShopifyDomain = shop.split('.myshopify.');

				setExistingShopifyDomain(splitShopifyDomain[0]);
				toast.success('Shopify Integration has been updated');
			})
			.catch((error) => {
				console.log(error);
			});

		setConnecting(false);
	};

	useEffect(() => {
		fetchShopifyIntegration();
	}, []);

	useEffect(() => {
		if (location.search) {
			const searchParams = new URLSearchParams(location.search);
			const code = searchParams.get('code');
			const shop = searchParams.get('shop');

			if (code && shop) {
				handleConnect({ code, shop });
			}
		}
	}, [location]);

	useEffect(() => {
		if (integrationId) {
			localStorage.setItem('integration_id', integrationId);
		}
	}, [integrationId]);

	const handleSubmit = (event) => {
		event.preventDefault();

		window.location.replace(
			`https://${url}.myshopify.com/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_KEY}&scope=${process.env.REACT_APP_SHOPIFY_SCOPE}&redirect_uri=${window.location.origin}/settings/integrations`,
		);
	};

	return (
		<div className='shopify-url-form'>
			{!connecting ? (
				<>
					<Form onSubmit={handleSubmit}>
						<Row>
							<Col>
								<Form.Group className='mb-3'>
									<Form.Label>Shopify URL</Form.Label>
									<InputGroup className='mb-3'>
										<InputGroup.Text>https://</InputGroup.Text>
										<Form.Control
											value={existingShopifyDomain || url}
											onChange={(event) => setUrl(event.target.value)}
											placeholder='store-name'
											required
											disabled={!!existingShopifyDomain}
										/>
										<InputGroup.Text>.myshopify.com</InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className='action-buttons'>
								{existingShopifyDomain || integrationId || !url ? null : (
									<Button
										type='submit'
										className='button button-secondary full-width-button'
									>
										Connect
									</Button>
								)}
							</Col>
						</Row>
					</Form>
				</>
			) : (
				<>
					<div className='content-header'>
						<span>Connecting...</span>
					</div>
					<p>Please do not refresh the page</p>
				</>
			)}
		</div>
	);
};

export default ShopifyUrlForm;
