import React, { useState, useEffect } from 'react';
import './NPSDisplay.scss';
import { useBetween } from 'use-between';
import useClient from '../../../pages/client/useClient';
import { calculateNPS } from '../calculateNPS';
import NPSGauge from './NPSGauge';

const NPSDisplay = () => {
	const [npsScore, setNpsScore] = useState(null);
	const { getNPSData } = useBetween(useClient);

	useEffect(() => {
		const fetchNPSData = async () => {
			let data = await getNPSData();
			data = data.filter((item) => item.completed);
			const score = calculateNPS(data);
			setNpsScore(score);
		};

		fetchNPSData();
	}, [getNPSData]);

	return (
		<div className='nps-score-container'>
			<div className='gauge-chart-container'>
				<NPSGauge npsScore={npsScore} />
				<div className='score-container'>
					<h2 className='pt-4 score'>
						Score: {npsScore !== null ? npsScore.toFixed(2) : 'Calculating...'}
					</h2>
				</div>
			</div>
		</div>
	);
};

export default NPSDisplay;
