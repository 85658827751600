import { useCallback, useEffect, useState } from 'react';
import { ecodriveApi } from '../libs/axios';

const useFetch = (path, _options = { method: 'GET' }) => {
	const [data, setData] = useState();
	const [error, setError] = useState();

	const memoizedOptions = JSON.stringify(_options);

	const request = useCallback(async () => {
		const options = JSON.parse(memoizedOptions);
		if (path) {
			try {
				const res = await ecodriveApi.request({
					url: path,
					method: options.method,
					data: options.body,
					...options,
				});
				setData(res.data);
			} catch (error) {
				setError(error);
			}
		}
	}, [path, memoizedOptions]);

	useEffect(() => {
		request();
		return function () {
			if (_options.reset) {
				setData();
				setError();
			}
		};
	}, [request, _options.reset]);

	if (_options.method === 'GET' && 'body' in _options)
		throw new Error('GET requests must not contain body');

	return {
		error,
		data,
	};
};

export default useFetch;
