import Smartlook from 'smartlook-client';
export const trackNetworkError = ({ url, data = {}, error, requestType }) => {
	if (data instanceof FormData) {
		let dataClone = {};
		data.forEach((value, key) => (dataClone[key] = value));
		data = dataClone;
	}

	const errorDetail = {
		requestUrl: url,
		requestData: JSON.stringify(data),
		error: error?.message ? error.message : JSON.stringify(error),
		requestType,
		allcombined: `${requestType} ${url} ${JSON.stringify(data)}`,
	};
	Smartlook.error(JSON.stringify(errorDetail));
};
