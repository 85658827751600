import { useContext, useEffect, useState } from 'react';
import SettingsLayout from '../layout';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useBetween } from 'use-between';
import { toast } from 'react-toastify';
import withErrorHandling from '../../../../components/withErrorHandling';
import {
	BarChart,
	SplineChart,
	SmallBarChart,
} from '../../../../components/icons';
import useClient from '../../useClient';
import { numberWithCommas } from '../../../../plugins';

import './index.scss';
import PlacesDropdown from '../../../../components/placesDropdown';
import {
	addressesInitialState,
	useAddressState,
} from '../../../../hooks/useAddressState';
import useHandleAddressComponents from '../../../../hooks/useHandleAddressComponents';
import usePlacesApi from '../../../../hooks/usePlacesApi';
import Smartlook from 'smartlook-client';
import { Ecodrive } from '../../../../context/store';
import useFetch from '../../../../hooks/useFetch';
import { BUSINESS_ENUM } from '../../../../context/store/actions/business.actions';

const Profile = () => {
	const { putBusiness } = useBetween(useClient);
	const [addresses, setAddress, handleAddressChange] = useAddressState();
	const [email, setEmail] = useState('');
	const [businessName, setBusinessName] = useState('');
	const [businessLegalName, setBusinessLegalName] = useState('');
	const [companySite, setCompanySite] = useState('');
	const [, setActive] = useState(1);
	const [treesPlanted, setTreesPlanted] = useState(0);

	const [{ user, business }, dispatch] = useContext(Ecodrive);

	useEffect(() => {
		Smartlook.identify(user.profile.id);
		if (Object.keys(business).length) {
			setEmail(business.email);
			setBusinessName(business.name);
			setBusinessLegalName(business.legalName);
			setAddress((prev) => ({
				address1: business.billing.address1,
				address2: business.billing.address2,
				city: business.billing.city,
				state: business.billing.state,
				zip: business.billing.zip,
				country: business.billing.country,
			}));
			setActive(business.subscription.active);

			setCompanySite(business.companySite);
		}
	}, [setAddress, user, business]);

	const { data: transactions } = useFetch(
		business.id && `/transactions/${business.id}/business`,
	);

	useEffect(() => {
		if (transactions) {
			setTreesPlanted(
				transactions.reduce((acc, tct) => acc + parseInt(tct.treesOrdered), 0),
			);
		}
	}, [transactions]);

	const updateContent = async () => {
		const formData = new FormData();
		formData.append('businessLegalName', businessLegalName);
		formData.append('businessName', businessName);
		formData.append('email', email);
		formData.append('address1', addresses.address1);
		formData.append('address2', addresses.address2);
		formData.append('city', addresses.city);
		formData.append('state', addresses.state);
		formData.append('zip', addresses.zip);
		formData.append('country', addresses.country);
		formData.append('companySite', companySite);

		await putBusiness(formData).then((data) => {
			dispatch(BUSINESS_ENUM.FILL_BUSINESS, data);
		});
		toast.success(`Content Updated!`);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		updateContent();
	};

	const [places, handlePlaceSelect, getPlacesProps] = usePlacesApi();
	const [countries, handleCountrySelect, , handleCountryChange] =
		usePlacesApi('country');
	const [states, handleStateSelect, , handleStateChange] = usePlacesApi(
		'administrative_area_level_1',
	);
	const [cities, handleCitySelect, , handleCityChange] =
		usePlacesApi('locality');
	const handleAddressComponents = useHandleAddressComponents(setAddress);

	return (
		<SettingsLayout title='Business Profile'>
			<div className='profile-container'>
				<Row>
					<Col md={7} sm={12}>
						<h1>Your Business Profile</h1>
						<h6>Edit all of your business information here.</h6>
						<Form onSubmit={(event) => handleSubmit(event)}>
							<Row>
								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>
										Business Legal Name
									</Form.Label>
									<Form.Control
										type='text'
										value={businessLegalName}
										required
										onChange={(event) => setBusinessLegalName(event.target.value)}
										className='profile-item-input'
									/>
								</Col>

								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>Business Name</Form.Label>
									<Form.Control
										type='text'
										value={businessName}
										required
										onChange={(event) => setBusinessName(event.target.value)}
										className='profile-item-input'
									/>
								</Col>
								<Col sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>Company Website</Form.Label>
									<Form.Control
										type='url'
										required
										value={companySite}
										onChange={(event) => {
											let value = event.target.value;
											const prependText = 'https://';
											if (value === prependText) {
												value = '';
											} else if (!value.startsWith(prependText)) {
												value = prependText + value;
											} else if (value.startsWith('https://https://')) {
												value = value.substring(8);
											}
											setCompanySite(value);
										}}
										className='profile-item-input'
										placeholder='yourwebsite.com/'
									/>
								</Col>

								<Col sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>
										Search your address
									</Form.Label>
									<Form.Control
										type='text'
										{...getPlacesProps()}
										className='profile-item-input'
									/>
									<PlacesDropdown
										places={places}
										onSelect={(place) => {
											setAddress(addressesInitialState);
											handlePlaceSelect(place.place_id, handleAddressComponents);
										}}
									/>
								</Col>
								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>Address Line 1</Form.Label>
									<Form.Control
										type='text'
										value={addresses.address1}
										required
										onChange={handleAddressChange('address1')}
										className='profile-item-input'
									/>
								</Col>
								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>Address Line 2</Form.Label>
									<Form.Control
										type='text'
										value={addresses.address2}
										onChange={handleAddressChange('address2')}
										className='profile-item-input'
									/>
								</Col>
								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>City</Form.Label>
									<Form.Control
										type='text'
										value={addresses.city}
										onChange={(ev) => {
											const setCity = handleAddressChange('city');
											setCity(ev);
											handleCityChange(ev);
										}}
										required
										className='profile-item-input'
									/>
									<PlacesDropdown
										places={cities}
										onSelect={(place) =>
											handleCitySelect(place.place_id, handleAddressComponents)
										}
									/>
								</Col>
								<Col md={6} sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>
										State or Province
									</Form.Label>
									<Form.Control
										type='text'
										value={addresses.state}
										onChange={(ev) => {
											const setState = handleAddressChange('state');
											setState(ev);
											handleStateChange(ev);
										}}
										required
										className='profile-item-input'
									/>
									<PlacesDropdown
										places={states}
										onSelect={(place) =>
											handleStateSelect(place.place_id, handleAddressComponents)
										}
									/>
								</Col>
								<Col sm={12} className='profile-col'>
									<Form.Label className='profile-item-label'>Country</Form.Label>
									<Form.Control
										type='text'
										value={addresses.country}
										onChange={(ev) => {
											const setCountry = handleAddressChange('country');
											setCountry(ev);
											handleCountryChange(ev);
										}}
										required
										className='profile-item-input'
									/>
									<PlacesDropdown
										places={countries}
										onSelect={(place) =>
											handleCountrySelect(place.place_id, handleAddressComponents)
										}
									/>
								</Col>
							</Row>

							<Row>
								<Col sm={12}>
									<Button type='submit' className='button-submit full-width my-3'>
										Save Changes
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>

					<Col md={1}></Col>

					<Col md={4} sm={12}>
						<h1 className='statistic-label text-center mb-3'>Your Tree Statistics</h1>
						<div className='statistic-item'>
							<Row>
								<Col xs={6} sm={6} md={6}>
									<p className='statistic-item-label'>Lifetime Lbs of CO2 Removed</p>
									<p className='statistic-item-value'>
										{numberWithCommas(treesPlanted * 680)}
									</p>
								</Col>
								<Col xs={6} sm={6} md={6} className=''>
									<div className='statistic-item-chart'>
										<BarChart />
									</div>
								</Col>
							</Row>
						</div>
						<div className='statistic-item'>
							<Row>
								<Col xs={6} sm={6} md={6}>
									<p className='statistic-item-label'>Lifetime Trees Planted</p>
									<p className='statistic-item-value'>
										{numberWithCommas(treesPlanted)}
									</p>
								</Col>
								<Col xs={6} sm={6} md={6}>
									<div className='statistic-item-chart'>
										<SplineChart />
									</div>
								</Col>
							</Row>
						</div>
						<div className='statistic-item'>
							<Row>
								<Col xs={3} sm={2} md={2} className='d-flex align-item-center'>
									<div className='statistic-item-chart'>
										<SmallBarChart />
									</div>
								</Col>
								<Col xs={9} sm={10} md={10}>
									<p className='statistic-item-label'>Work Days Created</p>
									<p className='statistic-item-value'>
										{numberWithCommas(treesPlanted / 100)}
									</p>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</div>
		</SettingsLayout>
	);
};
export default withErrorHandling(Profile);
