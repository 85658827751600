import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import './CustomTable.scss';

const ActionRow = ({ actionName, actionCount, onAddCount, onSubCount }) => {
	return (
		<tr className='custom-table__row'>
			<td
				className={`custom-table__action-name ${
					actionCount === 0 ? 'greyed-out' : ''
				}`}
			>
				<strong>Plant Per {actionName}</strong>
			</td>
			<td className='custom-table__content'>
				<Button
					variant='info'
					onClick={() => {
						onSubCount(actionName);
					}}
					className={`custom-table__circle-button ${
						actionCount === 0 ? 'greyed-out' : ''
					}`}
					disabled={actionCount === 0}
				>
					-
				</Button>
				<span
					className={`custom-table__trees-actionCount ${
						actionCount === 0 ? 'greyed-out' : ''
					}`}
				>
					<strong>{actionCount}</strong>
				</span>
				<Button
					variant='info'
					onClick={() => {
						onAddCount(actionName);
					}}
					className='custom-table__circle-button'
				>
					+
				</Button>
			</td>
		</tr>
	);
};

const CustomTable = ({ actions, setActions }) => {
	const handleAdd = (actionName) => {
		setActions((actions) =>
			actions.map((action) => {
				if (action.actionName === actionName) {
					return {
						...action,
						actionCount: action.actionCount + 1,
					};
				} else {
					return {
						...action,
					};
				}
			}),
		);
	};

	const handleSub = (actionName) => {
		setActions((actions) =>
			actions.map((action) => {
				if (action.actionName === actionName) {
					return {
						...action,
						actionCount: action.actionCount - 1,
					};
				} else {
					return {
						...action,
					};
				}
			}),
		);
	};

	return (
		<div>
			<Table className='custom-table'>
				<thead>
					<tr>
						<th>Action</th>
						<th className='custom-table__header--centered-bold'>Count</th>
					</tr>
				</thead>
				<tbody>
					{actions.map(({ actionName, actionCount }, index) => (
						<ActionRow
							key={index}
							actionName={actionName}
							actionCount={actionCount}
							onAddCount={() => {
								handleAdd(actionName);
							}}
							onSubCount={() => {
								handleSub(actionName);
							}}
						/>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default CustomTable;
