import React, { useEffect, useState } from 'react';
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

const GenerateSVG = (props) => {
	const { businessId } = props;
	const [customOptions, setCustomOptions] = useState({
		carbon: false,
		days: false,
		dark: false,
		transparent: false,
	});
	const [query, setQuery] = useState('?');
	const [copied, setCopied] = useState(false);

	const fullQuery = process.env.REACT_APP_LAMBDA_GETSVG + "?businessId=" + businessId + "&" + query;
	
	const anchor =
		window.location.protocol +
		'//' +
		window.location.host +
		'/public-dashboard/' +
		businessId;

	const handleCheckboxChange = (prop) => {
		setCustomOptions((prev) => {
			const additionalChanges = {};
			if (prop === 'dark' && !prev[prop]) {
				additionalChanges.transparent = false;
			}
			return {
				...prev,
				...additionalChanges,
				[prop]: !prev[prop],
			};
		});
	};

	const handleCopiedState = () => {
		setCopied(true);
		navigator.clipboard.writeText(`
<a href="${anchor}">
  <img src="${fullQuery}" alt="Ecodrive Badge" />
</a>
    `);
		setTimeout(() => {
			setCopied(false);
		}, 1300);
	};

  useEffect(() => {
    const { dark, days, carbon, transparent } = customOptions;
    const searchParams = new URLSearchParams();
    searchParams.set("variant", dark ? "dark" : "light");
    searchParams.set("days", days);
    searchParams.set("carbon", carbon);
    searchParams.set("transparent", transparent);
    setQuery(searchParams.toString());
  }, [customOptions]);

	return (
		<Card className='bg-forest-green-2 overflow-hidden'>
			<Row>
				<Col xs={12} lg={6}>
					<div className='p-4'>
						<h4 className='mb-4'>With Real-Time Data</h4>
						<Form.Check
							disabled={customOptions.dark}
							checked={customOptions.transparent}
							onChange={() => handleCheckboxChange('transparent')}
							type='switch'
							label={<h4>Transparent Background</h4>}
						/>
						<Form.Check
							checked={customOptions.dark}
							onChange={() => handleCheckboxChange('dark')}
							type='switch'
							label={<h4>Dark Mode</h4>}
						/>
						<Form.Check
							checked={customOptions.carbon}
							onChange={() => handleCheckboxChange('carbon')}
							type='switch'
							label={<h4>Lbs of Carbon Removed</h4>}
						/>
						<Form.Check
							checked={customOptions.days}
							onChange={() => handleCheckboxChange('days')}
							type='switch'
							label={<h4>Work Days Created</h4>}
						/>
						<p className='mb-0'>
							Copy and paste this to your website{' '}
							<OverlayTrigger show={copied} overlay={<Tooltip>Text Copied</Tooltip>}>
								<i
									onClick={handleCopiedState}
									style={{ cursor: 'pointer' }}
									className={'bi bi-clipboard' + (copied ? '-check' : '')}
								/>
							</OverlayTrigger>
						</p>
					</div>
				</Col>
				<Col xs={12} lg={6} className='bg-light p-5'>
					<div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
						<img width={300} src={fullQuery} alt='' />
					</div>
				</Col>
			</Row>
		</Card>
	);
};

export default GenerateSVG;
