const env = {
	google: {
		placesApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
	},
	api: {
		host: process.env.REACT_APP_API_ENDPOINT,
	},
} as const;

export { env };
