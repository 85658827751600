import { useState } from "react";

import SettingsLayout from "../layout"
import Milestones from "../../../../components/milestones";
import Trees from "../../../../components/trees";

import "./index.scss";
import { Button, Col, Row } from "react-bootstrap";

const TreesTemplate = () => {
  const [count, setCount] = useState(1);
  const [milestones, setMilestones] = useState([]);

  const description = "Select the three (3) tree planting milestones you want to display on your dashboard."

  const plus = () => {
    setCount(count + 1);
  }

  const minus = () => {
    setCount(count - 1);
  }

  return (
    <SettingsLayout title="Trees">
      <div className="trees-container">
        <Milestones data={milestones} setData={setMilestones} description={description} />
        <h1>Trees Per Action</h1>
        <h6>Update how many trees you want to plant per action</h6>
        <Trees count={count} setCount={setCount} plus={plus} minus={minus} />

        <Row className="mb-4">
          <Col sm={12}>
            <Button type="button" className="button-save full-width my-3">Save</Button>
          </Col>
        </Row>
      </div>
    </SettingsLayout>
  )
}

export default TreesTemplate;
