import React from 'react';
import { Image } from 'react-bootstrap';

const MediaEntity = (props) => {
	const entity = props.contentState.getEntity(props.block.getEntityAt(0));
	const { src } = entity.getData();
	const type = entity.getType();

	if (type === 'image') {
		return <Image width={'100%'} src={src} />;
	}
	return <></>;
};

const mediaBlockRenderer = (block) => {
	if (block.getType() === 'atomic') {
		return {
			component: MediaEntity,
			editable: false,
		};
	}
	return null;
};

export { mediaBlockRenderer };
