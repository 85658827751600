import React, { useCallback, useEffect, useState } from 'react';
import { useBetween } from 'use-between';
import useClient from '../../pages/client/useClient';
import AddNewProject from './AddNewProject';
import RenderProject from './RenderProject';
import EditProjectModal from './EditProjectModal';
import HandeDeleteModal from './HandeDeleteModal';

const Projects = () => {
	const { getProjects } = useBetween(useClient);
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [editing, setEditing] = useState('');
	const [forDeletion, setDeletionId] = useState('');

	const retrieveProjects = useCallback(() => {
		getProjects({ filtered: false })
			.then(setProjects)
			.finally(() => setLoading(false));
	}, [getProjects]);

	useEffect(() => {
		retrieveProjects();
	}, [retrieveProjects]);

	return (
		<div className='clients-container'>
			<div className='client-row row-header'>
				<span className='row-name'>Project Key</span>
				<span className='row-value'>Inventory</span>
				<span className='row-value'>Price</span>
			</div>
			{loading && (
				<div className='client-row'>
					<span>Loading projects, please wait...</span>
				</div>
			)}
			<EditProjectModal
				onUpdate={retrieveProjects}
				project={editing}
				onClose={() => setEditing('')}
			/>
			<HandeDeleteModal
				forDeletion={forDeletion}
				onClose={() => setDeletionId('')}
			/>
			{projects
				.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
				.map((project) => (
					<RenderProject
						onDelete={() => {
							setDeletionId(project.projectKey);
						}}
						{...project}
						onEdit={() => setEditing(project.id)}
					/>
				))}
			{!loading && (
				<AddNewProject
					onSubmit={() => {
						getProjects().then(setProjects);
					}}
				/>
			)}
		</div>
	);
};

export default Projects;
