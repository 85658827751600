import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { useBetween } from 'use-between';
import { toast } from 'react-toastify';

import SettingsLayout from '../layout';
import { Link, HeadingText } from '../../../../components/icons';
import Upload from '../../../../components/upload';
import DashPreview from '../../../../components/dashpreview';
import useClient from '../../useClient';
import Smartlook from 'smartlook-client';
import withErrorHandling from '../../../../components/withErrorHandling';

import './index.scss';
import { Ecodrive } from '../../../../context/store';
import useFetch from '../../../../hooks/useFetch';

const prependText = 'https://';

const PublicDash = () => {
	const { putContent } = useBetween(useClient);
	const [{ user, business }] = useContext(Ecodrive);

	const [logo, setLogo] = useState('');
	// TODO: what is this?
	const [, setStatus] = useState(false);
	// TODO: was this ever hooked up? -__-
	const [, setLogoUpload] = useState(false);
	const [navText, setNavText] = useState('Back to site ->');
	const [logoLink, setLogoLink] = useState('');
	const [headerText, setHeaderText] = useState('');
	const [subText, setSubText] = useState('');

	const updateContent = async () => {
		await putContent({
			navText: navText,
			logoLink: logoLink,
			headerText: headerText,
			subText: subText,
			logoImage: logo,
		});
		toast.success(`Content Updated!`);
	};
	const { data: content } = useFetch(business.id && `/content/${business.id}`);

	useEffect(() => {
		if (content) {
			setLogo(content.logoImage || '');
			setNavText(content.navText || '');
			setLogoLink(content.logoLink || '');
			setHeaderText(content.headerText || '');
			setSubText(content.subText || '');
		}
	}, [content]);

	useEffect(() => {
		if (user.profile?.id) {
			Smartlook.identify(user.profile.id);
		}
	}, [user.profile?.id]);

	const handleSubmit = (event) => {
		event.preventDefault();
		updateContent();
	};

	return (
		<SettingsLayout title='Public Dash'>
			<div className='publicdash-container'>
				<h1>Public Dashboard</h1>

				<h6>
					Adjust what your businesses will see when they visit your public tree
					planting dashboard.
				</h6>

				<Form onSubmit={(event) => handleSubmit(event)}>
					<Row>
						<Col md={6} sm={12}>
							<Form.Group className='pubdash-form mb-3'>
								<Form.Label>Logo</Form.Label>
								<Upload
									businessId={business.id}
									setLogo={setLogo}
									logo={logo}
									setStatus={setStatus}
									setLogoUpload={setLogoUpload}
								/>
								<Button className='logo-button' type='submit'>
									Save
								</Button>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={12} sm={12}>
							<Form.Group className='pubdash-form mb-3'>
								<Form.Label>Logo Link</Form.Label>
								<InputGroup>
									<InputGroup.Text>
										<Link />
									</InputGroup.Text>
									<Form.Control
										required
										type='text'
										value={logoLink}
										placeholder='yourwebsite.com'
										onChange={(event) => {
											let value = event.target.value;
											if (value === prependText) {
												value = '';
											} else if (!value.startsWith(prependText)) {
												value = prependText + value;
											} else if (value.startsWith('https://https://')) {
												value = value.substring(8);
											}
											setLogoLink(value);
										}}
									/>
									<Button type='submit'>Save</Button>
								</InputGroup>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} sm={12}>
							<Form.Group className='pubdash-form mb-3'>
								<Form.Label>Header Text</Form.Label>
								<InputGroup>
									<InputGroup.Text>
										<HeadingText />
									</InputGroup.Text>
									<Form.Control
										required
										type='text'
										value={headerText}
										onChange={(event) => setHeaderText(event.target.value)}
									/>
									<Button type='submit'>Save</Button>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col md={6} sm={12}>
							<Form.Group className='pubdash-form mb-3'>
								<Form.Label>Sub Text</Form.Label>
								<InputGroup>
									<InputGroup.Text>
										<HeadingText />
									</InputGroup.Text>
									<Form.Control
										required
										type='text'
										value={subText}
										onChange={(event) => setSubText(event.target.value)}
									/>
									<Button type='submit'>Save</Button>
								</InputGroup>
							</Form.Group>
						</Col>
					</Row>
				</Form>
				<div className='dash-preview-container mt-3'>
					<h2 className='mb-0'>Dashboard Preview:</h2>
					<DashPreview
						navText={navText}
						logo={logo}
						logoLink={logoLink}
						headerText={headerText}
						subText={subText}
					/>
				</div>
			</div>
		</SettingsLayout>
	);
};

export default withErrorHandling(PublicDash);
