import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";

const el = document.createElement("div");
/**
 * Custom hook to connect any input to
 * Google Cloud Places API
 */
const usePlacesApi = (type) => {
  const [places, setPlaces] = useState([]);
  const [value, setValue] = useState("");

  /**
   * API Call function
   * Debounced for performance
   */
  const debouncedPlacesSearch = useRef(
    debounce((keywords) => {
      const service = new window.google.maps.places.PlacesService(el);
      service.textSearch(
        {
          query: keywords,
          language: "en",
          type,
        },
        (res) => {
          setPlaces(res ?? []);
        }
      );
    }, 300)
  ).current;

  /**
   * Cleanup debounce call when component
   * unmounts
   */
  useEffect(() => {
    return () => {
      debouncedPlacesSearch.cancel();
    };
  }, [debouncedPlacesSearch]);

  /**
   * Handle Input onChange
   */
  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    debouncedPlacesSearch(value);
  };

  const handleSelect = async (placeId, callback) => {
    try {
      const service = new window.google.maps.places.PlacesService(el);
      service.getDetails({ placeId, language: "en" }, (res) => {
        const results = res;
        setValue(results.formatted_address);
        setPlaces([]);
        if (typeof callback === "function") {
          callback(results);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return [
    // Get Props function
    places,
    handleSelect,
    () => ({
      onChange: handleChange,
      value,
    }),
    handleChange,
  ];
};

export default usePlacesApi;
