export const formatPhoneNumber = (phone) => {
	let cleanPhone = phone.replace(/\D/g, '');

	return cleanPhone;
};

export const protoPhoneMask = [
	'(',
	/[1-9]/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];

export const phoneMask = (rawValue = '') => {
	const length = rawValue.replace(/\D/g, '').length;
	if (length < 11) {
		return protoPhoneMask;
	} else if (length === 11) {
		return ['+', /\d/, ' ', ...protoPhoneMask];
	} else if (length > 11) {
		return ['+', /\d/, /\d/, ' ', ...protoPhoneMask];
	}
};

export const creditCardMask = [
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];
