/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React from "react";
import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import { css } from '@emotion/react';

import './index.scss'

const ActiveProjectCard = ({ projectName, img, header, text, isActive, additionalText, onClick = () => null }) => {
  return (
    <Box
      css={css`display: flex;
      flex-direction: column;
      background-color: rgb(250, 250, 250);
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 360px;
      margin-bottom: 30px;
      overflow: hidden;
      position: relative;
      margin-top: 30px;
      
      &:hover {
        cursor: pointer;
      }`}
      onClick={onClick}>
      <Box
        css={css`font-size: 32px;
          font-weight: bold;
          color: #77cfc6;
          padding-top: 8px;
          padding-left: 30px;`}
      >
        {projectName}
      </Box>
      {isActive ? (
        <Box
          css={css`position: absolute;
          top: 19px;
          right: 30px;
          padding: 5px 10px;
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          border-radius: 5px;
          background-color: #77cfc6;
          color: #ffffff;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.75px;
          padding: 4px 15px 1px 15px;
          position: absolute;
          right: 26px;
          text-transform: uppercase;
          top: 20px;
          border-radius: 20px;`}
        >Active</Box>
      ) : (
        <Box className="active-project-card__badge active-project-card__badge--inactive">Inactive</Box>
      )}
      <Box className="active-project-card__image-container">
        <img className="active-project-card__image" src={img} alt={projectName} />
      </Box>
      <Box className="active-project-card__content">
        <Box className="active-project-card__text-container">
          <h3 className="active-project-card__header">{header}</h3>
          <p className="active-project-card__text"><ReactMarkdown>{text}</ReactMarkdown></p>
          <p className="active-project-card__additional-text">{additionalText}</p>
          <Box className="active-project-card__button-container">
            <button className={`active-project-card__button ${isActive ? "active-project-card__button--active" : "active-project-card__button--inactive"} custom-button-class`}>
              Explore More
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveProjectCard;
