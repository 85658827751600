import React, { useState, useEffect } from 'react';
import useClient from '../../pages/client/useClient';
import { toast } from 'react-toastify';
import { useBetween } from 'use-between';
import {
	Button,
	Select,
	MenuItem,
	TextField,
	FormControl,
	InputLabel,
	ThemeProvider,
	Grid,
	Typography,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	CardMedia,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import './CustomPlanting.scss';
import { settingsTheme } from '../../theme/theme';

const CustomPlanting = ({ businessId, closeButtonCallback }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [selectedProject, setSelectedProject] = useState('');
	const [treeCount, setTreeCount] = useState('');
	const [confirmed, setConfirmed] = useState(false);
	const { getBusiness, getProjects, postCustomTrees } = useBetween(useClient);
	const [plantingProjects, setPlantingProjects] = useState([]);

	useEffect(() => {
		const fetchProjects = async () => {
			const projects = await getProjects({ filtered: true });
			if (!plantingProjects.length) {
				setPlantingProjects(projects);
			}
		};
		fetchProjects();
	}, [getProjects, plantingProjects.length]);

	const createImpact = (event) => {
		event.preventDefault();

		if (!treeCount || treeCount <= 0 || !selectedProject) {
			toast.error(
				'Please specify both the project and a valid impact number (greater than 0).',
			);
			return;
		}

		const selectedProjectDetails = plantingProjects.find(
			(project) => project.id === selectedProject,
		);

		if (!selectedProjectDetails) {
			toast.error('Selected project not found.');
			return;
		}

		const projectKey = selectedProjectDetails.projectKey;
		const total = (treeCount * selectedProjectPrice).toString();

		const customImpactData = {
			trees: treeCount,
			type: 'custom',
			projectKey: projectKey,
		};

		postCustomTrees(customImpactData)
			.then((response) => {
				toast.success(
					`${treeCount} ${response.message || 'successfully submitted'}`,
				);
				getBusiness(businessId);
				closeButtonCallback();
			})
			.catch((err) => {
				console.error(err);
				toast.error('Failed to create impact');
			});
	};

	const handleProjectChange = (event) => {
		setSelectedProject(event.target.value);
	};

	const handleTreeCountChange = (event) => {
		let value = parseInt(event.target.value, 10);

		if (isNaN(value)) {
			setTreeCount('');
		} else if (value < 0) {
			setTreeCount('');
		} else {
			setTreeCount(value);
		}
	};

	const handleConfirmChange = (event) => {
		setConfirmed(event.target.checked);
	};

	const handleSubmit = () => {
		if (!confirmed) {
			alert('Please confirm your decision before submitting.');
			return;
		}

		createImpact(new Event('submit'));
	};

	const selectedProjectDetails = plantingProjects.find(
		(project) => project.id === selectedProject,
	);
	const selectedProjectPrice = selectedProjectDetails
		? selectedProjectDetails.price
		: 0;

	return (
		<ThemeProvider theme={settingsTheme}>
			<Card>
				<CardContent>
					<Grid
						container
						spacing={4}
						direction='column'
						alignItems='center'
						justifyContent='center'
					>
						<Grid item xs={12}>
							<Typography variant={isMobile ? 'h4' : 'h5'} gutterBottom>
								Custom Impact
							</Typography>

							<Typography
								variant={isMobile ? 'h3' : 'body2'}
								color='textSecondary'
								gutterBottom
							>
								Select a project and the amount of impact to implement.
							</Typography>
						</Grid>

						<Grid item container xs={12} sm={8} md={6} spacing={2}>
							<Grid item xs={12} sm={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel id='project-select-label'>Planting Project</InputLabel>
									<Select
										labelId='project-select-label'
										value={selectedProject}
										onChange={handleProjectChange}
										label='Planting Project'
									>
										{plantingProjects.map((project) => (
											<MenuItem value={project.id} key={project.id}>
												{project.title}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									variant='outlined'
									type='number'
									label='Number of Trees'
									value={treeCount}
									inputProps={{ min: '0', max: '5000' }}
									onChange={handleTreeCountChange}
									fullWidth
								/>
							</Grid>
						</Grid>

						<Grid
							item
							container
							xs={12}
							sm={8}
							md={6}
							spacing={2}
							alignItems='center'
						>
							<Grid item xs={12} sm={7}>
								<FormControlLabel
									control={
										<Checkbox checked={confirmed} onChange={handleConfirmChange} />
									}
									label={
										<Typography variant={isMobile ? 'h3' : 'body2'} color='textSecondary'>
											I agree to planting {treeCount} trees which will cost $
											{(treeCount * selectedProjectPrice).toFixed(2)}.
										</Typography>
									}
								/>
							</Grid>
							<Grid item xs={12} sm={5}>
								<Button
									variant='contained'
									color='primary'
									onClick={handleSubmit}
									fullWidth
									disabled={!confirmed || treeCount <= 0}
								>
									SUBMIT
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{!isMobile && selectedProjectDetails && (
				<Card style={{ marginTop: '20px' }}>
					<Grid container>
						<Grid item xs={12} sm={4}>
							<CardMedia
								style={{ height: 150, width: '100%' }}
								image={selectedProjectDetails.silhouette}
								title={selectedProjectDetails.title}
							/>
						</Grid>
						<Grid item xs={12} sm={8}>
							<CardContent>
								<Grid container spacing={3} style={{ justifyContent: 'space-between' }}>
									<Grid item>
										<Typography variant='h5' gutterBottom>
											{selectedProjectDetails.title}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant='body2' style={{ fontStyle: 'italic' }}>
											Price:{' '}
											{Number(selectedProjectDetails.price).toLocaleString('en-US', {
												style: 'currency',
												currency: 'USD',
											})}
										</Typography>
									</Grid>
								</Grid>
								<Typography
									variant='body2'
									color='textSecondary'
									component='p'
									style={{ fontSize: '0.65rem' }}
								>
									{selectedProjectDetails.preview}
								</Typography>
							</CardContent>
						</Grid>
					</Grid>
				</Card>
			)}
		</ThemeProvider>
	);
};

export default CustomPlanting;
