import { useState } from "react";

const addressesInitialState = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
};

const useAddressState = (address) => {
  const [addresses, setAddress] = useState({
    ...addressesInitialState,
    ...address,
  });

  const handleChange = (property) => (event) => {
    const { value } = event.target;

    setAddress((prev) => ({
      ...prev,
      [property]: value,
    }));
  };

  return [addresses, setAddress, handleChange];
};

export { addressesInitialState, useAddressState };
