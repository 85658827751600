const checkIfAddress = (component, keys, cb) => {
  if (component.types.some((v) => keys.includes(v))) {
    cb();
  }
};

const useHandleAddressComponents = (setAddress) => {
  const handleAddressComponents = ({ address_components = [] }) => {
    setAddress((prev) => {
      const returnObj = { ...prev };
      address_components.forEach((cmp) => {
        checkIfAddress(cmp, ["street_number"], () => {
          const addr = returnObj.address1.split(",");
          addr[0] = cmp.long_name;
          returnObj.address1 = addr.join(", ");
        });
        checkIfAddress(cmp, ["route"], () => {
          const addr = returnObj.address1.split(",");
          addr[1] = cmp.long_name;
          returnObj.address1 = addr.join(", ");
        });

        checkIfAddress(cmp, ["locality", "postal_town"], () => {
          returnObj.city = cmp.long_name;
        });
        checkIfAddress(cmp, ["administrative_area_level_1"], () => {
          returnObj.state = cmp.long_name;
        });
        checkIfAddress(cmp, ["country"], () => {
          returnObj.country = cmp.short_name;
        });
        checkIfAddress(cmp, ["postal_code"], () => {
          returnObj.zip = cmp.long_name;
        });
      });
      return returnObj;
    });
  };

  return handleAddressComponents;
};

export default useHandleAddressComponents;
