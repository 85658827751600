import uuid from 'draft-js/lib/uuid';
import { useRef } from 'react';

const useId = () => {
	const { current } = useRef(uuid());

	return current;
};

export default useId;
