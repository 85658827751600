import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import Modal from '../../../../components/modal/Modal';
import {
	PlatformIntegration,
	Integration,
	GetZapTemplate,
	GetZap,
} from '../../../../hooks/integrations';
import CustomTable from './CustomTable';
import ShopifyUrlForm from '../../../../components/integrationCard/ShopifyUrlForm';
import { CustomPlanting } from '../../../../components/CustomPlanting';
import {
	usePostIntegration,
	useUpdateIntegration,
} from '../../../../hooks/integrations';
import { ZapEditorModal } from './ZapEditorModal';
import SuccessModal from '../../../../components/modal/SuccessModal';

interface ZapierIntegrationModalProps {
	template: GetZapTemplate;
	integration?: Integration;
	zap?: GetZap;
	isOpen: boolean;
	onCloseModal: () => void;
	businessId: string;
}

export const ZapierIntegrationModal: React.FC<ZapierIntegrationModalProps> = ({
	template,
	integration,
	zap,
	isOpen,
	onCloseModal,
	businessId,
}) => {
	const queryClient = useQueryClient();
	const postIntegration = usePostIntegration();
	const updateIntegration = useUpdateIntegration();
	const { createUrl, description, events, logo, title, slug, platform } =
		template;
	const isActive = integration?.status === 'Active';
	const buttonText = integration ? 'Edit Integration' : 'Activate Integration';

	const [zapEditorUrl, setZapEditorUrl] = useState<string | undefined>();
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
	const [actions, setActions] = useState<
		ReadonlyArray<{
			actionName: string;
			actionCount: number;
		}>
	>(
		events.map((label) => ({
			actionName: label,
			actionCount: integration?.treesPerTransaction ?? 0,
		})),
	);

	const isLoading =
		postIntegration.isLoading ||
		updateIntegration.isLoading ||
		Boolean(zapEditorUrl);

	const treesPerTransaction = actions[0]?.actionCount ?? 0;
	const isButtonDisabled =
		isLoading || (treesPerTransaction === 0 && integration?.status !== 'Active');

	const handleOnClick = async () => {
		const action = actions[0];
		const treesPerTransaction = action?.actionCount ?? 0;

		if (integration) {
			const status = treesPerTransaction > 0 ? 'Active' : 'Paused';
			await updateIntegration.mutateAsync({
				integrationId: integration.id,
				treesPerTransaction,
				status,
			});

			if (!zap) {
				setZapEditorUrl(template.createUrl);
			} else if (zap.state === 'off') {
				setZapEditorUrl(zap.url);
			}
		} else {
			await postIntegration.mutateAsync({
				businessId,
				integration: slug,
				project: 'KEN',
				type: 'zap',
				treesPerTransaction,
			});

			setZapEditorUrl(createUrl);
		}
	};

	const handleZapEditorOnClose = () => {
		queryClient.invalidateQueries(['getBusinessIntegrations']);

		setZapEditorUrl(undefined);
		setIsSuccessModalOpen(true);
	};

	const handleZapEditorOnEvent = () => {
		handleZapEditorOnClose();
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				onCloseModal={onCloseModal}
				description={description}
				image={logo}
				title={title}
				isActive={isActive}
				buttonText={buttonText}
				labels={events}
				isButtonHidden={isSuccessModalOpen}
				onButtonClicked={handleOnClick}
				isLoading={isLoading}
				isButtonDisabled={isButtonDisabled}
				headerText={isSuccessModalOpen ? '' : undefined}
				subheaderText={isSuccessModalOpen ? '' : undefined}
			>
				{isSuccessModalOpen ? (
					<SuccessModal platform={platform} logo={logo} />
				) : (
					<CustomTable actions={actions} setActions={setActions} />
				)}
			</Modal>
			<ZapEditorModal
				url={zapEditorUrl}
				onClose={handleZapEditorOnClose}
				onEvent={(event) => {
					switch (event) {
						case 'zap:pause:done':
						case 'zap:unpause:done':
							handleZapEditorOnEvent();
							break;
						default:
							break;
					}
				}}
			/>
		</>
	);
};

interface IntegrationModalProps {
	platformIntegration: PlatformIntegration;
	integration?: Integration;
	labels: Readonly<Array<string>>;
	isOpen: boolean;
	onCloseModal: () => void;
	businessId: string;
}

export const IntegrationModal: React.FC<IntegrationModalProps> = ({
	platformIntegration,
	integration,
	labels,
	isOpen = false,
	onCloseModal,
	businessId,
}) => {
	const queryClient = useQueryClient();
	const postIntegration = usePostIntegration();
	const updateIntegration = useUpdateIntegration();

	const { logo, description, title } = platformIntegration;
	const isActive = integration?.status === 'Active';
	const buttonText = postIntegration.isLoading
		? 'Activating Integration...'
		: isActive
		? 'Update Integration'
		: 'Activate Integration';
	const isButtonHidden = !integration && title === 'shopify';
	const [actions, setActions] = useState<
		ReadonlyArray<{
			actionName: string;
			actionCount: number;
		}>
	>(
		labels.map((label) => ({
			actionName: label,
			actionCount: integration?.treesPerTransaction ?? 0,
		})),
	);

	const treesPerTransaction = actions[0]?.actionCount ?? 0;
	const isButtonDisabled =
		postIntegration.isLoading ||
		updateIntegration.isLoading ||
		(treesPerTransaction === 0 && integration?.status !== 'Active');

	const handlePostIntegration = () => {
		const action = actions[0];
		const treesPerTransaction = action?.actionCount ?? 0;

		postIntegration.mutate(
			{
				businessId,
				integration: title ?? 'custom',
				project: 'KEN',
				type: labels[0]?.toLowerCase() ?? 'manual',
				treesPerTransaction,
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['getBusinessIntegrations']);
					toast.success('Update Successful!');
				},
			},
		);
	};

	const handleUpdateIntegration = () => {
		if (integration) {
			const action = actions[0];
			const treesPerTransaction = action?.actionCount ?? 0;
			const status = treesPerTransaction > 0 ? 'Active' : 'Paused';

			updateIntegration.mutate(
				{
					integrationId: integration?.id,
					treesPerTransaction,
					status,
				},
				{
					onSuccess: () => {
						toast.success('Update Successful!');
					},
				},
			);
		}
	};

	const handleOnClick = () => {
		if (integration) {
			handleUpdateIntegration();
		} else {
			handlePostIntegration();
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onCloseModal={onCloseModal}
			description={description}
			image={logo}
			title={title}
			isActive={isActive}
			buttonText={buttonText}
			labels={labels}
			onButtonClicked={handleOnClick}
			isButtonHidden={isButtonHidden}
			isButtonDisabled={isButtonDisabled}
			isLoading={postIntegration.isLoading || updateIntegration.isLoading}
			hideCloseButton={title === 'custom'}
			hideHeaderText={title === 'custom'}
		>
			{title === 'shopify' ? (
				<>
					<ShopifyUrlForm onConnect={() => {}} />
					{integration && <CustomTable actions={actions} setActions={setActions} />}
				</>
			) : title === 'custom' ? (
				<>
					<CustomPlanting
						businessId={businessId}
						closeButtonCallback={onCloseModal}
					/>
				</>
			) : (
				<CustomTable actions={actions} setActions={setActions} />
			)}
		</Modal>
	);
};
