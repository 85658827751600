import { compareAsc } from 'date-fns';

export const ACCESS_TOKEN_KEY = 'zapier_access_token';
export const EXPIRES_IN_KEY = 'zapier_expires_in';

export const getZapierAccessToken = () => {
	const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
	const zapierExpiresIn = window.localStorage.getItem(EXPIRES_IN_KEY);

	if (typeof accessToken === 'string' && typeof zapierExpiresIn === 'string') {
		const expiresIn = JSON.parse(zapierExpiresIn) - 3600;
		const expiresAt = new Date(Date.now() + expiresIn);

		if (compareAsc(expiresAt, new Date()) > 0) {
			return accessToken;
		}
	}

	return undefined;
};

export const getZapierRedirectUrl = () => {
	const zapierClientId = process.env.REACT_APP_ZAPIER_CLIENT_ID;

	if (!zapierClientId)
		throw new Error('The Zapier Client ID is missing from the environment');

	const redirectUrl = new URL('https://zapier.com/oauth/authorize');

	redirectUrl.searchParams.append('client_id', zapierClientId);
	redirectUrl.searchParams.append(
		'redirect_uri',
		`${window.location.origin}/partner-auth`,
	);
	redirectUrl.searchParams.append('response_type', 'token');
	redirectUrl.searchParams.append('approval_prompt', 'force');
	redirectUrl.searchParams.append('scope', 'zap');

	return redirectUrl.toString();
};

export const redirectToZapier = () => {
	const zapierClientId = process.env.REACT_APP_ZAPIER_CLIENT_ID;

	if (!zapierClientId)
		throw new Error('The Zapier Client ID is missing from the environment');

	const redirectUrl = new URL('https://zapier.com/oauth/authorize');

	redirectUrl.searchParams.append('client_id', zapierClientId);
	redirectUrl.searchParams.append(
		'redirect_uri',
		`${window.location.origin}/partner-auth`,
	);
	redirectUrl.searchParams.append('response_type', 'token');
	redirectUrl.searchParams.append('approval_prompt', 'auto');
	redirectUrl.searchParams.append('scope', 'zap');

	window.open(redirectUrl.toString());
};
