import { Navbar, Container, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './index.scss';
import { Logo, Logout } from '../../components/icons';
import { Dashboard, Feedback } from '../icons';
import { useLogout } from '../../hooks/useAuth';

const Header = ({ showLinks }) => {
	const navigate = useNavigate();
	const logout = useLogout();
	return (
		<Container className='header'>
			<Navbar expand='lg'>
				<Navbar.Brand href='#'>
					<Logo className='logo' />
				</Navbar.Brand>
				{showLinks && (
					<>
						<Navbar.Toggle aria-controls='navbar-nav' />
						<Navbar.Collapse id='navbar-nav' className='justify-content-end'>
							<Nav>
								<Nav.Link
									onClick={() => navigate('/client-dashboard', { replace: true })}
								>
									<Dashboard />
									<span>Dashboard</span>
								</Nav.Link>
								<Nav.Link onClick={() => navigate('/nps-dash', { replace: true })}>
									<Feedback />
									<span>NPS</span>
								</Nav.Link>
								<Nav.Link onClick={() => logout()}>
									<Logout width='11' height='11' />
									<span>Log out</span>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</>
				)}
				{!showLinks && (
					<>
						<Navbar.Toggle aria-controls='navbar-nav' />
						<Navbar.Collapse id='navbar-nav' className='justify-content-end'>
							<Nav>
								<Nav.Link
									onClick={() => {
										logout();
										navigate('/', { replace: true });
									}}
								>
									<Logout width='11' height='11' />
									<span>Log out</span>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</>
				)}
			</Navbar>
		</Container>
	);
};

export default Header;
