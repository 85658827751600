import { z } from 'zod';
import { useQuery } from 'react-query';

import { ecodriveApi } from '../../libs/axios';
import { zapierApi } from '../../libs/zapier';
import { useGetBusinessIntegrations } from './useGetBusinessIntegrations';
import { useGetZaps, zapStepSchema } from './useGetZaps';

const platformIntegrationSchema = z.object({
	id: z.string(),
	created_at: z.string().nullish(),
	description: z.string(),
	eventType: z.string(),
	logo: z.string(),
	platform: z.string(),
	updated_at: z.string().nullish(),
	title: z.string().nullish(),
});

export type PlatformIntegration = z.infer<typeof platformIntegrationSchema> & {
	type: 'native';
};

const zapTemplateSchema = z.object({
	create_url: z.string(),
	description: z.string(),
	description_plain: z.string(),
	description_raw: z.string(),
	slug: z.string(),
	status: z.string(),
	title: z.string(),
	type: z.string(),
	url: z.string(),
	steps: z.array(zapStepSchema),
});

export type ZapTemplate = z.infer<typeof zapTemplateSchema> & {
	type: 'zapier';
};

const getPlatformIntegrations = async (): Promise<
	ReadonlyArray<PlatformIntegration>
> => {
	const { data } = await ecodriveApi.get('/platform-integrations');

	const parsed = z.array(platformIntegrationSchema).parse(data);

	return parsed.map((parse) => ({
		...parse,
		type: 'native',
	}));
};

const getZapTemplates = async (
	zapierClientId: string,
): Promise<ReadonlyArray<ZapTemplate>> => {
	const { data } = await zapierApi.get('/v1/zap-templates', {
		params: {
			client_id: zapierClientId,
		},
	});

	const parsed = z.array(zapTemplateSchema).parse(data);

	return parsed.map((parse) => ({
		...parse,
		type: 'zapier',
	}));
};

const useGetPlatformIntegrations = () => {
	const { data, ...rest } = useQuery(
		['getPlatformIntegrations'],
		getPlatformIntegrations,
	);

	return {
		platformIntegrations:
			data?.map((integration) => ({
				...integration,
				events: JSON.parse(integration.eventType),
				title: integration.platform,
			})) ?? [],
		...rest,
	};
};

export interface GetZapTemplate {
	createUrl: string;
	status: string;
	description: string;
	slug: string;
	logo: string;
	events: ReadonlyArray<string>;
	title: string;
	platform: 'zapier';
	type: 'zapier';
}

const useGetZapTemplates = (zapierClientId: string) => {
	const { data, ...rest } = useQuery<ReadonlyArray<GetZapTemplate>>(
		['getZapTemplates'],
		async () => {
			const data = await getZapTemplates(zapierClientId);

			return data.map((d) => {
				const step = d.steps[0];

				return {
					createUrl: d.create_url,
					status: d.status,
					description: step?.description ?? '',
					slug: step?.slug ?? '',
					logo: step?.image ?? '',
					events: [step?.label ?? ''],
					title: step?.title ?? '',
					platform: 'zapier',
					type: d.type,
				};
			});
		},
		{ refetchOnWindowFocus: false },
	);

	return {
		zapTemplates: data ?? [],
		...rest,
	};
};

export const useIntegrations = ({
	zapierClientId,
	businessId,
}: {
	zapierClientId: string;
	businessId: string;
}) => {
	const {
		platformIntegrations,
		isLoading: platformIntegrationsIsLoading,
		isError: platformIntegrationsIsError,
	} = useGetPlatformIntegrations();

	const {
		zapTemplates,
		isLoading: zapTemplatesIsLoading,
		isError: zapTemplatesIsError,
	} = useGetZapTemplates(zapierClientId);

	const {
		businessIntegrations,
		isLoading: businessIntegrationsIsLoading,
		isError: businessIntegrationsIsError,
	} = useGetBusinessIntegrations(businessId);

	const {
		zaps,
		isLoading: getZapsIsLoading,
		isError: getZapsIsError,
	} = useGetZaps();

	const mappedZapTemplates = zapTemplates.map((template) => {
		const zap = zaps.find(
			(zap) =>
				zap?.triggerSlug === template?.slug && zap?.actionSlug === 'ecodrive',
		);

		const businessIntegration = businessIntegrations.find(
			(integration) =>
				integration.type === 'zap' && integration.integration === template.slug,
		);

		return {
			...template,
			zap,
			businessIntegration,
			isActive: businessIntegration?.status === 'Active',
		};
	});

	const mappedPlatformIntegrations = platformIntegrations.map((integration) => {
		const businessIntegration = businessIntegrations.find(
			(bi) => bi.integration === integration.title,
		);

		return {
			...integration,
			isActive: businessIntegration?.status === 'Active',
			businessIntegration,
		};
	});

	const integrations = [
		...mappedZapTemplates,
		...mappedPlatformIntegrations,
	].sort((a, b) => {
		if (a.isActive && !b.isActive) {
			return -1;
		} else if (!a.isActive && b.isActive) {
			return 0;
		} else {
			return 1;
		}
	});

	return {
		integrations,
		zapTemplatesWithZaps: mappedZapTemplates,
		platformIntegrations: mappedPlatformIntegrations,
		isLoading:
			platformIntegrationsIsLoading ||
			zapTemplatesIsLoading ||
			businessIntegrationsIsLoading ||
			getZapsIsLoading,
		isError:
			platformIntegrationsIsError ||
			zapTemplatesIsError ||
			businessIntegrationsIsError ||
			getZapsIsError,
	};
};
