import React from "react";
import { Helmet } from "react-helmet";
import PlacesContext from "../../context/Places";
import usePlacesApi from "../../hooks/usePlacesApi";
import { env } from "../../utils/config";

const {
  google: { placesApiKey },
} = env;


const Places = ({ children }) => {
  const { getProps, places, handleSelect } = usePlacesApi();

  return (
    <PlacesContext.Provider
      value={[
        places,
        getProps,
        handleSelect
      ]}
    >
      <Helmet>
        <script
          async
          src={`https://maps.googleapis.com/maps/api/js?key=${placesApiKey}&libraries=places&callback=initMap`}
        ></script>
      </Helmet>
      {children}
    </PlacesContext.Provider>
  );
};

export default Places;
