import { z } from 'zod';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ecodriveApi } from '../../libs/axios';

export const getBusinessIntegrationsKey = 'getBusinessIntegrations';

const integrationSchema = z.object({
	authToken: z.string().nullish(),
	businessId: z.string(),
	business_id: z.string(),
	created_at: z.string(),
	id: z.string(),
	integration: z.string(),
	project: z.string(),
	projectId: z.string(),
	shopUrl: z.string().nullish(),
	status: z.string(),
	subscriptionItem: z.string().nullish(),
	totalTransactions: z.number(),
	totalTrees: z.coerce.number().nullish(),
	treesPerTransaction: z.number(),
	type: z.string(),
	updated_at: z.string(),
});

export type Integration = z.infer<typeof integrationSchema>;

const getBusinessIntegrationsSchema = z.object({
	integrations: z.array(integrationSchema),
});

const getBusinessIntegrations = async (businessId: string) => {
	const { data } = await ecodriveApi.get(`/integrations/${businessId}/business`);

	const parsed = getBusinessIntegrationsSchema.parse(data);

	return parsed;
};

const updateBusinessIntegration = ({
	integrationId,
	...rest
}: {
	integrationId: string;
	[x: string]: any;
}) =>
	ecodriveApi.put(
		`/integrations/${integrationId}`,
		{ ...rest },
		{
			headers: {
				'Content-Type': 'application/json',
			},
		},
	);

export const useUpdateIntegration = () => {
	const queryClient = useQueryClient();
	const mutate = useMutation(updateBusinessIntegration, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(getBusinessIntegrationsKey);
		},
	});

	return mutate;
};

export const useGetBusinessIntegrations = (businessId?: string) => {
	const { data, ...rest } = useQuery(
		[getBusinessIntegrationsKey],
		async () => {
			if (!businessId) return;

			return getBusinessIntegrations(businessId);
		},
		{
			enabled: !!businessId,
		},
	);

	return {
		businessIntegrations: data?.integrations ?? [],
		...rest,
	};
};
