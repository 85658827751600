import React from 'react';

import './IntegrationCard.scss';

const IntegrationCard = ({
	isActive,
	logo,
	title,
	platform,
	description,
	labels,
	actionText,
	onClick,
	disabled = false,
}) => {
	const native = platform === 'shopify' || platform === 'custom';
	return (
		<>
			<div
				className={`integration-card d-flex flex-column mb-5 rounded-4 ${
					isActive ? 'integration-card--active' : 'integration-card--inactive'
				}`}
			>
				<div className='integration-card__content position-relative d-flex flex-column flex-grow-1'>
					{isActive ? (
						<div className='integration-card__active-badge rounded-4 text-light fw-bold position-absolute text-uppercase'>
							Active
						</div>
					) : null}
					<div className='integration-card__image-container h-25 mb-3 w-25'>
						{logo ? <img src={logo} alt={title} className='h-100 w-100' /> : null}
					</div>
					<p className='integration-card__platform-name fs-4 fw-bold mb-1'>
						{title}
					</p>
					{platform && !native && (
						<p className='integration-card__platform-powered mb-3 fw-light'>
							Powered by {platform}
						</p>
					)}
					<p className='integration-card__platform-description fw-bolder mb-2 overflow-auto'>
						{description}
					</p>
					{labels && (
						<div className='integration-card__labels pt-3'>
							<ul className='integration-card__label-list'>
								{labels.map((label, index) => (
									<li key={index} className='integration-card__label-item'>
										{label}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
				<div
					onClick={disabled ? () => {} : onClick}
					className={`integration-card__action align-items-center d-flex fw-bolder justify-content-center text-center text-uppercase w-100${
						disabled ? ' integration-card__disabled' : ''
					}`}
				>
					{actionText}
				</div>
			</div>
		</>
	);
};

export default IntegrationCard;
