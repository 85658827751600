import React, { useEffect, useState } from 'react';
import {
	Badge,
	Button,
	Col,
	Form,
	Modal,
	OverlayTrigger,
	Row,
	Spinner,
	Tooltip,
} from 'react-bootstrap';
import { ecodriveApi } from '../../libs/axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RichEditor, { createDecorators } from './RichEditor';
import MediaUpload from './MediaUpload';
import { toast } from 'react-toastify';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import classes from './Editor.module.scss';

const editProjectSchema = Yup.object().shape({
	title: Yup.string().required('Please enter a title'),
	price: Yup.number().required('Please set a price'),
	inventory: Yup.number().required('Please set an inventory'),
	banner: Yup.string().url().required('Paste a URL or upload a file'),
	thumbnail: Yup.string().url().required('Paste a URL or upload a file'),
	preview: Yup.string().required('Please enter a content preview'),
	silhouette: Yup.string().url().required('Please enter an SVG silhouette'),
});

const ClipboardBadge = ({ label, bg, value }) => {
	const [copied, setCopied] = useState(false);
	const handleClipboard = (value) => {
		setTimeout(() => {
			setCopied(false);
		}, 800);
		navigator.clipboard.writeText(value);
		setCopied(true);
	};

	return (
		<>
			<Col xs={12}>
				<Form.Label>{label}</Form.Label>
			</Col>
			<Col xs={12} className='mb-3'>
				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement='right'
					overlay={<Tooltip>{copied ? 'Copied!' : 'Copy to clipboard'}</Tooltip>}
					show={copied || undefined}
				>
					<Badge
						onClick={() => handleClipboard(value)}
						style={{ cursor: 'pointer' }}
						bg={bg}
					>
						<i className={['bi bi-clipboard', copied ? '-check' : ''].join('')}></i>{' '}
						{value}
					</Badge>
				</OverlayTrigger>
			</Col>
		</>
	);
};

const EditProjectModal = ({
	project,
	onClose,
	onUpdate,
	closeOnSave = true,
}) => {
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [editorState, setEditorState] = useState(
		EditorState.createEmpty(createDecorators()),
	);

	const {
		values,
		handleChange,
		handleBlur,
		setValues,
		resetForm,
		handleSubmit,
		setFieldValue,
		setFieldTouched,
		errors,
		touched,
		isValid,
	} = useFormik({
		initialValues: {
			id: '',
			title: '',
			price: '',
			inventory: '',
			banner: '',
			thumbnail: '',
			preview: '',
			stripeId: '',
			key: '',
			silhouette: '',
		},
		validationSchema: editProjectSchema,
		async onSubmit(
			{ banner, inventory, preview, title, thumbnail, id, silhouette },
			{ resetForm },
		) {
			setSaving(true);
			const editorContent = editorState.getCurrentContent();
			const rawContent = convertToRaw(editorContent);
			try {
				await ecodriveApi.put('/planting-projects/' + id, {
					banner,
					content: JSON.stringify(rawContent),
					inventory,
					preview,
					thumbnail,
					title,
					silhouette,
				});
				onUpdate();
				if (closeOnSave) {
					resetForm();
					onClose();
				}
			} catch (error) {
				toast.error(error.response.data.message);
			}

			setSaving(false);
		},
	});

	const handleClose = () => {
		onClose();
		resetForm();
		setEditorState(EditorState.createEmpty(createDecorators()));
	};

	useEffect(() => {
		setLoading(true);
		const getProject = async () => {
			try {
				const { data } = await ecodriveApi.get('/planting-projects/' + project);
				if (data.content) {
					const rawContent = JSON.parse(data.content);
					setEditorState(
						EditorState.createWithContent(
							convertFromRaw(rawContent),
							createDecorators(),
						),
					);
				}
				setValues({
					id: data.id,
					key: data.projectKey,
					stripeId: data.stripePriceId,
					title: data.title,
					price: data.price,
					inventory: data.inventory,
					banner: data.banner,
					thumbnail: data.thumbnail,
					preview: data.preview,
					silhouette: data.silhouette,
				});
			} catch (error) {
				toast.error(error.response.data.message);
			}
			setLoading(false);
		};
		if (project) {
			getProject();
		}
	}, [project, setValues]);

	const projectKey = (
		<ClipboardBadge value={values.key} label='Project Key:' bg='success' />
	);

	const stripeId = (
		<ClipboardBadge value={values.stripeId} label='Stripe Id:' bg='dark' />
	);

	const projectTitle = (
		<Col xs={12} className='mb-3'>
			<Form.Group className='mb-3'>
				<Form.Label>Project Title</Form.Label>
				<Form.Control
					name='title'
					value={values.title}
					onChange={handleChange}
					onBlur={handleBlur}
					type='text'
					placeholder='Enter project title'
				/>
				<Form.Text className='text-danger'>{errors.title}</Form.Text>
			</Form.Group>
		</Col>
	);

	const priceAndInventory = (
		<Col xs={12} lg={6}>
			<div className='d-flex flex-column'>
				<div>
					<Form.Group className='mb-3'>
						<Form.Label>Price</Form.Label>
						<Form.Control
							disabled
							name='price'
							value={values.price}
							onChange={handleChange}
							onBlur={handleBlur}
							type='text'
							placeholder='Enter project price'
						/>
						<Form.Text className='text-danger'>{errors.price}</Form.Text>
					</Form.Group>
				</div>
				<div>
					<Form.Group className='mb-3'>
						<Form.Label>Inventory</Form.Label>
						<Form.Control
							name='inventory'
							value={values.inventory}
							onChange={handleChange}
							onBlur={handleBlur}
							type='text'
							placeholder='Enter project inventory'
						/>
						<Form.Text className='text-danger'>{errors.inventory}</Form.Text>
					</Form.Group>
				</div>
				<Row>
					{projectKey}
					{stripeId}
				</Row>
			</div>
		</Col>
	);

	const banner = (
		<Col xs={12}>
			<Form.Group className='mb-3'>
				<Form.Label>Banner</Form.Label>
				<MediaUpload
					value={values.banner}
					onChange={(v) => {
						setFieldValue('banner', v);
						setFieldTouched('banner', true);
					}}
					uploadUrl={`/planting-projects/${values.id}/upload`}
					setFormData={(formData) => {
						formData.set('name', 'banner');
					}}
				/>
				{touched.banner && (
					<Form.Text className='text-danger'>{errors.banner}</Form.Text>
				)}
			</Form.Group>
		</Col>
	);

	const thumbnail = (
		<Col xs={12} lg={6} className='align-self-center mb-3'>
			<Form.Group>
				<Form.Label>Thumbnail</Form.Label>
				<MediaUpload
					value={values.thumbnail}
					onChange={(v) => {
						setFieldValue('thumbnail', v);
						setFieldTouched('thumbnail', true);
					}}
					uploadUrl={`/planting-projects/${values.id}/upload`}
					setFormData={(formData) => {
						formData.set('name', 'thumbnail');
					}}
				/>
				{touched.thumbnail && (
					<Form.Text className='text-danger'>{errors.thumbnail}</Form.Text>
				)}
			</Form.Group>
		</Col>
	);

	const silhouetteAndPreviewText = (
		<>
			<Col xs={12} lg={6} className='mb-3'>
				<Form.Group className='mb-3'>
					<Form.Label>Silhouette</Form.Label>
					<MediaUpload
						fit='contain'
						accept='image/svg+xml'
						value={values.silhouette}
						onChange={(v) => {
							setFieldValue('silhouette', v);
							setFieldTouched('silhouette', true);
						}}
						uploadUrl={`/planting-projects/${values.id}/upload`}
						setFormData={(formData) => formData.set('name', 'silhouette')}
					/>
					{touched.silhouette && (
						<Form.Text className='text-danger'>{errors.silhouette}</Form.Text>
					)}
				</Form.Group>
			</Col>
			<Col xs={12} lg={6} className='mb-3'>
				<Form.Group className='mb-3'>
					<Form.Label>Preview Text</Form.Label>
					<Form.Control
						as='textarea'
						name='preview'
						value={values.preview}
						onChange={handleChange}
						onBlur={handleBlur}
						rows={5}
						type='text'
						placeholder='Edit preview content'
					/>
					<Form.Text className='text-danger'>{errors.preview}</Form.Text>
				</Form.Group>
			</Col>
		</>
	);

	const richEditor = (
		<Col xs={12} className='mb-3'>
			<Form.Group className='mb-3'>
				<Form.Label>Content</Form.Label>
				<div className={classes['project-editor']}>
					<RichEditor
						onChange={setEditorState}
						editorState={editorState}
						projectId={values.id}
					/>
				</div>
			</Form.Group>
		</Col>
	);

	return (
		<Modal fullscreen='md-down' show={project} onHide={handleClose} size='xl'>
			<Modal.Header>
				<Row>
					<Col xs={12}>
						<Modal.Title>Edit Project</Modal.Title>
					</Col>
				</Row>
			</Modal.Header>
			<Modal.Body>
				{loading && (
					<>
						<div
							className='position-absolute h-100 w-100 bg-dark start-0 top-0'
							style={{ zIndex: 1000, opacity: 0.3 }}
						/>
						<div className='text-center position-absolute w-100 p-5'>
							<Spinner animation='grow' size='sm' />
							<Spinner className='mx-3' animation='grow' size='sm' />
							<Spinner animation='grow' size='sm' />
						</div>
					</>
				)}
				<Form>
					<Row>
						{projectTitle}
						{richEditor}
						{silhouetteAndPreviewText}
						{thumbnail}
						{priceAndInventory}
						{banner}
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{!isValid && <Form.Text>Please fill all the fields</Form.Text>}
				{saving && <Spinner size='sm' />}
				<Button variant='secondary' onClick={handleClose}>
					Close
				</Button>
				<Button
					disabled={saving || !isValid}
					variant='success'
					onClick={handleSubmit}
				>
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditProjectModal;
