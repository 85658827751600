/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import plantATree from '../../../assets/images/auth/plantATree.svg';
import withErrorHandling from '../../../components/withErrorHandling';
import '../index.scss';
import { Logo } from '../../../components/icons';
import { useLogin } from '../../../hooks/useAuth';
import { AuthLayout } from '../../../lauyouts';
import { Ecodrive } from '../../../context/store';

const Login = withErrorHandling(() => {
	let navigate = useNavigate();
	const [{ user, business }] = useContext(Ecodrive);

	const [error, setError] = useState('');

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const login = useLogin({ email, password });

	const handleSubmit = async (event) => {
		event.preventDefault();
		login().catch((err) => {
			setError(true);
		});
	};

	if (
		user &&
		user?.profile?.id &&
		!user?.profile?.businessId &&
		user?.profile?.role !== 'admin'
	)
		return <Navigate to='/setup' />;

	if ('id' in business) return <Navigate to='/client-resources' />;
	if (user.profile?.role === 'admin') return <Navigate to='/dashboard' />;

	return (
		<AuthLayout>
			<div className='auth-container'>
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0'
					charSet='utf-8'
				></meta>
				<Row>
					<Col md={7} sm={12} className='content px-4 py-5'>
						<div className='content-wrapper'>
							<Logo />
							<h1>Login to Ecodrive</h1>
							<Form onSubmit={(event) => handleSubmit(event)}>
								<Row>
									<Col>
										<p className='form-title'>Please enter your login info</p>
									</Col>
								</Row>
								<Row>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type='email'
												value={email}
												onChange={(event) => setEmail(event.target.value)}
												placeholder='johnny@appleseed.com'
												required
											/>
										</Form.Group>
									</Col>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Password</Form.Label>
											<Form.Control
												type='password'
												value={password}
												onChange={(event) => setPassword(event.target.value)}
												placeholder='Password'
												required
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									{error && (
										<Col sm={12} className='my-3'>
											<p className='error mb-0'>
												{error} Forgot your password? Click{' '}
												<span
													onClick={() => {
														navigate('/forgot-password');
													}}
												>
													here
												</span>{' '}
												to reset it
											</p>
										</Col>
									)}
									<Col md={6} sm={12}>
										<Button
											type='submit'
											className='button button-secondary full-width my-3'
										>
											Login
										</Button>
									</Col>
								</Row>
							</Form>
							<p
								className='link mt-3'
								onClick={() => navigate('/register', { replace: true })}
							>
								Don't have an account? Sign up here
							</p>
						</div>
						<div className='banner-img'>
							<img src={plantATree} alt='Plant a tree' />
						</div>
					</Col>
					<Col md={5} sm={12} className='banner'></Col>
				</Row>
			</div>
		</AuthLayout>
	);
});

export default withErrorHandling(Login);
