import { useState } from 'react';
import { trackNetworkError } from '../../plugins/analytics';
import { ecodriveApi } from '../../libs/axios';
import Smartlook from 'smartlook-client';

let setAuthHeader = (objectToCombine = null) => {
	let defaultHeaders = {
		authorization: 'Bearer ' + localStorage.getItem('authorization'),
	};
	if (objectToCombine) {
		return { headers: Object.assign(defaultHeaders, objectToCombine) };
	}
	return { headers: defaultHeaders };
};

const setFromLocalStorageIfRecent = (keyName, defaultValue) => {
	let timeNow = new Date().valueOf();
	let weekAgo = timeNow - 1000 * 60 * 60 * 24 * 7;

	let lastAuth = localStorage.getItem('lastAuth');
	if (!lastAuth) return defaultValue;
	lastAuth = parseInt(lastAuth);
	if (isNaN(lastAuth)) return defaultValue;
	if (lastAuth < weekAgo) return defaultValue;
	if (!localStorage.getItem(keyName)) return defaultValue;
	return localStorage.getItem(keyName);
};

export default function useAuth() {
	const [user, setUser] = useState({});
	const [authed, setAuthed] = useState(
		setFromLocalStorageIfRecent('auth', false),
	);
	const [userId, setUserId] = useState(
		setFromLocalStorageIfRecent('userId', null),
	);
	const [verified, setVerified] = useState(
		setFromLocalStorageIfRecent('verified', 'false'),
	);
	const [role, setRole] = useState(setFromLocalStorageIfRecent('role', '0'));
	const [email, setEmail] = useState(setFromLocalStorageIfRecent('email', ''));
	const [businessId, setBusinessId] = useState(
		setFromLocalStorageIfRecent('business_id', ''),
	);
	const [stripeCustomerId, setStripeCustomerId] = useState(
		setFromLocalStorageIfRecent('stripe_customer_id', ''),
	);
	// this is going to be Shopify Integration
	const [integrationId, setIntegrationId] = useState(
		setFromLocalStorageIfRecent('integration_id', ''),
	);
	const [manualIntegrationId, setManualIntegrationId] = useState(
		setFromLocalStorageIfRecent('manual_integration', ''),
	);

	const resetAllHooks = () => {
		setUser({});
		setAuthed(false);
		setUserId(null);
		setVerified('false');
		setRole('0');
		setEmail('');
		setBusinessId('');
		setStripeCustomerId('');
		setIntegrationId('');
		setManualIntegrationId('');
	};

	return {
		user,
		authed,
		userId,
		verified,
		role,
		email,
		businessId,
		stripeCustomerId,
		integrationId,
		setIntegrationId,
		manualIntegrationId,
		setManualIntegrationId,
		setBusinessId,
		// TODO: this is never being used?
		getUser() {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/users/me')
					.then((response) => {
						Smartlook.track('getUser', {
							type: 'FetchSuccessful',
						});
						setUser(response.data);
						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/users/me`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		putUser(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.put(
						'/users/me',
						data,
						setAuthHeader({
							'Content-Type': 'multipart/form-data',
						}),
					)
					.then((response) => {
						Smartlook.track('putUser', {
							type: 'UpdateSuccessful',
						});
						resolve(response);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/users/me`,
							data: data,
							error,
							requestType: 'put',
						});
						reject(error.response.data);
					});
			});
		},
		register(data) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post('/register', data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((response) => {
						Smartlook.track('register', {
							type: 'RegistrationSuccessful',
						});
						if (response.data !== 'Invalid email address!') {
							const { userId } = response.data;
							localStorage.setItem('auth', true);
							localStorage.setItem('userId', userId);
							localStorage.setItem('verified', 'false');
							localStorage.setItem('email', response.data.email);
							setEmail(response.data.email);
							setAuthed(true);
							setUserId(userId);
							setVerified('false');
						}
						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/register`,
							data: data,
							error,
							requestType: 'post',
						});
						reject(error);
					});
			});
		},
		login(data) {
			localStorage.clear();
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post('/login', data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then(async ({ data }) => {
						Smartlook.track('login', {
							type: 'LoginSuccessful',
						});
						localStorage.setItem('authorization', data.token);
						let integrations = [];
						const { data: user } = await ecodriveApi.get('/users/me');

						setRole(user.role);
						setUserId(user.id);
						setEmail(user.email);
						if (user.business_id) {
							setBusinessId(user.businessId);
						}

						localStorage.setItem('email', user.email);
						localStorage.setItem('auth', true);
						localStorage.setItem('userId', user.id);
						localStorage.setItem('role', user.role);
						localStorage.setItem('business_id', user.businessId);
						localStorage.setItem('lastAuth', new Date().valueOf());
						let verifyStatus = 'false';
						if (user.verified) {
							verifyStatus = 'true';
						}
						localStorage.setItem('verified', verifyStatus);
						setVerified(verifyStatus);

						if (user.role === 1) {
							setAuthed(true);
							return { user };
						}
						const { data: business } = await ecodriveApi.get('/business');
						if (business) {
							setStripeCustomerId(business.customerId);
							localStorage.setItem('stripe_customer_id', business.customerId);
							const {
								data: { integrations: retrievedIntegrations },
							} = await ecodriveApi.get('/integrations/' + business.id + '/business');
							integrations.push(...retrievedIntegrations);
						}
						setAuthed(true);
						return {
							user,
							integrations: integrations,
						};
					})
					.then(({ integrations = [], user }) => {
						integrations.forEach((integration) => {
							if (integration.type === 'manual') {
								localStorage.setItem('manual_integration', integration.integration_id);
								setManualIntegrationId(integration.integration_id);
							}
							if (integration.type === 'order') {
								localStorage.setItem('integration_id', integration.integration_id);
								setIntegrationId(integration.integration_id);
							}
						});
						resolve(user);
					})
					.catch((error) => {
						reject(error.response.data);
					});
			});
		},
		forgot(email) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/forgot-password?email=' + email, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((response) => {
						Smartlook.track('forgotPassword', {
							type: 'ForgotPasswordEmailSuccessful',
						});
						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/forgot-password?email=${email}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error.response.data);
					});
			});
		},
		reset(data, token) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.post('/reset-password', data, {
						headers: {
							'Content-Type': 'multipart/form-data',
							Authorization: `Bearer ${token}`,
						},
					})
					.then((response) => {
						Smartlook.track('resetPassword', {
							type: 'UpdatePasswordSuccessful',
						});
						resolve(response.data);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/reset-password`,
							data: data,
							error,
							requestType: 'post',
						});
						if (error.isAxiosError) {
							reject(error.response.data);
						}
						reject(error);
					});
			});
		},

		verify() {
			return new Promise((res) => {
				setVerified('true');
				localStorage.setItem('verified', 'true');
				Smartlook.track('verify', { type: 'VerificationSuccessful' });
				res();
			});
		},
		logout() {
			return new Promise((res) => {
				localStorage.clear();
				localStorage.setItem('hasPrompted', 'false');
				resetAllHooks();
				Smartlook.track('logout', { type: 'LogoutSuccessful' });
				Smartlook.anonymize();
				res();
			});
		},

		resend(id) {
			return new Promise((resolve, reject) => {
				ecodriveApi
					.get('/resend-email?user_id=' + id)
					.then((response) => {
						Smartlook.track('resend', { type: 'ResendSuccessful' });
						resolve(response);
					})
					.catch((error) => {
						trackNetworkError({
							url: `${process.env.REACT_APP_API_ENDPOINT}/resend-email?user_id=${id}`,
							data: {},
							error,
							requestType: 'get',
						});
						reject(error);
					});
			});
		},
		setStripeId(stripeId) {
			setStripeCustomerId(stripeId);
		},
	};
}
