/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useContext } from 'react';
import {
	Container,
	Row,
	Col,
	Navbar,
	Nav,
	ProgressBar,
	Button,
	Modal,
	Form,
	Spinner,
	InputGroup,
} from 'react-bootstrap';
import { Carousel } from '3d-react-carousal';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useBetween } from 'use-between';
import { exportComponentAsPNG } from 'react-component-export-image';
import { toast } from 'react-toastify';
import Smartlook from 'smartlook-client';

import './index.scss';
import {
	Logo,
	Leap,
	HelpArrow,
	Days,
	Lifetime,
	LBS,
	Instagram,
	Linkedin,
	Pinterest,
	WebsiteIcon,
	Brand,
	Carbon,
	GreenLeaf,
	Download,
	Setting,
	Logout,
} from '../../../../components/icons';
import Background from '../../../../assets/images/dashboard/background_2.png';
import Carousel1 from '../../../../assets/images/dashboard/carousel1.jpg';
import Carousel2 from '../../../../assets/images/dashboard/carousel2.jpg';
import Carousel3 from '../../../../assets/images/dashboard/carousel3.jpg';
import Filters from '../../../../components/filters';
// import Tree from "../../../../assets/images/3d/tree.glb"
import useClient from '../../useClient';
import layer from '../../../../assets/images/dashboard/layer.png';
import Celebrate from '../../../../assets/images/dashboard/celebrate.png';
import Tree100 from '../../../../assets/images/dashboard/100.png';
import Tree1000 from '../../../../assets/images/dashboard/1000.png';
import TreeCustom from '../../../../assets/images/dashboard/custom.png';
import Layer2 from '../../../../assets/images/dashboard/layer2.png';
import { formatDate, datePeriod } from '../../../../plugins';

import VirtualTree from './virtualTree';
import DashboardContent from './dashboardContent';
import TreeStats from '../../../../components/treestats';
import ReachGoal from '../../../../components/reachGoal';
import { Ecodrive } from '../../../../context/store';
import { useLogout } from '../../../../hooks/useAuth';
import useFetch from '../../../../hooks/useFetch';
import { BUSINESS_ENUM } from '../../../../context/store/actions/business.actions';
import { ClientLayout } from '../../../../lauyouts';

const getDateXDaysAgo = (numOfDays, date = new Date()) => {
	const daysAgo = new Date(date.getTime());

	daysAgo.setDate(date.getDate() - numOfDays);

	return daysAgo;
};

const generateCarosuel1 = (image) => (
	<div className='carousel-card'>
		<img src={image} alt='card' />
		<span>Trees</span>
		<label>19 Oct, 2021</label>
		<a
			target='_blank'
			href='https://blog.ecodrive.community/thegrove/uydj7tfb9vxp9p3n1id9drtgqdrme6-56yyx-h92ha-6gyac-ywxlk-gjhnk-pxjad-pnm6c'
			rel='noreferrer'
		>
			<p>How Reforestation Helps Animals</p>
		</a>
	</div>
);

const generateCarosuel2 = (image) => (
	<div className='carousel-card'>
		<img src={image} alt='card' />
		<span>Trees</span>
		<label>11 Jan, 2022</label>
		<a
			target='_blank'
			href='https://blog.ecodrive.community/thegrove/uydj7tfb9vxp9p3n1id9drtgqdrme6-56yyx-h92ha-6gyac-ywxlk-gjhnk-pxjad-pnm6c-llmlp-zheet-feezp-6e3yd-rtkls-sww58-ya3xg-plj7j-shj2h-h2ymj-wmtrz-rybd2-j5k52-za9nz-m2rcf-z64ld'
			rel='noreferrer'
		>
			<p>Combatting Deforestation in Madagascar</p>
		</a>
	</div>
);

const generateCarosuel3 = (image) => (
	<div className='carousel-card'>
		<img src={image} alt='card' />
		<span>Trees</span>
		<label>18 Jan, 2022</label>
		<a
			target='_blank'
			href='https://blog.ecodrive.community/thegrove/uydj7tfb9vxp9p3n1id9drtgqdrme6-56yyx-h92ha-6gyac-ywxlk-gjhnk-pxjad-pnm6c-llmlp-zheet-feezp-6e3yd-rtkls-sww58-ya3xg-plj7j-shj2h-h2ymj-wmtrz-rybd2-j5k52-za9nz-m2rcf-z64ld-bkp5b'
			rel='noreferrer'
		>
			<p>Promising Decarbonization Technologies</p>
		</a>
	</div>
);

const ClientPublicDashboard = ({ isPublic }) => {
	const { fetchTrees, postCustomTrees, isSubscriptionActive } =
		useBetween(useClient);
	const logout = useLogout();
	let navigate = useNavigate();
	const badge1 = useRef();
	const badge2 = useRef();
	const badge3 = useRef();
	const [fminDate, setFminDate] = useState(getDateXDaysAgo(29, new Date()));
	const [from, setFrom] = useState(getDateXDaysAgo(29, new Date()));
	const [to, setTo] = useState(new Date());
	const [periodTrees, setPeriodTrees] = useState(0);
	const [periodOrders, setPeriodOrders] = useState(0);

	const [logo, setLogo] = useState('');
	const [, setNavText] = useState('');
	const [logoLink, setLogoLink] = useState('');
	const [headerText, setHeaderText] = useState('');
	const [subText, setSubText] = useState('');

	const [milestones] = useState([100, 1000, 10000]);
	const [chartData, setChartData] = useState([]);
	const [chartCate, setChartCate] = useState([]);
	const [plant, setPlant] = useState(false);
	const [amount, setAmount] = useState(100);
	const [status, setStatus] = useState(false);
	const [celebrate, setCelebrate] = useState(false);
	const [shopSlug, setShopSlug] = useState('');

	const [{ business }, dispatch] = useContext(Ecodrive);
	const [, setUserId] = useState();

	const params = useParams();
	const store = params.store;
	const { data: shop } = useFetch(store && `/integrations/${store}/shop_url`);

	const businessId = business.id || shop?.businessId;

	const { data: businessData } = useFetch(
		businessId && `/business/${businessId}`,
	);
	const { data: integrations } = useFetch(
		business.id && `/integrations/${business.id}/business`,
	);
	const { data: transactions = [] } = useFetch(
		businessId && `/transactions/${businessId}/business`,
	);
	const { data: content } = useFetch(businessId && `/content/${businessId}`);

	const { data: activeProject } = useFetch(
		business.project?.id && `/planting-projects/${business.project.id}`,
	);

	useEffect(() => {
		if (integrations) {
			const { integrations: ints } = integrations;
			const shopifyIntegration = ints.find(
				(int) => int.shopUrl && int.integration === 'shopify',
			);
			if (shopifyIntegration) {
				const [slug] = shopifyIntegration.shopUrl.split('.');
				setShopSlug(slug);
			} else {
				setShopSlug(businessId);
			}
		}
	}, [integrations, businessId]);

	useEffect(() => {
		if (content) {
			setFminDate(getDateXDaysAgo(29, new Date(content.created_at)));
			setLogo(content.logoImage);
			setNavText(content.navText);
			setLogoLink(content.logoLink);
			setHeaderText(content.headerText);
			setSubText(content.subText);
		}
	}, [content]);

	useEffect(() => {
		if (!('id' in business) && businessData) {
			setUserId(businessData.userId);
			Smartlook.identify(businessData.userId);
			dispatch(BUSINESS_ENUM.FILL_BUSINESS_PUBLIC, businessData);
		}
	}, [dispatch, businessData]);

	useEffect(() => {
		if (businessId && !isPublic) {
			fetchTrees({
				businessId: businessId,
				query: {
					from: formatDate(from),
					to: formatDate(to),
				},
			}).then((data) => {
				setPeriodTrees(data.total_trees);
				setPeriodOrders(data.total_transactions);
				setChartData(data.trees_per_day.slice(0, datePeriod(from, to).length - 1));
				setChartCate(datePeriod(from, to));
			});
		}
	}, [businessId, isPublic, from, to]);

	const handleModalClose = () => {
		setTimeout(() => {
			setCelebrate(false);
		});
		setPlant(false);
	};

	useEffect(() => {
		if (isSubscriptionActive === 'false') {
			if (isPublic) {
				window.location.replace('https://ecodrive.community');
			} else {
				navigate('/client-resources');
			}
		}
	}, [isSubscriptionActive, navigate]);

	const plantTrees = (event) => {
		event.preventDefault();
		setStatus(true);
		const customTreesFormData = new FormData();
		customTreesFormData.append('trees', amount);
		customTreesFormData.append('type', 'custom');
		postCustomTrees(customTreesFormData)
			.then((response) => {
				toast.success(
					`${amount} tree${amount === '1' ? '' : 's'} ${
						response.message || 'planted successfully'
					}`,
				);
				setStatus(false);
				setCelebrate(true);
			})
			.catch(() => {
				toast.error('Failed to add extra trees');
				setStatus(false);
			});
	};

	const chartOptions = {
		title: {
			text: '',
		},
		chart: {
			backgroundColor: 'transparent',
		},
		series: [
			{
				name: 'Trees',
				data: chartData,
				color: '#73e47d',
				marker: {
					radius: 0,
				},
			},
		],
		xAxis: {
			tickInterval: 1,
			tickWidth: 0,
			gridLineWidth: 0,
			categories: chartCate,
			gridLineColor: 'rgba(237, 230, 204, 0.36)',
			lineColor: 'rgba(237, 230, 204, 0.36)',
			type: '',
			angle: 30,
		},
		yAxis: {
			title: {
				text: 'Trees Planted',
				style: {
					color: '#FFFFFF',
				},
			},
			gridLineColor: 'rgba(237, 230, 204, 0.36)',
		},
		credits: {
			enabled: false,
		},
	};

	const filterFrom = (date) => {
		setFrom(date);
		fetchTrees({
			businessId: business.id,
			query: {
				from: formatDate(date),
				to: formatDate(to),
			},
		}).then((data) => {
			console.log(date, to);
			setChartData(data.trees_per_day.slice(0, datePeriod(date, to).length - 1));
			setChartCate(datePeriod(date, to));
		});
	};

	const filterTo = (date) => {
		setTo(date);
		fetchTrees({
			businessId: business.id,
			query: {
				from: formatDate(from),
				to: formatDate(date),
			},
		}).then((data) => {
			setChartData(data.trees_per_day.slice(0, datePeriod(from, date).length - 1));
			setChartCate(datePeriod(from, date));
		});
	};

	let slides = [
		generateCarosuel1(Carousel1),
		generateCarosuel2(Carousel2),
		generateCarosuel3(Carousel3),
	];

	// useEffect(() => {
	//   if (Object.keys(business).length > 0) {
	//     setUrl(business.url);
	//     setMilestones(JSON.parse(business.milestones))
	//     setTreesPlanted(business.planted_trees + business.free_trees + business.extra_trees + business.custom_planted_trees);
	//     setFminDate(new Date(business.created_at))
	//   }

	//   if(Object.keys(trees).length > 0) {
	//     setPeriodTrees(trees.total_trees)
	//     setPeriodOrders(trees.total_orders)
	//     setChartData(treesPeriod(from, to, trees.trees_per_day));
	//     setChartCate(datePeriod(from, to))
	//   }
	// }, [business, trees])

	const treesPlanted = transactions.reduce(
		(acc, proj) => acc + parseInt(proj.treesOrdered),
		0,
	);

	return (
		<ClientLayout>
			<div className='public-dashboard'>
				<Modal
					className='plant-extra'
					show={plant}
					onHide={handleModalClose}
					centered
				>
					<Modal.Header closeButton>
						{!celebrate ? (
							<>
								<Form className='z-1' onSubmit={(event) => plantTrees(event)}>
									<div className='position-relative'>
										{amount === 100 && <img src={Tree100} alt='welcome' />}
										{amount === 1000 && <img src={Tree1000} alt='welcome' />}
										{amount !== 100 && amount !== 1000 && (
											<>
												<img src={TreeCustom} alt='welcome' />
												<Form.Group className='mb-3 custom-trees'>
													<InputGroup className='mb-3'>
														<InputGroup.Text>Trees:</InputGroup.Text>
														<Form.Control
															value={amount}
															onChange={(event) => setAmount(event.target.value)}
															required
														/>
													</InputGroup>
												</Form.Group>
											</>
										)}
									</div>
									<Form.Group className='mb-3 px-4'>
										<Form.Check type='checkbox'>
											<Form.Check.Input type='checkbox' required />
											<Form.Check.Label>
												Please check this box to confirm you want to plan {amount} trees for
												${(amount * activeProject?.price).toFixed(2)}.&nbsp;This charge will
												be reflected on your next billing statement per out{' '}
												<Button
													variant='link'
													href='https://ecodrive.community/policies/terms-of-service'
													target='_blank'
												>
													Terms of Use
												</Button>
												.
											</Form.Check.Label>
										</Form.Check>
									</Form.Group>
									<Row>
										<Col className='action-buttons'>
											<Button
												className='button mx-2 button-primary'
												onClick={() => handleModalClose()}
											>
												Cancel
											</Button>
											<Button className='button mx-2 button-secondary' type='submit'>
												{!status ? <>Confirm</> : <Spinner animation='border' />}
											</Button>
										</Col>
									</Row>
								</Form>
							</>
						) : (
							<>
								<img src={Celebrate} alt='Celebrate' />
								<p>Your order is confirmed, we'll get planting!</p>
								<Row>
									<Col className='action-buttons'>
										<Button
											className='button mx-2 button-secondary'
											onClick={handleModalClose}
										>
											Done
										</Button>
									</Col>
								</Row>
							</>
						)}
						<img className='b-layer' src={Layer2} alt='layer' />
					</Modal.Header>
				</Modal>
				<div className='dashboard-banner'>
					<img src={Background} alt='background' />
					<div className='overlay' />
				</div>
				<div className='dashboard-header'>
					<Navbar expand='lg'>
						<Navbar.Brand href='https://ecodrive.community'>
							<Logo />
						</Navbar.Brand>
						<Navbar.Toggle aria-controls='navbar-nav' />
						<Navbar.Collapse id='navbar-nav' className='justify-content-end'>
							<Nav>
								{!isPublic && (
									<>
										<Nav.Link
											onClick={() => navigate('/partner-dashboard', { replace: true })}
										>
											<span>Planet Partners</span>
										</Nav.Link>
										<Nav.Link
											className='active'
											onClick={() => navigate('/client-dashboard', { replace: true })}
										>
											<span>Dash</span>
										</Nav.Link>
										<Nav.Link
											onClick={() => navigate('/client-resources', { replace: true })}
										>
											<span>Resources</span>
										</Nav.Link>
										<Nav.Link
											className='public-dash'
											onClick={() => {
												window.open(
													`/public-dashboard/${shopSlug || business.id}`,
													'_blank',
												);
											}}
										>
											<span>Public Dash</span>
										</Nav.Link>
									</>
								)}
								{isPublic && (
									<Nav.Link
										href={'https://brands.ecodrive.community/contact'}
										className='back-to-site'
									>
										<span>Join Ecodrive</span>
									</Nav.Link>
								)}
								{isPublic && logoLink && (
									<Nav.Link href={logoLink} className='back-to-site'>
										<span>Back to site -&gt;</span>
									</Nav.Link>
								)}
								{!isPublic && (
									<>
										<Nav.Link onClick={() => navigate('/settings', { replace: true })}>
											<span>
												<Setting width='18' height='18' />
											</span>
										</Nav.Link>
										<Nav.Link onClick={() => logout()}>
											<span>
												<Logout width='16' height='16' />
											</span>
										</Nav.Link>
									</>
								)}
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</div>
				<Container className='dashboard-content'>
					<Row className='positive-impact'>
						<Col className='d-flex flex-column align-items-center'>
							{logoLink ? (
								<a target='_blank' href={logoLink} rel='noreferrer'>
									<img className='logo' src={logo} alt='logo' />
								</a>
							) : (
								<img className='logo' src={logo} alt='logo' />
							)}
							<h1 className='logo-text'>{headerText}</h1>
							<p>{subText}</p>
							<div className='impact-status'>
								<div className='status-item'>
									<span className='status-logo'>
										<Days />
									</span>
									<p>{Intl.NumberFormat().format(treesPlanted / 100)}</p>
									<label>Work Days Created</label>
								</div>
								<div className='status-item status-active'>
									<span className='status-logo'>
										<Lifetime />
									</span>
									<p>{Intl.NumberFormat().format(treesPlanted)}</p>
									<label>Lifetime Trees Planted</label>
								</div>
								<div className='status-item'>
									<span className='status-logo'>
										<LBS />
									</span>
									<p>{Intl.NumberFormat().format(treesPlanted * 680)}</p>
									<label>
										Lbs of CO2 removed<small>over tree's lifetime</small>
									</label>
								</div>
							</div>
							<TreeStats projects={transactions} active={activeProject} />
						</Col>
					</Row>
					{!isPublic && (
						<Row className='tree-milestones' style={{ marginTop: '100px' }}>
							<Col className='d-flex flex-column align-items-center'>
								<h2>
									<span>Tree</span> Milestones
								</h2>
								<div className='milestones-container'>
									<label>Your Next Milestone!</label>
									{milestones[0] > treesPlanted && (
										<div className='milestone-steps'>
											<p>
												{Intl.NumberFormat().format(treesPlanted)} /{' '}
												{Intl.NumberFormat().format(milestones[0])}
											</p>
											<ProgressBar now={(treesPlanted * 100) / milestones[0]} />
										</div>
									)}
									{milestones[1] > treesPlanted && (
										<div className='milestone-steps'>
											<p>
												{Intl.NumberFormat().format(treesPlanted)} /{' '}
												{Intl.NumberFormat().format(milestones[1])}
											</p>
											<ProgressBar now={(treesPlanted * 100) / milestones[1]} />
										</div>
									)}
									{milestones[2] > treesPlanted && (
										<div className='milestone-steps'>
											<p>
												{Intl.NumberFormat().format(treesPlanted)} /{' '}
												{Intl.NumberFormat().format(milestones[2])}
											</p>
											<ProgressBar now={(treesPlanted * 100) / milestones[2]} />
										</div>
									)}
								</div>
							</Col>
						</Row>
					)}

					{activeProject?.projectKey && (
						<VirtualTree project={activeProject.projectKey} />
					)}
				</Container>
				{!isPublic && (
					<Container fluid className='dashboard-content trophy-case'>
						<Leap />
						<Container>
							<Row className='trophy-content'>
								<Col className='d-flex flex-column align-items-center'>
									<h2>Your Trophy Case</h2>
									<p>Awards and badges for you, plant more trees unlock more rewards!</p>
									<div className='trophy-choices'>
										<div className='badge-container' ref={badge1}>
											<div className='badge-item badge1'>
												<div className='brand'>
													<Brand width='61px' height='91px' />
												</div>
												<div className='trees-planted'>
													<p>{Intl.NumberFormat().format(treesPlanted)}</p>
													<label>Trees Planted</label>
													<p>{Intl.NumberFormat().format(treesPlanted / 100)}</p>
													<label>Work days Created</label>
												</div>
												<div
													className='download'
													onClick={() => {
														exportComponentAsPNG(badge1, {
															fileName: 'Badge.png',
															html2CanvasOptions: { backgroundColor: null },
														});
													}}
												>
													<Download />
												</div>
											</div>
										</div>
										<div className='badge-container' ref={badge2}>
											<div className='badge-item badge2'>
												<p>
													We're a carbon <span>positive</span> business.
												</p>
												<div className='brand'>
													<Brand width='12px' height='18px' />
												</div>
												<div className='leaf'>
													<Carbon width='115px' height='96px' />
													<div className='trees'>
														<p>{Intl.NumberFormat().format(treesPlanted)}</p>
														<label>Trees Planted</label>
													</div>
												</div>
												<div
													className='download'
													onClick={() => {
														exportComponentAsPNG(badge2, {
															fileName: 'Badge.png',
															html2CanvasOptions: { backgroundColor: null },
														});
													}}
												>
													<Download />
												</div>
											</div>
										</div>
										<div className='badge-container' ref={badge3}>
											<div className='badge-item badge3'>
												<img src={layer} alt='layer' />
												<div className='business'>
													<div className='leaf'>
														<GreenLeaf width='78px' height='78px' />
													</div>
													<div className='climate'>
														<h3>
															Climate Positive
															<br />
															Business with Ecodrive
														</h3>
													</div>
												</div>
												<div className='logo'>
													<Logo width='128px' height='26px' />
												</div>
												<div
													className='download'
													onClick={() => {
														exportComponentAsPNG(badge3, {
															fileName: 'Badge.png',
															html2CanvasOptions: { backgroundColor: null },
														});
													}}
												>
													<Download />
												</div>
											</div>
										</div>
									</div>
									<ReachGoal />
								</Col>
							</Row>
						</Container>
					</Container>
				)}

				{activeProject && <DashboardContent projectId={activeProject.id} />}

				<Container fluid className='dashboard-content carousel-wrapper'>
					<Leap />
					<Container>
						<Row className='carousel-content'>
							<Col xs={12}>
								<Carousel slides={slides} autoplay={false} />
							</Col>
							<Col
								xs={12}
								className='d-flex align-items-center justify-content-center'
							>
								<Button
									variant='link'
									className='dashboard-button'
									href='https://blog.ecodrive.community'
									target='_blank'
								>
									Read More
								</Button>
							</Col>
						</Row>
					</Container>
				</Container>

				{isPublic ? (
					<>
						<Container fluid className='dashboard-content want-to-plant'>
							<Row>
								<Col>
									<div className='plant-wrapper'>
										<div className='position-relative'>
											<h2>Want to plant your own trees?</h2>
											<p>Plant trees to offset a flight, driving mileage and more!</p>
											<HelpArrow />
										</div>
										<Button
											href='https://ecodrive.community/pages/carbon-footprint#plantATree'
											target='_blank'
										>
											Click here to plant
										</Button>
									</div>
								</Col>
							</Row>
						</Container>

						<Container fluid className='dashboard-content dashboard-footer'>
							<Row>
								<Col>
									<Row className='footer-content'>
										<Col lg={5} className='copyright'>
											{/* <span>All rights reserved 2022</span> */}
										</Col>
										<Col lg={2} className='text-center'>
											<Logo />
										</Col>
										<Col lg={5} className='footer-links'>
											{/* <Button variant="link">Home</Button>
                    <Button variant="link">Search</Button>
                    <Button variant="link">Contact</Button> */}
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</>
				) : (
					<>
						<Container fluid className='brand-dashboard-footer'>
							<Container>
								<Row>
									<Col md={4} sm={12}>
										<Logo width='131px' fill='white' />
										<div className='social-wrapper'>
											<Button
												variant='link'
												className='social-item'
												href='https://www.instagram.com/ecodrive/'
											>
												<Instagram />
											</Button>
											<Button
												variant='link'
												className='social-item'
												href='https://www.linkedin.com/company/ecodriveventures/'
											>
												<Linkedin />
											</Button>
											<Button
												variant='link'
												className='social-item'
												href='https://www.pinterest.com/ecodrivecommunity/'
											>
												<Pinterest />
											</Button>
											<Button
												variant='link'
												className='social-item'
												href='https://ecodrive.community/'
											>
												<WebsiteIcon />
											</Button>
										</div>
										<p>Need help?</p>
										<Button
											variant='link'
											target='_blank'
											href='mailto:support@ecodrive.community'
										>
											support@ecodrive.community
										</Button>
									</Col>
									<Col md={4} sm={12} className='d-flex flex-column align-items-start'>
										<Link className='btn-link' to={'/client-resources'}>
											Home
										</Link>
										<Button
											variant='link'
											target='_blank'
											href='https://blog.ecodrive.community'
										>
											Blog
										</Button>
										<Button
											variant='link'
											target='_blank'
											href='https://brands.ecodrive.community/tree/plant.html'
										>
											More about Trees
										</Button>
										<Button
											variant='link'
											target='_blank'
											href='https://brands.ecodrive.community/contact'
										>
											Join Ecodrive
										</Button>

										<Button
											variant='link'
											target='_blank'
											href='https://apps.shopify.com/ecodrive'
										>
											Install the app
										</Button>
									</Col>
									<Col md={4} sm={12} className='d-flex flex-column align-items-start'>
										<Link className='btn-link' to={'/settings'}>
											Settings
										</Link>
										<Link className='btn-link' to={'/partner-dashboard'}>
											Planet partners
										</Link>
										<Link className='btn-link' to={'/client-dashboard'}>
											Internal Dash
										</Link>
										<Button
											variant='link'
											target='_blank'
											href='https://ecodrive.community/policies/privacy-policy'
										>
											Privacy policy
										</Button>
										<Button
											variant='link'
											target='_blank'
											href='https://ecodrive.community/policies/terms-of-service'
										>
											Terms of Use
										</Button>
									</Col>
								</Row>
							</Container>
						</Container>

						<Container>
							<Row className='copyright'>
								<Col className='d-flex align-items-center justify-content-between'>
									<span>All rights reserved 2022</span>
									<div>
										<Button
											variant='link'
											target='_blank'
											href='https://donors.edenprojects.org/user/ecodrive/'
										>
											Proof of planting
										</Button>
										<Button
											variant='link'
											target='_blank'
											href='https://ecodrive.community/policies/terms-of-service'
										>
											Terms & Conditions
										</Button>
										<Button
											variant='link'
											target='_blank'
											href='https://ecodrive.community/policies/privacy-policy'
										>
											Privacy Policy
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</>
				)}
			</div>
		</ClientLayout>
	);
};

export default ClientPublicDashboard;
