import { useEffect, useState } from 'react';

/**
 *
 * @param {string} key The localStorage key to access.
 * @param {*} value Use this argument if you want to set a value to the provided key.
 * @returns {string} Returns the localStorage value as a string. If the value is an object, you'd need to parse it.
 */
const useLocalStorage = (key) => {
	const [state, setState] = useState(localStorage.getItem(key));

	useEffect(() => {
		setState(localStorage.getItem(key));
	}, [key]);

	return state;
};

export default useLocalStorage;
