/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "./index.scss";
import { Upload as UploadSVG } from "../icons";
import { ecodriveApi } from "../../libs/axios";

const Upload = ({ setLogo, logo, setStatus, setLogoUpload, businessId }) => {
  const [file, setFile] = useState();
  const [, setExt] = useState("");

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
    let extension = event.target.files[0].name.split(".").pop();
    if (extension === "svg") extension = "svg+xml";
    setExt(extension);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("businessId", businessId);
    setStatus(true);
    setLogoUpload(true);

    ecodriveApi
      .post("/file-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        setLogo(`${response.data.file}`);
        setStatus(false);
        setLogoUpload(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Allowed file types are png, and svg");
        setStatus(false);
      });
  };

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  return (
    <div className="file-input">
      <input type="file" id="file" className="file" onChange={changeHandler} />
      <label htmlFor="file">
        {logo && <img alt="logo" src={logo} />}
        {!logo && <UploadSVG />}
        {file && <p className="file-name">{file.name}</p>}
      </label>
      <p>Click to upload or drag and drop your logo here</p>
    </div>
  );
};

export default Upload;
