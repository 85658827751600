import { Navigate, Route, Routes } from 'react-router-dom';
import Billing from './billing';
import BillingEdit from './billingedit';
import BillingAdd from './billingAdd';
import Integrations from './integrations';
import Members from './members';
import Password from './password';
import Profile from './profile';
import BusinessProfile from './businessProfile';
import PublicDash from './public';
import TreesTemplate from './treestemplate';
import ProjectMarketplace from './projectMarketplace';
import ManageApiKeys from './manageApiKeys';

const Settings = () => {
	return (
		<Routes>
			<Route path='*' element={<Navigate replace to='/settings/public-dash' />} />
			<Route path='/public-dash' element={<PublicDash />} />
			<Route path='/integrations' element={<Integrations />} />
			<Route path='/project-marketplace' element={<ProjectMarketplace />} />
			<Route path='/trees' element={<TreesTemplate />} />
			<Route path='/members' element={<Members />} />
			<Route path='/profile' element={<Profile />} />
			<Route path='/business-profile' element={<BusinessProfile />} />
			<Route path='/billing' element={<Billing />} />
			<Route path='/billing/edit' element={<BillingEdit />} />
			<Route path='/billing/add' element={<BillingAdd />} />
			<Route path='/password' element={<Password />} />
			<Route path='/manage-api-keys' element={<ManageApiKeys />} />
		</Routes>
	);
};

export default Settings;
