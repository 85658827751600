import React from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/styles';

import SideColumn from './SideColumn';
import { Close } from '../icons';
import LeftFooter from './LeftFooter';

import './Modal.scss';

const ButtonContainer = styled('div')({
	position: 'absolute',
	bottom: '28px',
	right: '28px',
});

const Modal = ({
	isOpen,
	onCloseModal,
	description,
	image,
	title,
	labels,
	isActive,
	headerText = 'Trees Per Action',
	subheaderText = 'Update how many trees you want to plant per action.',
	children,
	buttonText,
	isButtonDisabled = false,
	onButtonClicked,
	isButtonHidden,
	isLoading = false,
	hideCloseButton = false,
	hideHeaderText = false,
}) => {
	const CloseButton = (
		<div onClick={onCloseModal} className='custom-modal__close-container'>
			<div className='custom-modal__close'>
				<Close />
			</div>
		</div>
	);
	return isOpen ? (
		<div className='custom-modal-container'>
			<div className='custom-modal-backdrop' onClick={onCloseModal}></div>
			<div className='custom-modal'>
				{!hideCloseButton && CloseButton}
				<div className='custom-modal__left-container'>
					<SideColumn
						image={image}
						title={title}
						description={description}
						labels={labels}
						isActive={isActive}
					/>
					<LeftFooter closeCallback={onCloseModal} />
				</div>
				<div className='custom-modal__right-container'>
					{!hideHeaderText && (
						<div className='custom-modal__right-header-container'>
							{headerText && (
								<h3 className='custom-modal__right-header'>{headerText}</h3>
							)}
							{subheaderText && (
								<p className='custom-modal__right-subheader'>{subheaderText}</p>
							)}
						</div>
					)}
					{children}
					{!isButtonHidden && !hideCloseButton && (
						<ButtonContainer>
							<LoadingButton
								disabled={isButtonDisabled}
								loading={isLoading}
								onClick={onButtonClicked}
								variant='contained'
								fullWidth={false}
							>
								<span>{buttonText}</span>
							</LoadingButton>
						</ButtonContainer>
					)}
				</div>
			</div>
		</div>
	) : null;
};
export default Modal;
