import { useState, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';

import ProjectCard from '../../../../components/projectCard';
import ActiveProjectCard from '../../../../components/activeProjectCard';

import SettingsLayout from '../layout';

import './index.scss';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { createDecorators } from '../../../../components/projects/RichEditor';
import { mediaBlockRenderer } from '../../../../components/projects/RichEditor/Entities/MediaEntity';
import { Button, Modal, Spinner } from 'react-bootstrap';
import useFetch from '../../../../hooks/useFetch';
import useToast from '../../../../hooks/useToast';
import { useSearchParams } from 'react-router-dom';
import { ecodriveApi } from '../../../../libs/axios';
import handleAxiosError from '../../../../utils/handleAxiosError';

const ProjectMarketplace = () => {
	const [loading, setLoading] = useState(false);

	// Get single project by id
	const [query, setQuery] = useSearchParams();
	const queryProject = useMemo(() => query.get('project'), [query]);
	const { data: project } = useFetch(
		queryProject ? '/planting-projects/' + queryProject : undefined,
		{ reset: true },
	);

	// Get and handle all projects list
	const { error: listError, data: list } = useFetch('/planting-projects', {
		params: {
			filtered: true,
		},
	});
	useToast(handleAxiosError(listError, 'Error listing planting projects'));

	// Get and handle active project
	const activeRef = useRef(1);
	const { error: activeError, data: activeProject } = useFetch(
		'/planting-projects/active',
		{ ref: activeRef },
	);
	useToast(handleAxiosError(activeError, 'Error finding active project'));

	// Update active project
	const handleProjectChange = async () => {
		setLoading(true);
		try {
			await ecodriveApi.put('/business/project', {
				id: project.id,
				projectKey: project.projectKey,
			});
			toast.success('Default project updated');

			// Change ref to trigger Get Active Project again
			activeRef.current = activeRef.current + 1;
		} catch (e) {
			if (e.response.data.message) {
				toast.error(e.response.data.message);
			} else {
				toast.error('There was an error setting your default project');
			}
		}
		setQuery();
		setLoading(false);
	};

	const sortProjects = (a, b) => new Date(a.created_at) - new Date(b.created_at);
	const projectCards = (data) => {
		if (data.id === activeProject?.id) return null;
		return (
			<ProjectCard
				key={data.id}
				platformName={data.title}
				img={data.thumbnail}
				header={data.title}
				text={data.preview}
				copy={data.preview}
				onClick={() => {
					setQuery({ project: data.id });
				}}
			/>
		);
	};

	const editorState = useMemo(() => {
		if (project) {
			const rawContent = JSON.parse(project.content);
			return EditorState.createWithContent(
				convertFromRaw(rawContent),
				createDecorators(),
			);
		}
		return EditorState.createEmpty();
	}, [project]);

	const LoadingBlock = (
		<div className='text-center w-100'>
			{[1, 2, 3].map((el) => (
				<Spinner
					key={el}
					style={{ animationDelay: el * 100 + 'ms' }}
					animation='grow'
					className='mx-2'
				/>
			))}
		</div>
	);

	return (
		<SettingsLayout title='Project Marketplace'>
			<Modal size='lg' onHide={() => setQuery()} show={project}>
				{project && (
					<>
						<Modal.Header>
							<h2>{project.title}</h2>
						</Modal.Header>
						<Modal.Body>
							<Editor
								blockRendererFn={mediaBlockRenderer}
								editorState={editorState}
								readOnly
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button
								onClick={handleProjectChange}
								style={{ width: '100%' }}
								disabled={loading || project.id === activeProject?.id}
								variant={project.id === activeProject?.id ? 'secondary' : 'success'}
							>
								{project.id === activeProject?.id
									? 'CURRENTLY ACTIVE'
									: 'SET AS DEFAULT'}
							</Button>
						</Modal.Footer>
					</>
				)}
			</Modal>
			<div className='project-marketplace-container'>
				<div className='project-marketplace-header'></div>
				<div className='project-marketplace-body'>
					{activeProject && (
						<div className='project-marketplace-active-project'>
							<ActiveProjectCard
								key={activeProject.id}
								projectName={activeProject.title}
								img={activeProject.banner}
								header={activeProject.title}
								text={activeProject.preview}
								copy={activeProject.preview}
								onClick={() => setQuery({ project: activeProject.id })}
								isActive={true}
							/>
						</div>
					)}
					{list ? (
						<div className='project-marketplace-cards'>
							{list.sort(sortProjects).map(projectCards)}
						</div>
					) : (
						LoadingBlock
					)}
				</div>
			</div>
		</SettingsLayout>
	);
};

export default ProjectMarketplace;
