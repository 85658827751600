import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const HandeDeleteModal = ({ onClose, forDeletion }) => {
	const [deleteId, setDeleteId] = useState('');

	const handleClose = () => {
		onClose();
		setDeleteId('');
	};
	return (
		<Modal show={forDeletion} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Delete project</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<Form.Label>
						Are you sure you want to delete {forDeletion}?
						<br /> Type the Project Key below to confirm.
					</Form.Label>
					<Form.Control
						value={deleteId}
						onChange={(e) => setDeleteId(e.target.value)}
						type='text'
					/>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={handleClose}>
					Close
				</Button>
				<Button
					disabled={deleteId !== forDeletion}
					variant={deleteId === forDeletion ? 'danger' : 'secondary'}
					onClick={() => {
						toast.error('Delete not implemented yet');
					}}
				>
					Delete
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default HandeDeleteModal;
