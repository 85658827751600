import React from 'react';
import './SideColumn.scss';

const SideColumn = ({ image, title, description, labels, isActive }) => {
	return (
		<div className='side-column w-100'>
			<div className='d-flex justify-content-between align-items-center'>
				<div className='side-column__image-container'>
					<img src={image} className='w-75' alt={title} />
				</div>
				<span
					className={`fs-7 fw-bold py-2 rounded badge ${
						isActive ? 'badge-success text-white' : 'badge-danger'
					}`}
				>
					{isActive ? 'ACTIVE' : 'INACTIVE'}
				</span>
			</div>
			<div className='pt-4 fs-3 lh-MD text-dark'>{title}</div>
			<div className='side-column__description mt-2 mb-2 fw-bolder'>
				{description}
			</div>
			<div className='mt-2 mb-0'>
				<div className='side-column__events-title fw-bolder text-dark'>
					Events Supported
				</div>
				<div className='side-column__events-subtitle fw-bolder'>
					You are able to plant a tree using this integration whenever one of the
					following happens:
				</div>
				{labels && (
					<ul className='ps-2 mt-1 side-column__event-list'>
						{labels.map((label) => (
							<li key={label} className='side-column__event-list-item fw-bolder'>
								{label}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

export default SideColumn;
