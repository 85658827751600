import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useId from './useId';

const useToast = (message, _options = { type: 'error' }) => {
	const id = useId();
	const memoizedOptions = JSON.stringify(_options);
	useEffect(() => {
		const options = JSON.parse(memoizedOptions);
		if (message) {
			toast(message, { toastId: id, ...options });
		}
	}, [id, message, memoizedOptions]);
};

export default useToast;
