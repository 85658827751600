import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
	Dashboard,
	Logo,
	Logout,
	Person,
	Resource,
	Setting,
	Brand,
} from '../icons';

import './index.scss';
import { useLogout } from '../../hooks/useAuth';
import { useEcodrive } from '../../context/store';

const Sidebar = ({ title, show, setShow }) => {
	const logout = useLogout();
	const [{ business }] = useEcodrive();
	const isSubscriptionActive = business.subscription?.active;

	return (
		<div className={`settings-sidebar-container ${show ? 'active' : ''}`}>
			<div className='settings-sidebar'>
				<div>
					<Link to='/' className='logo'>
						<Logo width='145' height='30' />
					</Link>
					<ul>
						<li>
							<Link to='/client-dashboard' className='menu-group-title'>
								<Dashboard width='18' height='18' />
								Dashboard
							</Link>
						</li>
						<li>
							<Link to='/client-resources' className='menu-group-title'>
								<Resource width='18' height='18' />
								Resources
							</Link>
						</li>
						<li className='menu-group'>
							<p className='menu-group-title'>
								<Setting width='18' height='18' />
								Workspace
							</p>
							<ul>
								<li className={`menu-item ${title === 'Public Dash' && 'active'}`}>
									<Link to='/settings/public-dash'>Public Dash</Link>
								</li>
								{isSubscriptionActive && (
									<>
										<li className={`menu-item ${title === 'Integrations' && 'active'}`}>
											<Link to='/settings/integrations'>Integrations</Link>
										</li>
									</>
								)}
								{/* <li className={`menu-item ${title === 'Integrations' && 'active'}`}><Link to='/settings/integrations'>Integrations</Link></li> */}
								<li
									className={`menu-item ${title === 'Project Marketplace' && 'active'}`}
								>
									<Link to='/settings/project-marketplace'>Project Marketplace</Link>
								</li>

								{/* <li className={`menu-item ${title === 'Integrations' && 'active'}`}><Link to='/settings/integrations'>Integrations</Link></li> */}
								{/* <li className={`menu-item ${title === 'Trees' && 'active'}`}><Link to='/settings/trees'>Trees</Link></li> */}
								{/* <li className={`menu-item ${title === 'Members' && 'active'}`}><Link to='/settings/members'>Members</Link></li> */}
							</ul>
						</li>
						<li className='menu-group'>
							<p className='menu-group-title'>
								<Person width='18' height='18' />
								Person
							</p>
							<ul>
								<li className={`menu-item ${title === 'Profile' && 'active'}`}>
									<Link to='/settings/profile'>Profile</Link>
								</li>
								<li className={`menu-item ${title === 'Password' && 'active'}`}>
									<Link to='/settings/password'>Password</Link>
								</li>
							</ul>
						</li>
						<li className='menu-group'>
							<p className='menu-group-title'>
								<Brand width='18' height='18' />
								Business
							</p>
							<ul>
								<li className={`menu-item ${title === 'Business Profile' && 'active'}`}>
									<Link to='/settings/business-profile'>Business Profile</Link>
								</li>
								<li className={`menu-item ${title === 'Billing' && 'active'}`}>
									<Link to='/settings/billing'>Billing</Link>
								</li>
								<li className={`menu-item ${title === 'Manage Keys' && 'active'}`}>
									<Link to='/settings/manage-api-keys'>Manage Keys</Link>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<Button className='btn-logout' variant='link' onClick={() => logout()}>
					<Logout width='12' height='12' />
					Log out
				</Button>
			</div>

			<div
				className='settings-sidebar-overlay'
				onClick={() => {
					setShow(false);
				}}
			></div>
		</div>
	);
};

export default Sidebar;
