import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	ButtonGroup,
	Card,
	Dropdown,
	Form,
	InputGroup,
} from 'react-bootstrap';
import * as Yup from 'yup';
const defaultHelper = 'Enter a valid URL';
const Link = ({ onClick }) => {
	const ref = useRef();
	const [open, toggle] = useState(false);
	useEffect(() => {
		if (open) {
			ref.current.focus();
		}
	}, [open]);

	const [helper, setHelper] = useState({
		message: defaultHelper,
		error: false,
	});

	const handleError = (e) => {
		setTimeout(() => {
			setHelper({
				message: defaultHelper,
				error: false,
			});
		}, 1200);
		setHelper({
			message: e.message,
			error: true,
		});
	};

	const [link, setLink] = useState('');
	const handleClick = () => {
		Yup.string()
			.required('Please enter a valid URL')
			.validate('http://' + link)
			.then(() => onClick(link))
			.then(() => setLink(''))
			.then(() => toggle(false))
			.catch(handleError);
	};

	return (
		<Dropdown as={ButtonGroup} onToggle={() => toggle(!open)} show={open}>
			<Dropdown.Toggle variant='light' size='sm'>
				<i className='bi bi-link-45deg'></i>
			</Dropdown.Toggle>
			<Dropdown.Menu className='p-0 border-0' style={{ minWidth: 240 }}>
				<Card style={{ width: '20rem' }}>
					<Card.Body>
						<InputGroup size='sm'>
							<InputGroup.Text>https://</InputGroup.Text>
							<Form.Control
								ref={ref}
								className='py-1 '
								placeholder='URL'
								aria-label='url'
								value={link}
								onChange={(e) => setLink(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') handleClick();
								}}
							/>
						</InputGroup>
						<Form.Text className={helper.error && 'text-danger'}>
							{helper.message}
						</Form.Text>
					</Card.Body>
					<Card.Footer className='d-grid'>
						<Button variant='success' size='sm' onClick={handleClick}>
							Save
						</Button>
					</Card.Footer>
				</Card>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default Link;
