import Payment from 'payment';

function clearNumber(value = '') {
	return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
	if (!value) {
		return value;
	}

	const issuer = Payment.fns.cardType(value);
	const clearValue = clearNumber(value);
	let nextValue;

	switch (issuer) {
		case 'amex':
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				10,
			)} ${clearValue.slice(10, 15)}`;
			break;
		case 'dinersclub':
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				10,
			)} ${clearValue.slice(10, 14)}`;
			break;
		default:
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				8,
			)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
			break;
	}

	return nextValue.trim();
}

export function formatCVC(value, number = {}) {
	const clearValue = clearNumber(value);
	let maxLength = 4;

	if (number) {
		const issuer = Payment.fns.cardType(number);
		maxLength = issuer === 'amex' ? 4 : 3;
	}

	return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
	const clearValue = clearNumber(value);

	if (clearValue.length >= 3) {
		return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
	}

	return clearValue;
}

export function formatFormData(data) {
	return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export const numberWithCommas = (num) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeHttp = (url) => {
	return url.replace(/(^\w+:|^)\/\//, '');
};

export const removeWWW = (url) => {
	if (url.startsWith('www.')) {
		const www = 'www.';
		return url.slice(www.length);
	} else {
		return url;
	}
};

export const formatDateTime = (time) => {
	const now = new Date(time);
	let year = now.getFullYear(),
		month = now.getMonth() + 1,
		day = now.getDate(),
		hour = now.getHours(),
		minute = now.getMinutes();

	minute = `0${minute}`;
	minute = minute.slice(-2);

	let t = 'am';
	if (hour > 12) {
		t = 'pm';
		hour = hour - 12;
	}

	return `${month}/${day}/${year} ${hour}:${minute} ${t}`;
};

export const dateSince = (time) => {
	const oldDate = new Date(time);
	const now = new Date();
	const timeCon = (t) => {
		let cd = 24 * 60 * 60 * 1000,
			ch = 60 * 60 * 1000,
			d = Math.floor(t / cd),
			h = Math.floor((t - d * cd) / ch);
		if (h === 24) {
			d++;
			h = 0;
		}
		if (d > 0) {
			return `${d}d ${h}h`;
		}
		return d > 0 ? `${d}d ${h}h` : `${h}h`;
	};

	return timeCon(now - oldDate);
};

export const formatDate = (date) => {
	return new Date(date).toISOString();
};

export const formatMonth = (date) => {
	const monthType = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const d = new Date(date),
		month = d.getMonth(),
		day = d.getDate();

	return [monthType[month], day].join(' ');
};

export const oneDay = 86400000;

export const totalDay = (from, to) => {
	return (new Date(formatDate(to)) - new Date(formatDate(from))) / oneDay + 1;
};

export const datePeriod = (from, to) => {
	const period = [];

	// eslint-disable-next-line no-extend-native
	Date.prototype.addHours = function (h) {
		const date = new Date(this.valueOf());
		date.setTime(this.getTime() + h * 60 * 60 * 1000);
		return date;
	};
	// eslint-disable-next-line no-extend-native
	Date.prototype.addDays = function (days) {
		const date = new Date(this.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	};

	let fromClone = new Date(from);
	let toClone = new Date(to);
	let thisOffset = from.getTimezoneOffset();
	if (thisOffset) {
		fromClone = fromClone.addHours(thisOffset / 60 + 1);
		toClone = toClone.addHours(thisOffset / 60 + 1);
		toClone = toClone.addDays(1);
	}

	let fromDate = new Date(formatDate(fromClone));
	let toDate = new Date(formatDate(toClone));
	if (thisOffset) {
		fromDate = fromDate.addHours(thisOffset / 60 + 1);
		toDate = toDate.addHours(thisOffset / 60 + 1);
	}
	for (let index = 0; index < totalDay(fromDate, toDate); index++) {
		period.push(formatMonth(fromDate.addDays(index)));
	}

	return period;
};

export const treesPeriod = (from, to, array) => {
	let fromClone = new Date(from);
	let toClone = new Date(to);
	let thisOffset = from.getTimezoneOffset();

	// eslint-disable-next-line no-extend-native
	Date.prototype.addHours = function (h) {
		this.setTime(this.getTime() + h * 60 * 60 * 1000);
		return this;
	};
	// eslint-disable-next-line no-extend-native
	Date.prototype.addDays = function (days) {
		const date = new Date(this.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	};

	if (thisOffset) {
		fromClone.addHours(thisOffset / 60 + 1);
		toClone.addHours(thisOffset / 60 + 1);
		toClone = toClone.addDays(1);
	}

	return array.splice(0, totalDay(fromClone, toClone));
};

export const validatePassword = (thisString = '') => {
	let isEnoughChars = thisString.length >= 8;
	let hasLetters = /[a-z]/.test(thisString);
	let hasNumbers = /[0-9]/.test(thisString);

	return isEnoughChars && hasLetters && hasNumbers;
};

export const disallowWhitespace = (thisString = '') => {
	if (thisString === '') return thisString;
	if (thisString.trim()) return thisString.trim();
	return '';
};

const specialCharsRegex = /[0-9a-zA-Z@]+/;

export const disallowOnlySpecialCharacters = (
	thisString = '',
	previousValue = '',
) => {
	if (thisString === '') return thisString;
	if (specialCharsRegex.test(thisString)) return thisString;
	return previousValue;
};

const lettersRegex = /^[a-zA-Z]*$/;
export const allowOnlyLetters = (thisString = '', previousValue = '') => {
	if (thisString === '') return thisString;
	if (lettersRegex.test(thisString)) return thisString;
	return previousValue;
};

export const basicValidation = (thisString = '', previousValue = '') => {
	if (!thisString) return '';
	if (specialCharsRegex.test(thisString)) {
		return thisString.trim();
	} else {
		return previousValue;
	}
};

const urlRegex = /[0-9a-zA-Z/:\-_%?&=.!*#@$^()<>]+/g;

const numbersRegex = /^[0-9]*$/;

export const onlyAllowNumbers = (thisString = '', previousValue = '') => {
	if (thisString === '') return thisString;
	if (numbersRegex.test(thisString)) return thisString;
	return previousValue;
};

export const urlValidation = (thisString = '') => {
	if (thisString === '') {
		return '';
	}
	const matched = thisString.match(urlRegex);
	const newValue = matched ? matched.join('') : '';
	return newValue;
};
