import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useBetween } from 'use-between';
import useAuth from '../useAuth';
import { toast } from 'react-toastify';
import withErrorHandling from '../../../components/withErrorHandling';
import '../index.scss';
import { Logo } from '../../../components/icons';
import { AuthLayout } from '../../../lauyouts';

const Forgot = () => {
	let navigate = useNavigate();
	const { forgot } = useBetween(useAuth);

	const [error, setError] = useState('');
	const [email, setEmail] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();

		forgot(email)
			.then((response) => {
				if (
					response.msg ===
					'An Email has been sent for password reset, reset in an hour!'
				) {
					toast.success('A password reset link has been sent to your email!');
				} else {
					toast.error(response.msg);
				}
			})
			.catch((error) => {
				setError(error);
			});
	};

	return (
		<AuthLayout>
			<div className='auth-container'>
				<Row>
					<Col md={7} sm={12} className='content px-4 py-5'>
						<div className='content-wrapper'>
							<Logo />
							<h1>Forgot Password</h1>
							<Form onSubmit={(event) => handleSubmit(event)}>
								<Row>
									<Col>
										<p className='form-title'>Please enter your Email</p>
									</Col>
								</Row>
								<Row>
									<Col md={6} sm={12}>
										<Form.Group className='mb-3'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type='email'
												value={email}
												onChange={(event) => setEmail(event.target.value)}
												placeholder='johnny@appleseed.com'
												required
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									{error && (
										<Col sm={12} className='my-3'>
											<p className='error mb-0'>{error}</p>
										</Col>
									)}
									<Col md={6} sm={12}>
										<Button
											type='submit'
											className='button button-secondary full-width my-3'
										>
											Send
										</Button>
									</Col>
								</Row>
							</Form>
							<p
								className='link mt-3'
								onClick={() => navigate('/register', { replace: true })}
							>
								Don't have an account? Sign up here
							</p>
						</div>
					</Col>
					<Col md={5} sm={12} className='banner'>
						<div className='logo-container'>{/* <Logo /> */}</div>
					</Col>
				</Row>
			</div>
		</AuthLayout>
	);
};
export default withErrorHandling(Forgot);
