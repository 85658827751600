/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Navbar, Nav } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useBetween } from 'use-between';

import './index.scss';
import {
	Logo,
	Instagram,
	Linkedin,
	Pinterest,
	WebsiteIcon,
	Setting,
	Logout,
} from '../../../../components/icons';
import Yotpo from '../../../../assets/images/dashboard/yotpo.png';
import Okendo from '../../../../assets/images/dashboard/okendo.png';
import GR0 from '../../../../assets/images/dashboard/gr0.png';
import Attentive from '../../../../assets/images/dashboard/attentive.png';
import VideoFresh from '../../../../assets/images/dashboard/video_fresh.png';
import Video from '../../../../assets/images/dashboard/video.png';
import Mattered from '../../../../assets/images/dashboard/mattered.png';
import Gorgias from '../../../../assets/images/dashboard/gorgias.png';
import Affable from '../../../../assets/images/dashboard/affable-ai-logo.png';
import Digital from '../../../../assets/images/dashboard/digital_agencies.png';
import LowShipping from '../../../../assets/images/dashboard/low_ship_rate.png';
import TreeBackground from '../../../../assets/images/dashboard/tree-background.png';
import PlanetFWD from '../../../../assets/images/dashboard/planet-fwd.png';
import LogoHere from '../../../../assets/images/dashboard/logo-here.png';
import Certified from '../../../../assets/images/dashboard/certified.png';
import Klaviyo from '../../../../assets/images/dashboard/klaviyo.png';
import Leaf from '../../../../assets/images/dashboard/leaf.png';
import PlanetBackground from '../../../../assets/images/dashboard/planet-background.png';
import TreeSet from '../../../../assets/images/dashboard/tree_set.png';
import useAuth from '../../../auth/useAuth';
import useClient from '../../useClient';
import Smartlook from 'smartlook-client';
import { Ecodrive } from '../../../../context/store';
import { useLogout } from '../../../../hooks/useAuth';
import { ClientLayout } from '../../../../lauyouts';

const PartnerDashboard = () => {
	const navigate = useNavigate();
	const { getIntegrationByBusinessId } = useBetween(useClient);
	const { userId } = useBetween(useAuth);
	const [{ business }] = useContext(Ecodrive);
	const [shopSlug, setShopSlug] = useState();

	useEffect(() => {
		if (business.id) {
			getIntegrationByBusinessId(business.id).then((data) => {
				let integrationWithShopURL = data.find((el) => el.shopUrl);
				if (!integrationWithShopURL) return;
				let [shopName] = integrationWithShopURL.shopUrl.split('.');
				setShopSlug(shopName);

				Smartlook.identify(userId);
			});
		}
	}, [business.id]);

	const logout = useLogout();

	return (
		<ClientLayout>
			<div className='partner-dashboard'>
				<div className='dashboard-header'>
					<Navbar expand='lg'>
						<Navbar.Brand href='https://ecodrive.community'>
							<Logo />
						</Navbar.Brand>
						<Navbar.Toggle aria-controls='navbar-nav' />
						<Navbar.Collapse id='navbar-nav' className='justify-content-end'>
							<Nav>
								<Nav.Link
									className='active'
									onClick={() => navigate('/partner-dashboard', { replace: true })}
								>
									<span>Planet Partners</span>
								</Nav.Link>
								<Nav.Link
									onClick={() => navigate('/client-dashboard', { replace: true })}
								>
									<span>Dash</span>
								</Nav.Link>
								<Nav.Link
									onClick={() => navigate('/client-resources', { replace: true })}
								>
									<span>Resources</span>
								</Nav.Link>
								{(shopSlug || business.id) && (
									<Nav.Link
										className='public-dash'
										onClick={() =>
											window.open(`/public-dashboard/${shopSlug || business.id}`, '_blank')
										}
									>
										<span>Public Dash</span>
									</Nav.Link>
								)}
								<Nav.Link onClick={() => navigate('/settings', { replace: true })}>
									<span>
										<Setting width='18' height='18' />
									</span>
								</Nav.Link>
								<Nav.Link onClick={() => logout()}>
									<span>
										<Logout width='16' height='16' />
									</span>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</div>
				<Container className='dashboard-content position-relative'>
					<img src={Leaf} alt='Leaf' className='leaf' />
					<Row className='content-header'>
						<Col className='z-1'>
							<h1>Planet Partners</h1>
							<p>
								Curated partnerships that we have formed to help you reach your goals of
								becoming an entirely earth-friendly brand. We serve as a great stepping
								stone in the right direction for your journey and our partners are here
								to help you the rest of the way!
							</p>
							<h5>Let’s take your brand’s sustainability to the next level.</h5>
						</Col>
					</Row>

					<Row className='package-container'>
						<Col xs={12} className='mb-5'>
							<Row className='package-item align-items-center'>
								<Col lg={5}>
									<div className='package-img'>
										<img src={TreeBackground} alt='Tree Background' />
										<div className='package-logo'>
											<img src={PlanetFWD} alt='PLANET FWD' />
										</div>
									</div>
								</Col>
								<Col lg={7} className='package-description'>
									<div>
										<h3>Achieve Net Neutrality</h3>
										<p>
											Planet forward has an automated tool that allows your brands to audit
											scope 1,2,3 emissions to understand your exact CO2 footprint across
											your entire business! They are the most affordable, fastest, simplest
											and most premium offering for this service on the market. Once they
											calculate your total CO2, Ecodrive can help you offset it!
										</p>
									</div>
									<div className='d-flex justify-content-end'>
										<Button
											variant='link'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Planet%20forward&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Planet%20forward%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH
										</Button>
									</div>
								</Col>
							</Row>
						</Col>
						<Col xs={12} className='mb-5'>
							<Row className='package-item align-items-center'>
								<Col lg={5}>
									<div className='package-img'>
										<img src={TreeBackground} alt='Tree Background' />
										<div className='package-logo'>
											<img src={LogoHere} alt='PLANET FWD' />
										</div>
									</div>
								</Col>
								<Col lg={7} className='package-description'>
									<div>
										<h3>Packaging Solutions</h3>
										<p>
											Make the seemingly impossible switch from plastic, non-recyclable
											packaging, to biodegradable, eco-conscious materials that are sure to
											stand out to consumers. Their affordable and hands on approach makes
											the switch seamless and economical for your brand while helping to
											reduce unnecessary waste from our planet and further doing your part
											with businesses!
										</p>
									</div>
									<div className='d-flex justify-content-end'>
										<Button
											variant='link'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20ECOENCLOSE&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20ECOENCLOSE%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH
										</Button>
									</div>
								</Col>
							</Row>
						</Col>
						<Col xs={12}>
							<Row className='package-item align-items-center'>
								<Col lg={5}>
									<div className='package-img'>
										<img src={TreeBackground} alt='Tree Background' />
										<div className='package-logo'>
											<img src={Certified} className='certfied' alt='PLANET FWD' />
										</div>
									</div>
								</Col>
								<Col lg={7} className='package-description'>
									<div>
										<h3>Become BCorp Certified</h3>
										<p>
											Partnering with Ecodrive and our partners allows you to score higher
											in the environmental section and helps you to qualify as an eligible
											brand for their certification. There is a lot of work needed of your
											brand, but this stamp of approval is held in highest regard in this
											space and to consumers!
										</p>
									</div>
									<div className='d-flex justify-content-end'>
										<Button
											variant='link'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20BCorp%20Certified&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20BCorp%20Certified%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH
										</Button>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
			<div className='planet-dashboard'>
				<Container className='planet-content'>
					<Row className='content-header'>
						<Col className='z-1'>
							<div className='d-flex justify-content-between title-wrapper'>
								<h1>Growth Partners</h1>
								<img src={TreeSet} alt='Trees' className='tree-set' />
							</div>
							<p>
								Our trusted partners that can help you leverage your tree planting
								initiative to help grow your brand. The more your business grows, the
								more trees we can grow together for our planet! Below you will find
								heavily vetted partners that can help you implement proven strategies
								across all channels to and reach sustainable consumers.
							</p>
							<h5 className='text-center'>
								Grow your business, grow{' '}
								<span className='text-decoration-underline'>more trees</span>.
							</h5>
						</Col>
					</Row>
					<Row className='our-partners position-relative'>
						<img
							className='planet-bg'
							src={PlanetBackground}
							alt='Planet Background'
						/>

						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Yotpo} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Yotpo&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Yotpo%20reviews%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Loyalty
									</Button>
								</div>
								<div className='item-content'>
									<p>
										Build out loyalty programs that incentivize your businesses around
										tree planting and planet goals, instead of around discounting your
										products! Yotpo has a suite of offerings that can help your brand
										across the board to build out business loyalty.
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											target='_blank'
											className='partner-badge'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Yotpo&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Yotpo%20reviews%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Mattered} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Mattered&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Mattered%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Email/SMS
									</Button>
								</div>
								<div className='item-content'>
									<p>
										Mattered is the world's only email marketing agency that makes all
										campaigns and emails climate positive with Ecodrive! Send campaigns
										sustainably by partnering with them. They also have a ton of
										experience designing campaigns specific to your tree planting
										initiative to boost AC, retention and set up post purchase flows to
										engage your businesses!
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Mattered&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Mattered%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Affable} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Influence&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Influence%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Influencers
									</Button>
								</div>
								<div className='item-content'>
									<p>
										Influencers are looking to represent brands like yours that are doing
										their part for the planet. Work with our partners to help you find
										eco-friendly influencers and collaborate around tree planting and
										being climate positive with Ecodrive!
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Influence&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Influence%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Digital} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Marketing%20Agencies&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Marketing%20Agencies%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Ecodrive strategy help
									</Button>
								</div>
								<div className='item-content'>
									<p>
										Use our partners who have successfully built paid media, email/sms,
										website and organic campaigns around our strategies provided to
										increase conversions, retention and business acquisition with tree
										planting for our clients
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Marketing%20Agencies&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Marketing%20Agencies%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Gorgias} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Gorgias&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Gorgias%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Climate Positive CS
									</Button>
								</div>
								<div className='item-content'>
									<p>
										Leading business service tool for e-commerce merchants has partnered
										with Ecodrive to make all business service climate positive exclusive
										to Ecodrive partners! Sign up today and inform the Ecodrive team about
										it to run your first audit!
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Gorgias&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Gorgias%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={LowShipping} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Low%20Ship%20Rate&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Low%20Ship%20Rate%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Discounted Shipping Rates
									</Button>
								</div>
								<div className='item-content'>
									<p>
										If you’re reading this, you most likely plant for every order. Use our
										partners at Low Ship Rate to help you cover the tree cost and more by
										lowering your shipping rates! We also have clients that build the tree
										costs into shipping and call it climate positive shipping (they still
										see increased conversion!)
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Low%20Ship%20Rate&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Low%20Ship%20Rate%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Klaviyo} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Klaviyo&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Klaviyo%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Email Marketing Platform
									</Button>
								</div>
								<div className='item-content'>
									<p>
										Want to have the most success increasing your businesses experience
										with Ecodrive? You need email and Klaviyo is the best of breed. Build
										out direct integrations and flows to help you inform your businesses
										around the tree planting and drive ROI on your tree planting efforts.
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Klaviyo&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Klaviyo%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Okendo} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Okendo&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Okendo%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Reviews
									</Button>
								</div>
								<div className='item-content'>
									<p>
										Incentivize businesses to leave a review by clearly offering "leave a
										review, plant a tree" offers instead of discounting products. Ecodrive
										brands have seen a ton of success increasing opt-ins and experience by
										doing this at a time where reviews matter so much to consumers. Okendo
										is the best review platform to accomplish this.
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Okendo&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Okendo%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={GR0} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20GR0&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20GR0%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										SEO
									</Button>
								</div>
								<div className='item-content'>
									<p>
										Consumers are searching for brands that are doing their part to be
										sustainable. Leverage what you are doing with Ecodrive and our proven
										SEO partners to capture this growing audience away from competition
										and onto your site!
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20GR0&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20GR0%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Attentive} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Attentive&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Attentive%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										SMS Marketing
									</Button>
								</div>
								<div className='item-content'>
									<p>
										SMS is very important to have a personal relationship with your
										businesses. Our clients are having success with simple callouts like
										“we plant 1 🌳 if you buy now”. If you don’t have SMS set up, we
										highly recommend Attentive as the best platform.
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Attentive&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Attentive%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={VideoFresh} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Friendbuy&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Friendbuy%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										Referral Marketing
									</Button>
								</div>
								<div className='item-content'>
									<p>
										In a day where FB ads no longer can be depended on, your brand needs
										word of mouth to get your products out there to the world! Friendbuy
										is the best solution for this on the market and we have an exclusive
										where in addition to a give $5, get $5 program, you can plant 5, get 5
										planted! 🌳
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Friendbuy&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Friendbuy%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={4} md={6} sm={12}>
							<div className='partner-item'>
								<div className='item-header'>
									<img src={Video} alt='parnter' />
									<Button
										variant='link'
										className='partner-badge'
										target='_blank'
										href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Toktent&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Toktent%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
									>
										TikTok Content
									</Button>
								</div>
								<div className='item-content'>
									<p>
										TikTok is an important social channel to bring awareness to your brand
										on social media, but it can be hard to get started. Reach younger
										demo's that align their spending with brands that do more, through our
										partners at TokTent who strategize and create content native to TikTok
										for your brand!
									</p>
									<div className='text-end'>
										<Button
											variant='link'
											className='partner-badge'
											target='_blank'
											href="mailto:partners@ecodrive.community?subject=Interested%20in%20learning%20more%20about%20Toktent&body=Hey%20Ecodrive%2C%20can%20you%20give%20me%20more%20information%20on%20Toktent%3F%0D%0AI'm%20interested%20in%20their%20services%20and%20would%20love%20to%20be%20connected%20with%20their%20team%20to%20receive%20any%20Ecodrive%20exclusive%20client%20discounts%20and%20offerings!"
										>
											GET IN TOUCH &gt;
										</Button>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
				<Container fluid className='dashboard-footer'>
					<Container>
						<Row>
							<Col md={4} sm={12}>
								<Logo width='131px' fill='white' />
								<div className='social-wrapper'>
									<Button
										variant='link'
										className='social-item'
										target='_blank'
										href='https://www.instagram.com/ecodrive/'
									>
										<Instagram />
									</Button>
									<Button
										variant='link'
										className='social-item'
										target='_blank'
										href='https://www.linkedin.com/company/ecodriveventures/'
									>
										<Linkedin />
									</Button>
									<Button
										variant='link'
										className='social-item'
										target='_blank'
										href='https://www.pinterest.com/ecodrivecommunity/'
									>
										<Pinterest />
									</Button>
									<Button
										variant='link'
										className='social-item'
										target='_blank'
										href='https://ecodrive.community/'
									>
										<WebsiteIcon />
									</Button>
								</div>
								<p>Need help?</p>
								<Button
									variant='link'
									target='_blank'
									href='mailto:support@ecodrive.community'
								>
									support@ecodrive.community
								</Button>
							</Col>
							<Col md={4} sm={12} className='d-flex flex-column align-items-start'>
								<Link className='btn-link' to={'/client-resources'}>
									Home
								</Link>
								<Button
									variant='link'
									target='_blank'
									href='https://blog.ecodrive.community'
								>
									Blog
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://brands.ecodrive.community/tree/plant.html'
								>
									More about Trees
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://brands.ecodrive.community/contact'
								>
									Join Ecodrive
								</Button>

								<Button
									variant='link'
									target='_blank'
									href='https://apps.shopify.com/ecodrive'
								>
									Install the app
								</Button>
							</Col>
							<Col md={4} sm={12} className='d-flex flex-column align-items-start'>
								<Link className='btn-link' to={'/settings'}>
									Settings
								</Link>
								<Link className='btn-link' to={'/partner-dashboard'}>
									Planet partners
								</Link>
								<Link className='btn-link' to={'/client-dashboard'}>
									Internal Dash
								</Link>
								<Button
									variant='link'
									target='_blank'
									href='https://ecodrive.community/policies/privacy-policy'
								>
									Privacy policy
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://ecodrive.community/policies/terms-of-service'
								>
									Terms of Use
								</Button>
							</Col>
						</Row>
					</Container>
				</Container>

				<Container>
					<Row className='copyright'>
						<Col className='d-flex align-items-center justify-content-between'>
							<span>All rights reserved 2022</span>
							<div>
								<Button
									variant='link'
									target='_blank'
									href='https://donors.edenprojects.org/user/ecodrive/'
								>
									Proof of planting
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://ecodrive.community/policies/terms-of-service'
								>
									Terms & Conditions
								</Button>
								<Button
									variant='link'
									target='_blank'
									href='https://ecodrive.community/policies/privacy-policy'
								>
									Privacy Policy
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</ClientLayout>
	);
};

export default PartnerDashboard;
